import React, { useState } from 'react';
import { Languages } from '../../shared/slices/languageSlice';
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';

interface ILanguageSelectComponentProps {
    value : string;
}

const LanguageSelectComponent : React.FC<ILanguageSelectComponentProps> = props => {

    const [language, setLanguage] = useState(props.value);

    const handleLanguageChange = ( value : SelectValue ) => {
        setLanguage(value?.toString() ?? '');
    }

    return (

        <Select 
            disabled
            value={language}
            onChange={handleLanguageChange}
            options={[{label:Languages.IT,value:Languages.IT},{label:Languages.EN,value:Languages.EN},{label:Languages.FR,value:Languages.FR}]} 
            style={{width: '100%'}} 
        />

    )

}

export default LanguageSelectComponent;