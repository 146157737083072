/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Logo from '../../shared/assets/images/mybasiq_logo.png';

const MyBasiqLogo : React.FC<{}> = props => {

    return (

        <img src={Logo} style={{maxWidth:'100%',maxHeight:'100%', margin:'auto'}} />

    )

}

export default MyBasiqLogo;