import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface INoResellerWarningProps {
    feature : string
}

const NoResellerWarning : React.FC<INoResellerWarningProps> = props => {

    const { t } = useTranslation();

    return (

        <Row style={{padding:'10px'}}>

            <Card
                style={{width:'100%'}}>

                <p> <FontAwesomeIcon icon={faExclamationTriangle} color="red" /> {t('shared.warning')} </p>

                <hr/>

                <Row>

                    <Col>

                        <p> {t('shared.reseller.noResellerWarning.p1').replace(':feature',props.feature)} </p>
                        <p> {t('shared.reseller.noResellerWarning.p2')} </p>
                        <p> {t('shared.reseller.noResellerWarning.p3')} </p>
                    
                    </Col>
                    
                </Row>
            
            </Card>

        </Row>

    )

}

export default NoResellerWarning;