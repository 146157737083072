import React from 'react';
import { CashInOut } from '../../../features/ZReports/models/IZReport';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import useMathUtilities from '../../../hooks/useMathUtilities';

interface ICashInOutTableProps {
    cashInOuts : CashInOut[]
}

const CashInOutTable : React.FC<ICashInOutTableProps> = props => {

    const { t } = useTranslation();

    const { formatNumber } = useMathUtilities();

    const alignRight : AlignSetting = 'right';

    const columns = [
        {
            title: t('zreports.zreport.details.table.description'),
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: t('zreports.zreport.details.table.amount'),
            dataIndex: 'amount',
            key: 'amount',
            align: alignRight
        },
    ];

    const dataSource = props.cashInOuts.map((cashInOut, index) => {
        return {
            key: index, 
            description: cashInOut.description,
            amount: formatNumber(cashInOut.amount) + " €"
        }
    });

    return (

        <Table
            size="small"
            bordered
            columns={columns}
            pagination={false}
            dataSource={dataSource} />

    )

}

export default CashInOutTable;