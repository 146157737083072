import React from 'react';
import { Card } from 'antd';
import { IPayment } from '../../../features/ZReports/models/IZReport';
import { useTranslation } from 'react-i18next';

import PaymentsTable from './paymentsTable';

export interface IZReportPaymentComponent {
     payments ?: IPayment[]
}

const ZReportPaymentsComponent : React.FC<IZReportPaymentComponent> = props => {

     const { t } = useTranslation();


     return (
          <Card title={t("zreports.zreport.details.payments.title")} style={{margin:"8px"}}>{ 
               props.payments ? 
                    
                    <PaymentsTable payments={props.payments} /> 
                    :  <p>{t("zreports.zreport.details.general.noHeaderData")}</p>
          }
          </Card> 

     );
}

export default ZReportPaymentsComponent;