import { AppDispatch } from "../../core/store/store";
import { IUserDetail } from "../models/IUser";
import request from "../../core/request/request";
import { AxiosError } from "axios";

const host = process.env.REACT_APP_4CO_HOST || "";

const getUser = (username: string, dispatch: AppDispatch): Promise<IUserDetail> => {
	const url = `/v2/_services/users/${username}`;

	return new Promise(async (resolve, reject) => {
		try {
			const response = await request<IUserDetail>(
				host,
				{
					url: url,
					method: "GET"
				},
				dispatch
			);

			resolve(response.data);
		} catch (e: unknown) {
			const err = e as AxiosError;
			reject(err.response);
		}
	});
};

export const userService = { getUser };
