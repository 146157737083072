/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip, AreaChart, Area, Legend } from 'recharts';
import { useTranslation } from 'react-i18next';
import { DashboardDailyData } from './DashboardComponent';
import ChartTotalsComponent, { ChartTotal } from './chartTotals.component';
import DashboardChartWrapper from './dashboardChartWrapper.component';
import DashboarcComponentTitle from './DashboardComponentTitle';
import { TemporalSelectorModes } from '../../layouts/TemporalSelector/TemporalSelector';

export interface DailyAggregateData {
    date : string;
    dateDay: string;
    document_total : number;
    document_total_compare? :number;
    document_num : number;
    document_num_compare? : number;
    document_avg : number;
    document_avg_compare ? : number;
    cover_num : number;
    cover_num_compare? : number;
    cover_total_amount : number;
    cover_avg : number;
    bar_avg : number;
}

export interface IDashboardDailySellsProps{
    data : DashboardDailyData;
    reportCaption : string;
}

export interface IDashboardDailyProps {
    compareLegendLabel : string;
    mode : TemporalSelectorModes;
    responsiveContainerClassName: string;
}

const DashboardDailySells : React.FC<IDashboardDailySellsProps & IDashboardDailyProps> = props => {

    const { t } = useTranslation();

    const document_total_translated = t("zreports.zreport.totalDocumentAmount");

    const totals : ChartTotal [] = [
        {
            label: t('dashboard.current'),
            value: props.data.gran_totals.document_gran_total,
            color: '#8884d8',
            suffix: '€',
            fontSize: '3.5vw'
        },
        {
            label: t('dashboard.previous'),
            value: props.data.gran_totals_compare.document_gran_total_compare,
            color: '#888412',
            suffix: '€'
        }
    ];

    const title = props.mode === TemporalSelectorModes.week ? t('dashboard.titles.weekly.total_documents') : t('dashboard.titles.monthly.total_documents');
 
    return (
    
        
            <DashboardChartWrapper>

                    <DashboarcComponentTitle title={title} />

                    <ChartTotalsComponent
                        totals={totals}
                        mainTotal={0}
                        compareTotal={1}
                        deltaSuffix="€" />
                
                    <ResponsiveContainer 
                        height={160} 
                        className={props.responsiveContainerClassName}>

                        <LineChart 
                            
                            data={props.data.aggregates}
                            margin={{left:-20, top:10}} >

                            <CartesianGrid />

                            <XAxis dataKey="dateDay" />
                            <Tooltip labelFormatter={() => document_total_translated }/>
                            <YAxis tick={{fontSize: 18}} />
                            <Line animationDuration={2000} name={t('shared.period').toString()} type="monotone" dataKey="document_total" strokeWidth={2} stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line animationDuration={2000} name={t(props.compareLegendLabel).toString()} type="monotone" dataKey="document_total_compare" strokeWidth={1} stroke="#888412" />

                        </LineChart>

                    </ResponsiveContainer>

                <div style={{textAlign:'center', width:'100%'}}>{props.reportCaption}</div>

            </DashboardChartWrapper>

    )

}

export default DashboardDailySells;