import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoResellerWarning from '../components/shared/reseller/NoReseller';
import SupportComponent from '../components/support/support.component';
import { RootState } from '../core/store/rootReducer';
import { IResellerState } from '../features/Reseller/slices/resellerSlices';
import DefaultLayout from '../layouts/PageLayout/DefaultLayout';
import { IFeedbackState, uploadFeedback } from '../features/Feedbacks/slices/feedbackSlices';
import { IFeedback } from '../features/Feedbacks/models/feedback';
import { ISiteState } from '../shared/slices/siteSlice';

const SupportContainer : React.FC<{}> = props => {

    const dispatch = useDispatch();

    const resellerState : IResellerState = useSelector(
        ( state : RootState ) => state.ResellerReducers
    );

    const feedback : IFeedbackState = useSelector(
        (state : RootState ) => state.FeedbackReducers
    )

    const siteState : ISiteState = useSelector(
        ( state : RootState ) => state.SiteReducers
    )

    const onSubmit = ( feedback : IFeedback ) => {

         resellerState.reseller && siteState.site && dispatch( 
            uploadFeedback(
                feedback,
                resellerState.reseller,
                siteState.site
            )
        );

    }

    return (

        <DefaultLayout>

            { resellerState.reseller 
                ? <SupportComponent 
                    onSubmit={onSubmit}
                    feedbackState={feedback}
                    reseller={resellerState.reseller} />
                : <NoResellerWarning feature={'features.support'} /> 
            }

        </DefaultLayout>

    )

}

export default SupportContainer;