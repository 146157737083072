import React from 'react';
import { useTranslation } from 'react-i18next';
import WarningModal from '../../layouts/Modal/WarningModal';
import { ISite } from '../../shared/models/ISite';
import SiteEntryComponent from './siteEntry.component';

interface ISitesComponentProps {
    sites   : ISite[];
    isError : boolean;
    selectSite: (site: ISite) => void;
    onRefresh: (...args: any) => void;
}

const SitesComponent : React.FC<ISitesComponentProps> = props => {

    const renderSite = ( site : ISite) => <SiteEntryComponent key={site.id} site={site} selectSite={props.selectSite} />

    const  { t } = useTranslation();

    return(

        <React.Fragment>

                <WarningModal 
                    title={t('sites.fetchingErrorTitle')}
                    message={t('sites.fetchingErrorMessage')}
                    isOpen={props.isError} 
                    onOk={props.onRefresh} />
            
                {props.sites.map(renderSite)}
                
        </React.Fragment>

    )

}

export default SitesComponent;