import React from 'react';
import SectionButton from '../../layouts/SectionButton/SectionButton';
import { IZReportList } from '../../features/ZReports/models/IZReportList';
import { IZReportHeader } from '../../features/ZReports/models/IZReportHeader';
import SimpleLoading from '../../layouts/Loading/SimpleLoading';
import { Row, Col } from 'antd';
import useDateUtilities from '../../hooks/useDateUtilities';
import { customIcons, CustomIconType } from '../../layouts/icons/icons';

import style from './siteHome.module.css';

interface IZReportButtonComponentProps {
     title ?: string,
     isLoading : boolean,
     isError : boolean,
     handleClick: () => void,
     zReportList: IZReportList | null,
     lastZReport: IZReportHeader | null
}

const ZReportButtonComponent : React.FC<IZReportButtonComponentProps> = props => {

     const { formatDateToLocale, isStringValidDate } = useDateUtilities();

     const handleClick = () => {
          if(!props.isLoading && !props.isError) props.handleClick();
     }

     const zReportCount  = props.lastZReport?.z_count;
     const documentTotal = props.lastZReport?.document_total?.toFixed(2) || '0';
     const startDate     = props.lastZReport && isStringValidDate(props.lastZReport?.start_date) ? formatDateToLocale(new Date(props.lastZReport?.start_date)) : "-";
     
     const renderInfoColumn = ( value : string, SVGElement? : CustomIconType, color? : string ) => 
          <Col xs={24}>

               <span style={{fontSize:'3.3vw', fontWeight:'bold'}}> { SVGElement && <span> <SVGElement className={style.lastZIcon} /></span>} { value }</span>

          </Col>

     const renderAmountColumn = (value : string, SVGElement? : CustomIconType, color? : string ) => 
          <Col xs={24} style={{marginTop:'5px'}}>

               <span style={{fontSize:'4.2vw', fontWeight: 'bold'}}> { SVGElement && <span> <SVGElement className={style.lastZIcon} /></span> } { value }</span>

          </Col>

     return (
          <SectionButton title={props.title} onClick={handleClick}>{
               props.isLoading ? 
                    <SimpleLoading /> : 
                    <div>

                         {props.lastZReport && 
                              <Row style={{textAlign:'center'}}> 

                                   {renderInfoColumn( "Z " + zReportCount?.toString() || '0')}
                                   {renderInfoColumn(startDate || '', customIcons.IconCalendar)}
                                   {renderAmountColumn(documentTotal + " €", customIcons.IconBill, 'green')}

                              </Row> }

                    </div>
               }
          </SectionButton>
     );
}

export default ZReportButtonComponent;