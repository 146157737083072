import React from 'react';
import { Row, Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Loading : React.FC<{}> = props => {

    return (

        <Card style={{margin: "10px"}}>
            <Row justify="center" gutter={0}>

                <FontAwesomeIcon icon={faSpinner} size="2x" spin />

            </Row>
        </Card>
    )

}

export default Loading;