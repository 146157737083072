/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Axios from "axios";
import { useState, useEffect } from "react";


const useAppStatus = () => {

    const [ online, setOnline ] = useState<boolean>(navigator.onLine);

    const startConnectionWorker = () => {

        const intervalId = setInterval( async () => {

            setOnline( await checkConnectivity( intervalId ));

        }, 1000);

    }

    const checkConnectivity = ( intervalId : ReturnType< typeof setInterval >) : Promise<boolean> => {

        return new Promise( async (resolve) => {

            const host      = process.env.REACT_APP_API_HOST || '';
            const endpoint  = host + `?timestamp=${new Date().getTime()}`;

            try{

                const response = await Axios.head(endpoint);
                clearInterval( intervalId );
                resolve(true);
    
            }
            catch( e ) { resolve(false) }
    

        })

    }

    const handler = ( e : Event ) => {

        setOnline(navigator.onLine);

    }

    useEffect(() => {

        window.addEventListener('online', handler);
        window.addEventListener('offline', handler);

        return () => {

            window.removeEventListener('online', handler);
            window.removeEventListener('offline', handler);

        }


    }, []);

    useEffect(() => {

        if(!online) startConnectionWorker();

    }, [online])

    return { online };

}

export default useAppStatus;