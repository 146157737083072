/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import DefaultLayout from '../layouts/PageLayout/DefaultLayout';
import '../i18n';
import DashboardComponent from '../components/dashboard/DashboardComponent';
import { IZReportListState, fetchZReportListByDateInterval, aggregateByDay } from '../features/ZReports/slices/zReportListSlices';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../core/store/rootReducer';
import { ISiteState } from '../shared/slices/siteSlice';
import useDateUtilities from '../hooks/useDateUtilities';

const DashboardContainer : React.FC<{}> = () => {

     const [ startDate, setStartDate ] = useState<Date>();
     const [ stopDate, setStopDate]    = useState<Date>();
     const [ datesRange, setDatesRange]= useState<Date[]>([]);

     const [ keyUpTimer, setKeyUpTimer ] = useState<NodeJS.Timeout>();

     const site : ISiteState = useSelector(
          ( state : RootState ) => state.SiteReducers
     );

     const zReportList : IZReportListState = useSelector(
          ( state : RootState) => state.ZReportListReducers
     );

     console.log('zReportList', zReportList);

     const dispatch = useDispatch();

     const { formatDateToServerFormat, getDatesInRange, shiftWeekFromDate } = useDateUtilities();

     useEffect(() => {

          fetchData();

     }, [startDate, stopDate])

     const fetchData = () => {

          if(startDate && stopDate) {

               const dates = getDatesInRange(startDate, stopDate );
               setDatesRange( dates )

               const weeksToShift =  Math.round(- (dates.length / 7));

               const compareStartDate   = shiftWeekFromDate(startDate, weeksToShift);
               const compareStopDate    = shiftWeekFromDate(stopDate, weeksToShift);

               keyUpTimer && clearTimeout( keyUpTimer );

               setKeyUpTimer(
                    setTimeout(() => {

                         dispatch(
                              fetchZReportListByDateInterval(
                                   site.site?.id!!, 
                                   formatDateToServerFormat(startDate), 
                                   formatDateToServerFormat(stopDate), 
                                   'day',
                                   formatDateToServerFormat(compareStartDate),
                                   formatDateToServerFormat(compareStopDate)
                              )
                         );

                    }, 300)
               )

          }

     }

     const handleDateRangeChange = (startDate : Date, endDate : Date) => {

          setStopDate(endDate);
          setStartDate(startDate);

     }

     return (
          <DefaultLayout >

               <DashboardComponent
                    isError={zReportList.isError}
                    onRefresh={fetchData} 
                    onDateChange={handleDateRangeChange} 
                    dates={datesRange} 
                    zReportAggregates={zReportList.zReportAggregates} 
                    zReportAggregatesCompare={zReportList.zReportCompareAggregates}
                    lastZReportDate={new Date(site.site?.last_z_report?.start_date || '')} />

          </DefaultLayout>
     )

}

export default DashboardContainer;