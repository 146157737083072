/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';

interface IRedirectModalProps {
    isOpen : boolean;
    url : string;
    toggle: (...args: any) => void;
    onOk? : (...args: any) => void;
    onCancel?: (...args : any) => void;
}

const RedirectModal : React.FC<IRedirectModalProps> = props => {

    const { confirm } = Modal;

    const { t } = useTranslation(); 

    const icon = <FontAwesomeIcon icon={faQuestionCircle} />

    const content = t('shared.redirectTo') + ` ${props.url}. ` + t('shared.continue') + `?`;

    const onOk = () => {

        props.toggle();
        window.location.href = props.url;
        props.onOk && props.onOk();

    }

    const onCancel = () => {

        props.toggle();

        props.onCancel && props.onCancel();

    }

    const showConfirmModal = () => {
        confirm({
            icon: icon,
            content: content,
            onOk: onOk,
            onCancel: onCancel
        })
    }

    useEffect(() => {

        props.isOpen && showConfirmModal();

    },[props.isOpen])

    return(
        <>
            
        </>
    )

}

export default RedirectModal;