import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

const NotificationComponent : React.FC<{}> = props => {

    const { t } = useTranslation();

    return(

        <Card title={t("notifications.title")} style={{margin:"8px"}}>

            {t('notifications.noNotifications')}

        </Card>

    )

}

export default NotificationComponent;