/* eslint-disable @typescript-eslint/no-unused-vars */
import { faExclamationTriangle, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface IWarningModalProps {
    isOpen : boolean;
    onOk: (...args: any) => void;
    title: React.ReactNode | string;
    message: React.ReactNode | string;
}

const WarningModal : React.FC<IWarningModalProps> = props => {

    const { t } = useTranslation();

    return (

        <>
            { props.isOpen && 
                <Drawer
                    placement="bottom"
                    closable={false}
                    onClose={props.onOk}
                    open={props.isOpen}
                    getContainer={false}
                    height='150px'
                    style={{position:'absolute'}}>
                        <h3><FontAwesomeIcon icon={faExclamationTriangle} color="red"/><span style={{paddingLeft:'10px'}}>{props.title}</span></h3>
                        <p>{props.message}</p>
                        <div>
                            <Button id="warningModalRetryButton" onClick={props.onOk} style={{float:'right'}} type="primary" danger>
                                <FontAwesomeIcon icon={faSync} /><span style={{paddingLeft:'5px'}}>{t('shared.retry')}</span>
                            </Button>
                        </div>
                </Drawer> 
            }

        </>

    )

}

export default WarningModal;