/* eslint-disable @typescript-eslint/no-unused-vars */
import qs from "qs";
import { IZReportList } from "../models/IZReportList";
import { IZReport } from "../models/IZReport";
import request from "../../../core/request/request";
import { AppDispatch } from "../../../core/store/store";
import { IApiServerResponse } from "../../../core/request/IApiServerResponse";
import { IZReportStatistics } from "../models/IZReportStatistics";
import useDateUtilities from "../../../hooks/useDateUtilities";
import { AxiosError } from "axios";

const host = process.env.REACT_APP_API_HOST || "";
const DEFAULT_PAGE_SIZE_LIMIT = 10;
const DEFAULT_PAGE_NUM = 1;

export enum IOrderBy {
	id = "id",
	count = "count",
	startDate = "start_date",
	stopDate = "stop_date"
}

export enum ISortBy {
	asc = "asc",
	desc = "desc"
}

const getAll = (siteId: number, dispatch: AppDispatch, pageSize?: number, pageNumber?: number, orderBy?: IOrderBy, sortBy?: ISortBy): Promise<IZReportList> => {
	const qsPageSize = pageSize || DEFAULT_PAGE_SIZE_LIMIT;
	const qsPageNumber = pageNumber || DEFAULT_PAGE_NUM;
	const qsOrderBy = orderBy || IOrderBy.startDate;
	const qsSortBy = sortBy || ISortBy.desc;
	const qsObject = { page_size: qsPageSize, page_number: qsPageNumber, order_by: qsOrderBy, sort_by: qsSortBy, withExtendedData: true };
	const qsUrl = qs.stringify(qsObject);

	const endpoint = `/sites/${siteId}/zreports?${qsUrl}`;

	return new Promise(async (resolve, reject) => {
		try {
			const response = await request<IZReportList>(host, { url: endpoint, method: "GET" }, dispatch);

			resolve(response.data);
		} catch (e: unknown) {
			const err = e as AxiosError;
			const response = {} as IZReportList;
			resolve(response);
			// reject(err.response);
		}
	});
};

const getAllLive = (
	siteId: number,
	dispatch: AppDispatch,
	pageSize?: number,
	pageNumber?: number,
	orderBy?: IOrderBy,
	sortBy?: ISortBy
): Promise<IZReportList> => {
	const qsPageSize = pageSize || DEFAULT_PAGE_SIZE_LIMIT;
	const qsPageNumber = pageNumber || DEFAULT_PAGE_NUM;
	const qsOrderBy = orderBy || IOrderBy.startDate;
	const qsSortBy = sortBy || ISortBy.desc;
	const qsObject = { page_size: qsPageSize, page_number: qsPageNumber, order_by: qsOrderBy, sort_by: qsSortBy, withExtendedData: true };
	const qsUrl = qs.stringify(qsObject);

	const endpoint = `/sites/${siteId}/zreports?${qsUrl}`;

	return new Promise(async (resolve, reject) => {
		try {
			const response = await request<IZReportList>(host, { url: endpoint, method: "GET" }, dispatch);

			resolve(response.data);
		} catch (e: unknown) {
			const err = e as AxiosError;
			const response = {} as IZReportList;
			resolve(response);
			// reject(err.response);
		}
	});
};

const getById = (siteId: number, zReportId: number, dispatch: AppDispatch): Promise<IZReport> => {
	const endpoint = `/sites/${siteId}/zreports/${zReportId}/detail`;

	return new Promise(async (resolve, reject) => {
		try {
			const response = await request<IZReport>(host, { url: endpoint, method: "GET" }, dispatch);

			resolve(response.data);
		} catch (e: unknown) {
			const err = e as AxiosError;
			reject(err.response);
		}
	});
};

const getStatisticsById = (siteId: number, zReportId: number, dispatch: AppDispatch): Promise<IApiServerResponse<IZReportStatistics>> => {
	const endpoint = `/sites/${siteId}/zreports/${zReportId}/statistics`;

	return new Promise(async (resolve, reject) => {
		try {
			const response = await request<IApiServerResponse<IZReportStatistics>>(host, { url: endpoint, method: "GET" }, dispatch);

			resolve(response.data);
		} catch (e: unknown) {
			const err = e as AxiosError;
			reject(err.response);
		}
	});
};

const getByDateInterval = (
	siteId: number,
	startDate: string,
	stopDate: string,
	dispatch: AppDispatch,
	orderBy?: IOrderBy,
	sortBy?: ISortBy
): Promise<IZReportList> => {
	const qsOrderBy = orderBy || IOrderBy.startDate;
	const qsSortBy = sortBy || ISortBy.asc;
	const qsObject = {
		order_by: qsOrderBy,
		sort_by: qsSortBy,
		start_date: startDate,
		stop_date: stopDate,
		withExtendedData: true
	};
	const qsUrl = qs.stringify(qsObject);

	const endpoint = `/sites/${siteId}/zreports?${qsUrl}`;

	return new Promise(async (resolve, reject) => {
		try {
			const response = await request<IZReportList>(host, { url: endpoint, method: "GET" }, dispatch);

			resolve(response.data);
		} catch (e: unknown) {
			const err = e as AxiosError;
			const response = {} as IZReportList;
			resolve(response);
			// reject(err.response);
		}
	});
};

export const zReportService = { getAll, getById, getStatisticsById, getByDateInterval };
