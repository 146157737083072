/* eslint-disable no-control-regex */
const useCharset = () => {


    const isASCII = ( input : string ) => {

        return /^[\x00-\x7F]*$/.test(input);

    }

    return { isASCII }


}

export default useCharset;