import { ISite } from "../models/ISite";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISiteState {
	site: ISite | null;
}

const initialSiteState: ISiteState = {
	site: null
};

const siteSlice = createSlice({
	name: "site",
	initialState: initialSiteState,
	reducers: {
		selectSite(state: ISiteState, action: PayloadAction<ISite>) {
			state.site = action.payload;
		},

		unselectSite(state: ISiteState, action: PayloadAction<null>) {
			state.site = action.payload;
		}
	}
});

export const { selectSite, unselectSite } = siteSlice.actions;

export default siteSlice.reducer;
