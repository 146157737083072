import { Row } from "antd";
import React from "react";
import { IFeedback } from "../../features/Feedbacks/models/feedback";
import { IFeedbackState } from "../../features/Feedbacks/slices/feedbackSlices";
import { IReseller } from "../../features/Reseller/models/IReseller";
import ContactsComponent from './contacts.component';
import FeedbackComponent from "./feedback/feedback.component";

interface ISupportComponentProps {
    reseller: IReseller;
    feedbackState : IFeedbackState;
    onSubmit: ( feedback : IFeedback ) => void;
}

const SupportComponent : React.FC<ISupportComponentProps> = props => {

    return (

        <Row style={{marginLeft: '10px', marginTop: '10px', marginRight: '10px', position:'sticky'}} gutter={0}>

            <ContactsComponent 
                reseller={props.reseller} />

            <FeedbackComponent 
                onSubmit={props.onSubmit}
                feedbackState={props.feedbackState}
                />

        </Row>

    )

}

export default SupportComponent;