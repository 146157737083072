/* eslint-disable getter-return */
declare type MixedEvent = TouchEvent | MouseEvent;

// Initial global values
let startY: number = 0;
let enabled: boolean = false;
let supportsPassiveOption: boolean = false;

try {
	let options = Object.defineProperty({}, "passive", {
		get: () => {
			supportsPassiveOption = true;
		}
	});
	window.addEventListener("test", () => null, options);
} catch (e) {}

const isElementScrollable = (webkitOverflowValue: string, overflowValue: string): boolean => {
	return webkitOverflowValue === "touch" && (overflowValue === "auto" || overflowValue === "scroll");
};

const handleTouchMove = (e: MixedEvent) => {
	let element = e.target as HTMLElement; //Get Scrolled Element
	if (!element) return;

	let zoom = window.innerWidth / window.document.documentElement.clientWidth;
	if ((e instanceof TouchEvent && e.touches.length > 1) || zoom !== 1) return;

	// Loop through all parent elements
	while (element !== document.body) {
		let style = window.getComputedStyle(element as Element);

		if (!style) break;

		if (element.nodeName === "INPUT" && element.getAttribute("type") === "range") return; //Ignore input elements

		let scrolling = style.getPropertyValue("-webkit-overflow-scrolling");
		let overflowY = style.getPropertyValue("overflow-y");
		let height = parseInt(style.getPropertyValue("height"), 10);

		//Check if the element should be scrollable
		let isScrollable = isElementScrollable(scrolling, overflowY);
		let elementCanScroll = element.scrollHeight > element.offsetHeight;

		if (isScrollable && elementCanScroll) {
			let currentY = 0;
			if (e instanceof TouchEvent) currentY = e.touches[0].screenY;
			else if (e instanceof MouseEvent) currentY = e.screenY;

			const isTop = startY <= currentY && element.scrollTop === 0;
			const isBottom = startY >= currentY && element.scrollHeight - element.scrollTop === height;

			if (isTop || isBottom) e.preventDefault();

			return;
		}

		element = element.parentElement as HTMLElement;
	}

	e.preventDefault();
};

const handleTouchStart = (e: MixedEvent) => {
	if (e instanceof TouchEvent) startY = e.touches[0].screenY;
	else if (e instanceof MouseEvent) startY = e.screenY;
};

const enable = () => {
	window.addEventListener("touchstart", handleTouchStart, supportsPassiveOption ? { passive: false } : false);
	window.addEventListener("touchmove", handleTouchMove, supportsPassiveOption ? { passive: false } : false);
	enabled = true;
};

const disable = () => {
	window.removeEventListener("touchstart", handleTouchStart, false);
	window.removeEventListener("touchmove", handleTouchMove, false);
	enabled = false;
};

const testDiv = document.createElement("div");
document.documentElement.appendChild(testDiv);
testDiv.style.setProperty("-webkit-overflow-scrolling", "touch");
const scrollSupport = "getComputedStyle" in window && window.getComputedStyle(testDiv).getPropertyValue("-webkit-overflow-scrolling") === "touch";
document.documentElement.removeChild(testDiv);

scrollSupport && enable();

export const webkitBounce = {
	enable,
	disable,
	enabled
};
