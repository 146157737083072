/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {  Col, Row, Layout } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faHome, faBell } from '@fortawesome/free-solid-svg-icons';
import { IUser } from '../../shared/models/IUser';
import CustomBadge from '../CustomBadge/CustomBadge';

interface IBottomToolbarProps {
    user : IUser | null;
    onLogout : (...args: any) => void;
    onNotification : (...args: any) => void;
    onHome : (...args: any) => void;
    onCloud : (...args: any) => void;
    notifications: number;
}

const BottomToolbar : React.FC<IBottomToolbarProps> = props => {

    const iconSize = "lg";

    const disabledColor = "rgb(219, 225, 236)"
    const enabledColor = "white";

    return props.user &&

        <Layout.Footer style={{position:'absolute',bottom:"0px", backgroundColor:"#414141", width:"100%", padding:"5px 0", height:"40px",zIndex:5, boxShadow: '5px 5px 5px 5px #FFF'}}>

            <Row style={{paddingTop:"5px"}} gutter={0}>

                <Col xs={8}>

                    <Row justify="center" gutter={0}>

                        <FontAwesomeIcon 
                            icon={faHome} 
                            size={iconSize} 
                            color={enabledColor}
                            onClick={props.onHome} />

                    </Row>

                </Col>
                <Col xs={8}>

                    <Row justify="center" gutter={0}>

                        <FontAwesomeIcon 
                            icon={faCloud} 
                            size={iconSize} 
                            color={enabledColor}
                            onClick={props.onCloud} />

                    </Row>

                    
                </Col>
                <Col xs={8}>

                    <Row justify="center" gutter={0}>

                        <FontAwesomeIcon 
                            icon={faBell} 
                            size={iconSize} 
                            onClick={props.onNotification}
                            color={enabledColor} /> 
                        <CustomBadge count={props.notifications} isToShowZero={false} />

                    </Row>

                    
                </Col>

            </Row>

        </Layout.Footer>
    

}

export default BottomToolbar;