import React from 'react';
import { IZReportStatistics } from '../../features/ZReports/models/IZReportStatistics';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

import StatisticsTable from './statistics/statisticsTable';

interface IZReportStatisticsComponentProps {
    statistics : IZReportStatistics
}

const ZReportStatisticsComponent : React.FC<IZReportStatisticsComponentProps> = props => {

    const { t } = useTranslation();

    return (
        <Card title={t("zreports.zreport.details.statistics.title")} style={{margin:"8px"}}>

            <StatisticsTable statistics={props.statistics} />

        </Card>
    )

}

export default ZReportStatisticsComponent;