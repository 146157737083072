import React from 'react';
import { IZReportList } from '../../features/ZReports/models/IZReportList';
import { IZReportHeader } from '../../features/ZReports/models/IZReportHeader';
import ZReportItemComponent from './zreportItem.component';
import WarningComponent from '../shared/warning.component';
import Loading from '../../layouts/Loading/Loading';
import { useTranslation } from 'react-i18next';

interface IZReportsComponent {
     zreportList : IZReportList | null;
     selectZReport: (zReport : IZReportHeader) => void;
     isLastRecordLoaded: boolean;
     isLazyLoading: boolean;
}

const ZReportsComponent : React.FC<IZReportsComponent> = props => {

     const renderZReport = ( zReportHeader : IZReportHeader) => <ZReportItemComponent key={zReportHeader.z_id} zreport={zReportHeader} selectZReport={props.selectZReport} />

     const { t } = useTranslation();

     const isZReportListFound = () => {
          return typeof props.zreportList?.records != "undefined" && props.zreportList?.records.length > 0;
     }

     return(
          isZReportListFound() ? 
               <>
                    {props.zreportList?.records.map(renderZReport)}
                    {props.isLastRecordLoaded ? 
                         null : 
                         props.isLazyLoading ? <Loading /> : null
                    }
               </>
               :
               <WarningComponent title={t("shared.warning")} message={t("zreports.noZReportFound")} />
     );
}

export default ZReportsComponent;