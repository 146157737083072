/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { IZReport } from '../../features/ZReports/models/IZReport';
import ZReportPaymentsComponent from './payments/zreportPayments.component';
import ZReportResumeComponent from './zreportResume.component';
import ZReportHeaderComponent from './zReportHeader.component';
import ZReportStatisticsComponent from './zreportStatistics.component';
import ZReportCashInOutComponent from './cashInOut/zreportCashInOut.component';
import WarningModal from '../../layouts/Modal/WarningModal';
import { useTranslation } from 'react-i18next';

interface IZReportDetailComponent {
     zreportDetail : IZReport | null;
     isError: boolean;
     onRefresh: (...args: any) => void;
}

const ZReportDetailComponent : React.FC<IZReportDetailComponent> = props => {

     const zreportDetail = props.zreportDetail?.records;
     const resume = zreportDetail?.info[0];
     const payments = zreportDetail?.payments;
     const statistics = zreportDetail?.statistics;
     const cashInOuts = zreportDetail?.cashInOut;

     const { t } = useTranslation();

     return (
          <React.Fragment>
               
               <ZReportResumeComponent resume={resume} />
               <ZReportPaymentsComponent payments={payments} />
               <ZReportCashInOutComponent cashInOuts={cashInOuts || []} />
               {statistics ? <ZReportStatisticsComponent statistics={statistics} /> : null }

               <WarningModal
                    title={t('zreports.zreport.fetchingErrorTitle')}
                    message={t('zreports.zreport.fetchingErrorMessage')}
                    onOk={props.onRefresh}
                    isOpen={props.isError} />

                    

          </React.Fragment>
     );
}

export default ZReportDetailComponent;