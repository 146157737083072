import React from 'react';

interface ITemporalModeProps {
    label : string;
    isSelected : boolean;
}

const TemporalModeLabel : React.FC<ITemporalModeProps> = props => {

    const selectedStyle : React.CSSProperties = { backgroundColor: '#414141', color:'white', borderRadius:'10px' }

    return (

        <React.Fragment>

            <div style={props.isSelected ? selectedStyle : undefined}>

                <h3 style={{textAlign:'center', color: props.isSelected ? 'white' : 'black'}}>{ props.label }</h3>

            </div>

        </React.Fragment>

    )


}

export default TemporalModeLabel;