import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { IZReportHeader } from '../../features/ZReports/models/IZReportHeader';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMathUtilities from '../../hooks/useMathUtilities';
import { faClock, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'

import { customIcons, CustomIconType } from '../../layouts/icons/icons';

import style from './Zreport.module.css';
import { ISiteState } from '../../shared/slices/siteSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../core/store/rootReducer';

export interface IZReportResumeComponent {
     resume ?: IZReportHeader
}

const ZReportResumeComponent : React.FC<IZReportResumeComponent> = props => {

     const site : ISiteState = useSelector(
          ( state : RootState ) => state.SiteReducers
     );

     const { t } = useTranslation();

     const { formatNumber } = useMathUtilities();

     const { IconBill,IconCounterAverage, IconCoverAverage, IconDocumentAverage, IconCover, IconDocuments } = customIcons;

     const [calculatedCoverAvg, setCalculatedCoverAvg] = useState(0);

     useEffect(() => {

          const cur_doc_total = props?.resume?.document_total ? +props?.resume?.document_total : 0;
          const cur_bar_total = props?.resume?.bar_amount ? +props?.resume?.bar_amount : 0;
          const cur_cover_num = props?.resume?.cover_num ? +props?.resume?.cover_num : 0;
          if(cur_cover_num > 0){
               const cur_cover_avg = (cur_doc_total - cur_bar_total) / cur_cover_num ;
               setCalculatedCoverAvg(cur_cover_avg);
          }
     },[])


     
     const resumeTitle = () =>
          <React.Fragment> 

               <Row>

                    <Col xs={12}>
                         
                         <span style={{fontSize:'6vw', fontWeight: 'bold', marginTop:'auto', marginBottom:'auto'}}># {props.resume?.z_count}</span>
                    
                    </Col>

                    <Col xs={12}>

                         <Row>
                              <Col xs={24}>

                                   <span style={{float:'right', fontSize:'3vw'}}>
                                        <FontAwesomeIcon icon={faCalendarAlt} /> {props.resume?.start_date} <FontAwesomeIcon icon={faClock} /> {props.resume?.start_time}
                                   </span>

                              </Col>
                              <Col xs={24}>

                                   <span style={{float:'right', fontSize:'3vw'}}>
                                        <FontAwesomeIcon icon={faCalendarAlt} /> {props.resume?.stop_date} <FontAwesomeIcon icon={faClock} /> {props.resume?.stop_time}
                                   </span>

                              </Col>
                         </Row>

                    </Col>

               </Row>
          </React.Fragment>

     const renderInfoColumn = ( columnsWidth: number, SvgComponent: CustomIconType, value : string, secondValue?: string) => 
          <Col xs={columnsWidth}>

               <Row>

                    <Col xs={24} >

                         <SvgComponent className={style.resumeIcon}/> 

                    </Col>

                    <Col xs={24} className={style.resumeValue}>

                         {value}
                         
                    </Col>

                    {secondValue && 

                         <Col xs={24}>

                              {secondValue}  
                         
                         </Col>

                    }

               </Row>
               

          </Col>

     return (
          <Card title={resumeTitle()} style={{margin:'8px'}}>

               { props.resume ? 
                    <React.Fragment>

                         <Row className={style.row} style={{justifyContent: 'space-evenly'}}>

                              {renderInfoColumn( 8, IconBill, formatNumber(props.resume.document_total?.toFixed(2) || '0.00') + " €" )}
                              {site.site?.activity_type !== 'retail' ? renderInfoColumn( 8, IconCover, (props.resume.cover_num?.toString() || '0')) : null}
                              {renderInfoColumn( 8, IconDocuments, (props.resume.document_num?.toString() || '0'))}

                         </Row>
                         <Row className={style.row} style={{justifyContent: 'space-evenly'}}>

                              {renderInfoColumn( 8, IconCounterAverage, formatNumber(props.resume.bar_avg?.toFixed(2) || '0.00') + " €")}
                              {site.site?.activity_type !== 'retail' ? renderInfoColumn(
                                   8, 
                                   IconCoverAverage, 
                                   // (document total - bar total) / doc num
                                   formatNumber(calculatedCoverAvg?.toFixed(2) || '0.00') + " €"
                              ): null}
                              {renderInfoColumn( 
                                   8, 
                                   IconDocumentAverage, 
                                   formatNumber(props.resume.document_avg.toFixed(2)) + " €"
                              )}

                         </Row>
                         
                    </React.Fragment> :
                    <p>{t("zreports.zreport.details.general.noHeaderData")}</p>
               }
          </Card>
     );
}

export default ZReportResumeComponent;