import { AxiosResponse } from "axios";
import request from "../../../core/request/request";
import { AppDispatch } from "../../../core/store/store";
import { ISite } from "../../../shared/models/ISite";
import { IReseller } from "../../Reseller/models/IReseller";
import { IFeedback, IFeedbackResponse } from "../models/feedback";

const host = process.env.REACT_APP_RT_HOST || "";

const postFeedback = (feedback: IFeedback, reseller: IReseller, site: ISite, dispatch: AppDispatch): Promise<IFeedbackResponse> => {
	const endpoint = host + `/resellers/${reseller.id}/feedbacks`;

	return new Promise(async (resolve, reject) => {
		const data = feedback.files;

		data.append("subject", feedback.title);
		data.append("body", feedback.body);
		data.append("siteId", site.id.toString());

		try {
			const response: AxiosResponse<IFeedbackResponse> = await request<IFeedbackResponse>(
				host,
				{
					url: endpoint,
					method: "POST",
					data: feedback.files
				},
				dispatch
			);

			resolve(response.data);
		} catch (e: any) {
			reject(e.response);
		}
	});
};

export const feedbackService = { postFeedback };
