import React from 'react';
import { Row, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import ProfileMenuEntryComponent from './profileMenuEntry.component';
import { IProfileMenuEntry } from './profile.component';

interface IProfileMenuComponentProps {
    menu : IProfileMenuEntry[]
}

const ProfileMenuComponent : React.FC<IProfileMenuComponentProps> = props => {

    const { t } = useTranslation();

    const renderProfileMenu = ( profileMenuEntry : IProfileMenuEntry ) => 
        <ProfileMenuEntryComponent 
            key={profileMenuEntry.name}
            icon={profileMenuEntry.icon} 
            label={t(profileMenuEntry.translation)} 
            color={profileMenuEntry.color} 
            onClick={profileMenuEntry.onClick} />


    return (

        <Card style={{width:'100%'}} >

            <Row gutter={0}>
                

                {props.menu.map( renderProfileMenu )}

            </Row>

        </Card>

    )

}

export default ProfileMenuComponent;