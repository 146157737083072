/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Table, { ColumnProps, ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { IPayment } from '../../../features/ZReports/models/IZReport';
import useMathUtilities from '../../../hooks/useMathUtilities';

import style from './paymentsTable.module.css';
import { ILanguageState } from '../../../shared/slices/languageSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../core/store/rootReducer';

interface IPaymentsTableProps {
    payments : IPayment[]
}

const PaymentsTable : React.FC<IPaymentsTableProps> = props => {

    const { t } = useTranslation();

    const { formatNumber } = useMathUtilities();

    const rightAlign : AlignSetting = 'right';

    const language: ILanguageState = useSelector(
		(state: RootState) => state.LanguageReducers
	)

    const columns = [
        {
            title: t('zreports.zreport.details.table.description'),
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: t('zreports.zreport.details.table.amount'),
            dataIndex: 'amount',
            key: 'amount',
            align: rightAlign
        },
    ]

    const dataSource = () => {

        const cashPayments = props.payments.filter( payment => payment.type === 'Cash');
        const otherPayments = props.payments.filter( payment => payment.type !== 'Cash');

        const totalCash = cashPayments.map( payment => payment.amount).reduce((acc, amount) => acc + amount, 0);
        const totalDrawer = props.payments.map(payment => payment.amount).reduce((acc,value) => acc + value, 0);

        let paymentList = cashPayments.map( payment => {
            let curDesc = ''
            if(payment.description === 'TicketLunch'){
                switch (language?.value) {
                    case 'FR':
                        curDesc = 'Titre de paiement';
                        break;
                    case 'IT':
                        curDesc = 'Buoni Pasto';
                        break;
                    default:
                        curDesc = 'Ticket Lunch'
                        break;
                }
            }else if(payment.description === 'CHANGE'){  
                switch (language?.value) {
                    case 'FR':
                        curDesc = 'Rendu';
                        break;
                    case 'IT':
                        curDesc = 'Resto';
                        break;
                    default:
                        curDesc = 'Rest'
                        break;
                }
            }else if(payment.description === 'COUPON_CHANGE'){  
                switch (language?.value) {
                    case 'FR':
                        curDesc = 'Bon avoir';
                        break;
                    case 'IT':
                        curDesc = 'Buono acquisto';
                        break;
                    default:
                        curDesc = 'Coupon/Voucher'
                        break;
                }  
            }else{
                curDesc = payment.description;
            }

            return ({description : curDesc, amount : formatNumber(payment.amount.toFixed(2)) + " €" })
        });
        paymentList.push({description: t('zreports.zreport.details.payments.totalCash'), amount : formatNumber(totalCash.toFixed(2) + " €")});
        paymentList.push( ...otherPayments.map( payment => {
                let curDesc = ''
                if(payment.description === 'TicketLunch'){
                    switch (language?.value) {
                        case 'FR':
                            curDesc = 'Titre de paiement';
                            break;
                        case 'IT':
                            curDesc = 'Buoni Pasto';
                            break;
                        default:
                            curDesc = 'Ticket Lunch'
                            break;
                    }
                }else if(payment.description === 'CHANGE'){  
                    switch (language?.value) {
                        case 'FR':
                            curDesc = 'Rendu';
                            break;
                        case 'IT':
                            curDesc = 'Resto';
                            break;
                        default:
                            curDesc = 'Rest'
                            break;
                    }
                }else if(payment.description === 'COUPON_CHANGE'){  
                    switch (language?.value) {
                        case 'FR':
                            curDesc = 'Bon avoir';
                            break;
                        case 'IT':
                            curDesc = 'Buono acquisto';
                            break;
                        default:
                            curDesc = 'Coupon/Voucher'
                            break;
                    }  
                }else{
                    curDesc = payment.description;
                }
                return ({description: curDesc, amount: formatNumber(payment.amount.toFixed(2)) + " €"})
            }   
        ))
        paymentList.push({ description: t('zreports.zreport.details.payments.totalDrawer'), amount : formatNumber(totalDrawer.toFixed(2)) + " €"})

        paymentList = paymentList.map( (payment, index) => ({...payment, key: index }))

        return paymentList;

    }

    return(

        <Table 
            size="small"
            rowClassName={(record, index) => record.description === t('zreports.zreport.details.payments.totalCash') || record.description === t('zreports.zreport.details.payments.totalDrawer') ? style.totalRow : ''}
            bordered
            columns={columns} 
            pagination={false}
            dataSource={dataSource()} />

    )

}

export default PaymentsTable;