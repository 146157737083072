import axios, { AxiosError } from "axios";
import { IToken } from "../models/IToken";
import qs from "qs";

const host = process.env.REACT_APP_AUTH_HOST;

const GRANT_TYPE_PASSWORD = "password";
const GRANT_TYPE_REFRESH = "refresh_token";

const login = (username: string, password: string): Promise<IToken> => {
	const url = host + `/login`;

	return new Promise(async (resolve, reject) => {
		const body = {
			grant_type: GRANT_TYPE_PASSWORD,
			username: username,
			password: password,
			client_id: process.env.REACT_APP_CLIENT_ID,
			client_secret: process.env.REACT_APP_CLIENT_SECRET
		};

		try {
			const response = await axios.post<IToken>(url, qs.stringify(body));

			resolve(response.data);
		} catch (e: unknown) {
			const err = e as AxiosError;
			reject(err.response);
		}
	});
};

const refreshToken = (refresh_token: string): Promise<IToken> => {
	const url = host + `/tokenrefresh`;

	return new Promise(async (resolve, reject) => {
		const body = {
			grant_type: GRANT_TYPE_REFRESH,
			refresh_token: refresh_token,
			client_id: process.env.REACT_APP_CLIENT_ID,
			client_secret: process.env.REACT_APP_CLIENT_SECRET
		};

		try {
			const response = await axios.post<IToken>(url, qs.stringify(body));

			resolve(response.data);
		} catch (e: unknown) {
			const err = e as AxiosError;
			reject(err.response);
		}
	});
};

export const auth = { login, refreshToken };
