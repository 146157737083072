import { SyntheticEvent } from "react";

interface IPagePosition {
     isTop: boolean,
     isBottom: boolean,
     direction: ScrollDirection
}

export enum ScrollDirection {
     "DOWN" = "DOWN",
     "UP" = "UP"
}

const usePagePosition = () => {

     let lastScrollY = 0;


     const onPositionChange = (e : SyntheticEvent) : IPagePosition => 
     {

          let scrollDirection : ScrollDirection;
          scrollDirection =  e.currentTarget.scrollTop > 0 && lastScrollY <= e.currentTarget.scrollTop ? ScrollDirection.DOWN : ScrollDirection.UP;

          lastScrollY = e.currentTarget.scrollTop;

          return {
               direction: scrollDirection,
               isBottom: isPageBottomReached(e.currentTarget),
               isTop: isPageTopReached(e.currentTarget)
          }

     }

     const isPageBottomReached = (target : Element) => {

          /**
           * Added a little percentage of margin due to a different scrolling calculation between different browsers, mobile devices etc..
           */
          if(target.scrollHeight - target.scrollTop <= ( target.clientHeight *1.05)){
               return true;
          }
          return false;
     }

     const isPageTopReached = (target : Element) => {
          if(target.scrollTop === 0) return true;
          return false;
     }

     return {onPositionChange};

}

export default usePagePosition;