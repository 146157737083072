import React from 'react';

const DashboarcComponentTitle : React.FC<{ title : string}> = props => {

    return (

        <div style={{textAlign: 'center', marginBottom: '1vh', fontSize: '4.5vw'}}>
            {props.title}
        </div>

    )

}

export default DashboarcComponentTitle;