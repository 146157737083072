import React from 'react';
import CustomBadge, {BadgeColors } from '../../../layouts/CustomBadge/CustomBadge';
import style from '../../sites/sites.module.css';

export interface SubCategoryItem {
     title : string,
     count ?: number,
     color ?: keyof typeof BadgeColors
}
interface IElectronicInvoiceResumeSubCategoryProps {
     title : string,
     items ?: SubCategoryItem[]
}

const ElectronicInvoiceResumeSubCategory : React.FC<IElectronicInvoiceResumeSubCategoryProps> = props => {

     const renderCustomBadge = (item : SubCategoryItem, index: number) => 
          <div key={index}>
               <div className={style.mb1rem}><CustomBadge text={item.title} color={item.color} count={item.count} /></div>
          </div>
     

     return (
          <>
               <h3>{props.title}</h3>
               <hr/>
               {props.items?.map(renderCustomBadge)}
               <br/>
          </>
     );
}

export default ElectronicInvoiceResumeSubCategory;