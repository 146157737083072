import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BaseResponse from './BaseResponse';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import style from './response.module.css';
import { Col, Row } from 'antd';

interface ISuccessResponseProps {
    text : string;
}

const SuccessResponse : React.FC<ISuccessResponseProps> = props => {

    return (

        <BaseResponse backgroundColor="#99f3bd" >

                <Row style={{height:'100%'}}>

                    <Col xs={4}>

                        <div className={style.textItem}>

                            <FontAwesomeIcon icon={faCheckCircle} size="lg" />

                        </div>

                    </Col>
                    <Col xs={20} style={{height:'100%'}}>

                        <div className={style.textItem}>

                            {props.text}

                        </div>

                    </Col>

                </Row>

        </BaseResponse>

    )

}

export default SuccessResponse;