import React from 'react';
import { IUser } from '../../shared/models/IUser';
import { Card, Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

interface IAvatarComponentProps {
    user : IUser
}

const AvatarComponent : React.FC<IAvatarComponentProps> = props => {

    const fontSize : string = props.user.username.length > 30 ? '2.8vw' : '3.7vw';

    return (

        <Card style={{width:'100%'}} >

            <Row gutter={0}>

                <Col xs={8} style={{textAlign:'center'}}>

                    <FontAwesomeIcon icon={faUser} size="4x" />

                </Col>

                <Col xs={16}>

                    <p style={{fontSize:fontSize}}>{props.user.username.charAt(0).toUpperCase() + props.user.username.slice(1)}</p>

                </Col>

            </Row>

        </Card>

    )

}

export default AvatarComponent;