/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import Select, { SelectValue } from 'antd/lib/select';
import { Button, Input } from 'antd';
import { IMaterial } from '../../features/Materials/data/materials';
import useMathUtilities from '../../hooks/useMathUtilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import usePrevious from '../../hooks/usePrevious';
import SuccessResponse from '../../layouts/Response/SuccessResponse';
import ErrorResponse from '../../layouts/Response/ErrorResponse';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import useCharset from '../../hooks/useCharset';

interface IMaterialFormProps {
    materialOptions : IMaterial[];
    onSubmit: ( material : IMaterial, quantity : number, notes : string ) => void;
    isProcessing: boolean;
    isError:boolean;
}

const MaterialForm : React.FC<IMaterialFormProps> = props => {

    const { formatNumber } = useMathUtilities();

    const { t } = useTranslation();

    const { isASCII } = useCharset();

    const materialOptions = props.materialOptions.map( material => ({label: material.description, value: material.id }));

    const [ selectedMaterial, setSelectedMaterial ] = useState<IMaterial | undefined >( undefined );

    const [ quantity, setQuantity ] = useState<number | undefined>(undefined);

    const [ notes, setNotes ] = useState<string>(''); 

    const [ isFormValid, setIsFormValid ] = useState<boolean>(false);

    const [ showSuccess, setShowSuccess ] = useState<boolean>(false);

    const [ showError, setShowError ]    = useState<boolean>(false);

    const prevProps = usePrevious<{isProcessing : boolean, isError : boolean}>({isProcessing: props.isProcessing, isError: props.isError});

    const handleSelect = ( selection : SelectValue ) => {

        const selected = props.materialOptions.find( material => material.id === selection);

        setSelectedMaterial(selected);

    }

    const handleQuantity = ( quantity : number ) => {

        setQuantity(quantity);

    }

    const handleNotes = ( notes : string ) => {
        
        setNotes( notes );

    }

    const handleSubmit = () => {

        if( selectedMaterial !== undefined && quantity !== undefined && notes)
            props.onSubmit(selectedMaterial, quantity, notes);

    }

    const resetForm = () => {

        //setSelectedMaterial(undefined);
        setQuantity(undefined);
        setNotes('');

    }

    useEffect(() => {

        if(prevProps?.isProcessing && !props.isError && !props.isProcessing) {
            setShowSuccess(true);
            resetForm();
        }
        
        if(prevProps?.isProcessing && props.isError && !props.isProcessing) {
            setShowError(true);
            resetForm();
        }

    }, [props.isError, props.isProcessing])

    useEffect(() => {

        if(quantity && selectedMaterial && quantity >= selectedMaterial?.minQuantity && quantity <= selectedMaterial?.maxQuantity) setIsFormValid( true );
        else setIsFormValid( false );

    }, [quantity, selectedMaterial])

    return(

        <Form>

            { showSuccess ? <SuccessResponse text={t('consumables.requestSuccess')} />  : null }
            { showError ? <ErrorResponse text={t('consumables.requestError')} /> : null }

            <FormItem
                label={t('consumables.title')}
                rules={[{required: true}]} >

                <Select 
                    placeholder={t('consumables.form.selectMaterialPlaceHolder')}
                    onSelect={ handleSelect } 
                    options={materialOptions} />

            </FormItem>

            { selectedMaterial && <React.Fragment>

                { selectedMaterial.price && 
                        <FormItem>
                            <Input value={ formatNumber(selectedMaterial?.price.toFixed(2) || '0.00') + " €"} disabled />
                        </FormItem> }

                    <FormItem
                        label={t('consumables.form.quantityPlaceholder')}
                        rules={[{required: true}]}
                        >
                        <Input 
                            onChange={ e => handleQuantity(parseInt(e.target.value))}
                            value={quantity}
                            placeholder={t('consumables.form.quantityPlaceholder')}
                            min={selectedMaterial.minQuantity}
                            max={selectedMaterial.maxQuantity}
                            type="number" />
                    </FormItem>

                    <FormItem label={t('consumables.form.notes')}>

                        <TextArea 
                            value={notes}
                            onChange={e => handleNotes(e.target.value) } />

                    </FormItem>

                    <FormItem>

                        <Button 
                            onClick={handleSubmit}
                            type="primary" 
                            disabled={!isFormValid && !props.isProcessing}>
                            { props.isProcessing ? <FontAwesomeIcon icon={faSpinner} spin /> : t('consumables.form.submitButton') }
                        </Button>

                    </FormItem>
                </React.Fragment> }

        </Form>

    )

}

export default MaterialForm;