import React from 'react'
import { Card } from 'antd';

interface IWarningComponentProps {
     title: string | React.ReactNode,
     message: string
}
const WarningComponent : React.FC<IWarningComponentProps> = props => {
     return (
          <Card title={props.title} style={{margin:"8px"}}>
               <p>{props.message}</p>
          </Card>
     );
}

export default WarningComponent ;
