import React from 'react';
import DefaultLayout from '../layouts/PageLayout/DefaultLayout';
import NotificationComponent from '../components/notification/notification.component';

const NotificationContainer : React.FC<{}> = props => {

    return (

        <DefaultLayout >

            <NotificationComponent />

        </DefaultLayout>

    )

}

export default NotificationContainer;