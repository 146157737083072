import React from 'react';
import { Button, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ITemporalSlidingButtonProps {
    onClick : (...args: any) => void;
    direction: 'previous' | 'next';
}

const TemporalSlidingButton : React.FC<ITemporalSlidingButtonProps> = props => {

    const icon : IconProp = props.direction === 'previous' ? faChevronLeft : faChevronRight;

    return (

        <Row style={{height:'100%'}}>

            <div style={{margin:'auto'}}>

                <Button size="small" onClick={props.onClick}>
                    <FontAwesomeIcon icon={icon} />
                    <FontAwesomeIcon icon={icon} />
                </Button>

            </div>

        </Row>

    )

}

export default TemporalSlidingButton;