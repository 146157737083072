/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SitesComponent from "../components/sites/sites.component";
import { RootState } from "../core/store/rootReducer";
import Loading from "../layouts/Loading/Loading";
import DefaultLayout from "../layouts/PageLayout/DefaultLayout";
import { ISite } from "../shared/models/ISite";
import { selectSite, unselectSite } from "../shared/slices/siteSlice";
import { ISitesState, fetchSites } from "../shared/slices/sitesSlices";

const SitesContainer: React.FC<{}> = (props) => {
	const [action, setAction] = useState("show");

	const sites: ISitesState = useSelector((state: RootState) => state.SitesReducers);

	const dispatch = useDispatch();
	const MINUTES_MS = 300000;
	let interval: undefined | ReturnType<typeof setTimeout>;

	useEffect(() => {
		fetch();
	}, []);

	useEffect(() => {
		const hide = () => setAction("hide");

		const show = () => setAction("show");

		const handleVisibilityChange = () => {
			document.hidden ? hide() : show();
		};

		document.addEventListener("visibilitychange", handleVisibilityChange, false);
		window.addEventListener("focus", show, false);
		window.addEventListener("blur", hide, false);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			window.removeEventListener("focus", show);
			window.removeEventListener("blur", hide);
		};
	}, [setAction]);

	useEffect(() => {
		if (action === "show") {
			forceFetchSites();
		} else {
			stopInterval();
		}
	}, [action]);

	const stopInterval = () => {
		clearInterval(interval);
	};

	const forceFetchSites = () => {
		stopInterval();
		interval = setInterval(() => {
			dispatch(fetchSites(1, true));
		}, MINUTES_MS);
	};

	const fetch = () => {
		dispatch(unselectSite(null));
		dispatch(fetchSites());
	};

	const history = useHistory();

	const handleSelectSite = (site: ISite) => {
		dispatch(selectSite(site));
		history.push("/site-home");
	};

	const refresh = () => {
		if (!sites.loading) fetch();
	};

	useEffect(() => {
		console.log("sites", sites);
		if (sites.sites && sites.sites.length === 1) {
			handleSelectSite(sites.sites[0]);
		}
	}, [sites]);

	return (
		<DefaultLayout onRefresh={refresh}>
			{sites.loading ? (
				<Loading />
			) : (
				sites.sites.length !== 1 && <SitesComponent onRefresh={refresh} isError={sites.error} sites={sites.sites} selectSite={handleSelectSite} />
			)}
		</DefaultLayout>
	);
};

export default SitesContainer;
