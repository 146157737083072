import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../core/store/store";
import { zReportService } from "../services/zreport.service";
import { IZReport } from "../models/IZReport";
import { IZReportStatistics } from "../models/IZReportStatistics";

import { zreportStoreLocal, zreportGetStore, zreportCheckStore } from "../../../shared/storage.js";

export interface IZReportState {
	isLoading: boolean;
	isError: boolean;
	zreport: IZReport | null;
}

const initialState: IZReportState = {
	isLoading: false,
	isError: false,
	zreport: null
};

const zReportSlice = createSlice({
	name: "zReport",
	initialState,
	reducers: {
		zReportFetching: (state: IZReportState, action: PayloadAction<boolean>) => {
			state.isError = false;
			state.isLoading = action.payload;
			state.zreport = null;
		},

		zReportSuccessfulFetch: (state: IZReportState, action: PayloadAction<IZReport>) => {
			state.isError = false;
			state.isLoading = false;
			state.zreport = action.payload;
		},

		zReportErrorFetch: (state: IZReportState, action: PayloadAction<null>) => {
			state.isError = true;
			state.isLoading = false;
			state.zreport = action.payload;
		},

		zReportSetStatistics: (state: IZReportState, action: PayloadAction<IZReportStatistics>) => {
			if (state.zreport) {
				let zreport = Object.assign({}, state.zreport?.records);

				zreport.statistics = action.payload;

				state.zreport.records = zreport;
			}
		}
	}
});

export const { zReportFetching, zReportSuccessfulFetch, zReportErrorFetch, zReportSetStatistics } = zReportSlice.actions;

export default zReportSlice.reducer;

export const fetchZReport =
	(siteId: number, zReportId: number): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(zReportFetching(true));

			//const checkStore = zreportCheckStore("zreport_home_", siteId);

			const obj = await zReportService.getById(siteId, zReportId, dispatch<any>);
			//Salvo Z report nel local storage
			zreportStoreLocal("zreport_home_", obj, siteId, 720);

			const zreport = obj; //await zReportService.getById(siteId, zReportId, dispatch);

			console.log(zreport);

			dispatch(zReportSuccessfulFetch(zreport));

			const statistics = await zReportService.getStatisticsById(siteId, zReportId, dispatch<any>);

			dispatch(zReportSetStatistics(statistics.records));
		} catch (e) {
			dispatch(zReportErrorFetch(null));
		}
	};
