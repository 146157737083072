import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18next from "i18next";

export enum Languages {
	"IT",
	"EN",
	"FR"
}

export interface ILanguageState {
	value: string;
}

const localStorageUser = localStorage.getItem("language") ?? null;

const initialLanguageState: ILanguageState = {
	value: localStorageUser ? localStorageUser : navigator.language.split("-")[0].toUpperCase()
};

const languageSlice = createSlice({
	name: "Language",
	initialState: initialLanguageState,
	reducers: {
		setLanguage: (state: ILanguageState, action: PayloadAction<string>) => {
			localStorage.setItem("language", action.payload);
			state.value = action.payload;
			i18next.changeLanguage(action.payload.toLowerCase(), (err) => {
				console.error("Error changing language");
			});
		}
	}
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
