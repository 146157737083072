import React from 'react';
import { ISite } from '../../shared/models/ISite';
import { Row, Col } from 'antd';

import style from './sites.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faChevronRight } from '@fortawesome/free-solid-svg-icons';


interface ISiteEntryTitleProps {
    site: ISite
}

const SiteEntryTitle : React.FC<ISiteEntryTitleProps> = props => {

    return(

        <React.Fragment>

            <Row>

                <Col xs={16}>

                    <Row>

                        <Col xs={24}>

                            <span className={style.activityName}>{props.site.name}</span>

                        </Col>

                    </Row>

                    <Row className={style.activityPlace}>

                            <Col xs={3}>

                                <FontAwesomeIcon style={{marginTop:'4px'}} icon={faMapMarkerAlt} size="2x" />

                            </Col>
                            <Col xs={21}>

                                <Row>


                                    <Col xs={24}>

                                        <span>{props.site.street}, {props.site.zip}</span>

                                    </Col>
                                    <Col xs={24}>

                                        <span>{props.site.city} ( {props.site.province}) {props.site.country}</span>
                                        

                                    </Col>

                                </Row>
        

                            </Col>

                        </Row>


                </Col>

                <Col xs={8} style={{textAlign:'right'}}>

                    <Row>

                        <Col xs={24}>

                            <FontAwesomeIcon icon={faChevronRight} size="lg" color="#af0404"/>

                        </Col>

                    </Row>
                    <Row>

                        <Col xs={24}>

                            <span className={style.activityPlace}>ID {props.site.id}</span>

                        </Col>

                    </Row>

                </Col>


            </Row>

        </React.Fragment>


    )

}

export default SiteEntryTitle;