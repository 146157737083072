import { ReactComponent as IconBill } from '../../assets/icons/icon-bill.svg';
import { ReactComponent as IconCalendar } from '../../assets/icons/icon-calendar.svg';
import { ReactComponent as IconCover } from '../../assets/icons/icon-covers.svg';
import { ReactComponent as IconDocuments } from '../../assets/icons/icon-documents.svg';
import { ReactComponent as IconCounterAverage } from '../../assets/icons/icon-average-counter.svg';
import { ReactComponent as IconCoverAverage } from '../../assets/icons/icon-average-cover.svg';
import { ReactComponent as IconDocumentAverage } from '../../assets/icons/icon-average-document.svg';
import { ReactComponent as IconMatCons } from '../../assets/icons/icon-matcons.svg';
import { ReactComponent as MyBasiqHeaderLogo } from '../../assets/icons/mybasiq_header_logo.svg';

export const customIcons = {
    IconBill,
    IconCalendar,
    IconCover,
    IconDocuments,
    IconCounterAverage,
    IconCoverAverage,
    IconDocumentAverage,
    IconMatCons,
    MyBasiqHeaderLogo
};

export type CustomIconType = typeof IconDocumentAverage;
