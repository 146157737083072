import store from '../core/store/store';
import { ILanguageState } from '../shared/slices/languageSlice';

export declare type NumberSeparator = "." | ","

const useMathUtilities = () => {

    const language : ILanguageState = store.getState().LanguageReducers;

    const decimalSeparator : NumberSeparator = language.value === 'EN' ? '.' : ',';
    const thousandSeparator: NumberSeparator = language.value === 'EN' ? ',' : '.';

    const formatNumber = ( number : string ) => {

        var x = number.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? decimalSeparator + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + thousandSeparator + '$2');
        }
        return x1 + x2;

    }

    return  { formatNumber }


}

export default useMathUtilities;