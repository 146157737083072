import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../core/store/rootReducer';
import { fetchZReport, IZReportState } from '../features/ZReports/slices/zReportSlice';
import ZReportDetailComponent from '../components/zreport/zreportDetail.component';
import Loading from '../layouts/Loading/Loading';
import DefaultLayout from '../layouts/PageLayout/DefaultLayout';
import { ISiteState } from '../shared/slices/siteSlice';
import { useParams } from 'react-router-dom';

const ZReportDetailContainer: React.FC<{}> = props => {

	const { id } = useParams<{ id: string }>();

	const zreport: IZReportState = useSelector(
		(state: RootState) => state.ZReportReducers
	);

	const siteState: ISiteState = useSelector(
		(state: RootState) => state.SiteReducers
	)

	const dispatch = useDispatch();

	const refresh = () => {

		siteState.site && dispatch(fetchZReport(siteState.site?.id, +id));

	}

	return (
		<DefaultLayout>{
			zreport.isLoading ?
				<Loading /> :
				<ZReportDetailComponent
					onRefresh={refresh}
					isError={zreport.isError}
					zreportDetail={zreport.zreport} />
		}</DefaultLayout>
	);
}

export default ZReportDetailContainer;