import React from 'react';
import { IZReportStatistics } from '../../../features/ZReports/models/IZReportStatistics';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import useMathUtilities from '../../../hooks/useMathUtilities';

interface IStatisticsTableProps {
    statistics : IZReportStatistics
}

const StatisticsTable : React.FC<IStatisticsTableProps> = props => {

    const { t } = useTranslation();

    const alignRight : AlignSetting = 'right';

    const { formatNumber }   = useMathUtilities();

    const columns = [
        {
            title: t('zreports.zreport.details.table.quantity'),
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: t('zreports.zreport.details.table.description'),
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: t('zreports.zreport.details.table.amount'),
            dataIndex: 'amount',
            key: 'amount',
            align: alignRight
        },
    ]

    const dataSource = [
        {
            quantity : props.statistics.void.countPartialVoids,
            description: t('zreports.zreport.details.statistics.partialVoids'),
            amount : formatNumber(props.statistics.void.amountPartialVoids) + " €"
        },
        {
            quantity: props.statistics.void.countFullVoidDocuments,
            description: t('zreports.zreport.details.statistics.fullVoids'),
            amount : formatNumber(props.statistics.void.amountFullVoidDocuments) + " €"

        },
        {
            quantity: props.statistics.refunds.reduce((acc, refund) => acc + refund.quantity, 0),
            description: t('zreports.zreport.details.statistics.refundedArticles'),
            amount: formatNumber(props.statistics.refunds.reduce((acc, refund) => acc + parseFloat(refund.totalAmount), 0).toFixed(2)) + " €"
        },
        {
            quantity: props.statistics.erased.countErasedDocuments + props.statistics.erased.countTransferredDocuments,
            description: t('zreports.zreport.details.statistics.erasedArticles'),
            amount: formatNumber(props.statistics.erased.amountTotal) + " €"
        },
        {
            quantity: props.statistics.customerOffers.quantity,
            description: t('zreports.zreport.details.statistics.customerOffers'),
            amount: formatNumber(props.statistics.customerOffers.amount) + " €"
        },
        {
            quantity: props.statistics.selfconsume.quantity,
            description: t('zreports.zreport.details.statistics.selfConsume'),
            amount: formatNumber(props.statistics.selfconsume.amount) + " €"
        },   
        {
            quantity: props.statistics.corrections.quantity,
            description: t('zreports.zreport.details.statistics.corrections'),
            amount: formatNumber(props.statistics.corrections.amount) + " €"
        },
        {
            quantity: props.statistics.discountAndCharges.reduce((acc, dc) => acc + dc.quantity, 0),
            description: t('zreports.zreport.details.statistics.discountCharges'),
            amount: formatNumber(props.statistics.discountAndCharges.reduce((acc, dc) => acc + parseFloat(dc.amount), 0).toFixed(2)) + " €"
        },
        {
            quantity: props.statistics.wasteds.reduce((acc, waste) => acc + waste.quantity, 0),
            description: t('zreports.zreport.details.statistics.wasteds'),
            amount: formatNumber(props.statistics.wasteds.reduce((acc, waste) => acc + parseFloat(waste.amount), 0).toFixed(2)) + " €"
        }    
    ].map((elem, index) => ({...elem, key : index}))

    return (

        <Table 
            pagination={false}
            bordered
            size="small"
            columns={columns} 
            dataSource={dataSource} />

    )

}

export default StatisticsTable;