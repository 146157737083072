import React from 'react';
import { IUserState } from '../shared/slices/userSlices';
import { useSelector } from 'react-redux';
import { RootState } from '../core/store/rootReducer';
import { useHistory } from 'react-router-dom';
import { ISiteState } from '../shared/slices/siteSlice';

import TopToolbar from '../layouts/TopToolbar/TopToolbar';
import { IAppStatusState } from '../shared/slices/appStatusSlice';

const ToolbarContainer : React.FC<{}> = props => {

    const user : IUserState = useSelector(
        ( state : RootState) => state.UserReducers
    );

    const site : ISiteState = useSelector(
        (state : RootState) => state.SiteReducers
    )

    const appStatus : IAppStatusState = useSelector(
        (state : RootState) => state.AppStatusReducers
    );

    const history = useHistory();

    const handleBack = () => {

        history.go(-1);

    }

    const handleTitleClick = () => {

        history.push("/sites");

    }

    const handleUser = () => {

        history.push("/profile/0");

    }

    return( 
        <React.Fragment>
            <TopToolbar 
                online={appStatus.online}
                onUser={handleUser}
                onBack={handleBack} 
                site={site.site} 
                user={user.user?.username || ""} 
                onTitleClick={handleTitleClick}
            /> 
        </React.Fragment>
    )

}

export default ToolbarContainer;