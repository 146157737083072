import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationIT from './locales/it/translation.json';
import translationFR from './locales/fr/translation.json';

import { ILanguageState } from "./shared/slices/languageSlice";
import store from "./core/store/store";

const language : ILanguageState = store.getState().LanguageReducers;

const resources = {
    en: {
        translation: translationEN
    },
    it: {
        translation: translationIT
    },
    fr: {
      translation: translationFR
    }
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: language.value.toLowerCase(),
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;