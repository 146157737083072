import { ISite } from "../models/ISite";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../core/store/store";
import { siteService } from "../services/site.service";
import { zReportService, IOrderBy, ISortBy } from "../../features/ZReports/services/zreport.service";

import { zreportStoreLocal, zreportGetStore, zreportCheckStore } from "../../shared/storage.js";

const MAXIMUM_RETRY_TENTATIVES = 5;
const host = process.env.REACT_APP_API_HOST || "";

export interface ISitesState {
	sites: ISite[];
	error: boolean;
	loading: boolean;
}

const initialSitesState: ISitesState = {
	sites: [],
	error: false,
	loading: false
};

const sitesSlice = createSlice({
	name: "sites",
	initialState: initialSitesState,
	reducers: {
		sitesFetching: (state: ISitesState, action: PayloadAction<boolean>) => {
			state.error = false;
			state.loading = action.payload;
		},

		sitesFetchedSuccessfully: (state: ISitesState, action: PayloadAction<ISite[]>) => {
			state.error = false;
			state.loading = false;
			state.sites = action.payload;
		},

		sitesFetchFailed: (state: ISitesState, action: PayloadAction<null>) => {
			state.error = true;
			state.loading = false;
			state.sites = [];
		},

		setSiteLastZReportHeader: (state: ISitesState, action: PayloadAction<ISite>) => {
			state.sites.forEach((site) => {
				if (site.id === action.payload.id) {
					site.last_z_report = action.payload.last_z_report;
					site.live = action.payload.live;
				}
			});
		}
	}
});

export const { sitesFetching, sitesFetchedSuccessfully, sitesFetchFailed, setSiteLastZReportHeader } = sitesSlice.actions;

export default sitesSlice.reducer;

export const fetchSites =
	(currentRetry: number = 1, force: boolean = false): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(sitesFetching(true));

			let sites = await siteService.getSites(dispatch<any>);

			const updatedSites = sites.records.map(async (site) => {
				const checkStore = zreportCheckStore("zreport_single_", site.id);
				let obj, temp, objL;

				if (checkStore && !force) {
					temp = zreportGetStore("zreport_single_", site.id);
					obj = temp.data;
				} else {
					obj = await zReportService.getAll(site.id, dispatch<any>, 1, 1, IOrderBy.startDate, ISortBy.desc);
					zreportStoreLocal("zreport_single_", obj, site.id);
				}
				const response = obj;

				var site_id = site.id;

				const checkStoreLive = zreportCheckStore("zreport_live_", site.id);

				if (checkStoreLive && !force) {
					temp = zreportGetStore("zreport_live_", site.id);
					console.log("Uso il local storage dati LIVE per ancora :" + temp.remainingtime + " minuti");
					objL = temp.data;
				} else {
					let jsonLive = null;
					let responseLive = await siteService.getLiveData(site_id, dispatch<any>);
					let cover_finalized,
						amount_finalized,
						document_finalized,
						cover_not_finalized,
						amount_not_finalized,
						document_not_finalized,
						cover_tot,
						amount_tot,
						document_tot,
						erased_doc,
						refund,
						amount_waste_tot = 0;
					let amount_waste,
						amount_tot_string = "0.00";
					let lastUpdate = "";

					jsonLive = responseLive?.data ?? {};

					lastUpdate = responseLive?.last_update ?? "";

					const covers_finalized_documents = jsonLive?.finalized_documents?.covers ?? 0;
					const covers_other_documents = jsonLive?.other_documents?.covers ?? 0;
					const amount_finalized_documents = jsonLive?.finalized_documents?.amount ?? 0;
					const amount_other_documents = jsonLive?.other_documents?.amount ?? 0;
					const count_finalized_documents = jsonLive?.finalized_documents?.count ?? 0;
					const count_other_documents = jsonLive?.other_documents?.count ?? 0;

					//First row live
					cover_finalized = covers_finalized_documents + covers_other_documents;
					amount_finalized = amount_finalized_documents + amount_other_documents;
					document_finalized = count_finalized_documents + count_other_documents;

					// Second row live
					cover_not_finalized = jsonLive?.open_orders?.covers ?? 0;
					amount_not_finalized = jsonLive?.open_orders?.amount ?? 0;
					document_not_finalized = jsonLive?.open_orders?.count ?? 0;

					//Third row live
					cover_tot = cover_finalized + cover_not_finalized;
					amount_tot = amount_finalized + amount_not_finalized;
					document_tot = document_finalized + document_not_finalized;

					amount_finalized = amount_finalized > 0 ? (amount_finalized / 100).toFixed(2) : "0.00";
					amount_not_finalized = amount_not_finalized > 0 ? (amount_not_finalized / 100).toFixed(2) : "0.00";
					amount_tot_string = amount_tot > 0 ? (amount_tot / 100).toFixed(2) : "0.00";

					erased_doc = jsonLive?.erased_documents?.amount ?? 0;
					refund = jsonLive?.refund?.amount ?? 0;
					amount_waste_tot = erased_doc + refund;

					amount_waste = (amount_waste_tot / 100).toFixed(2);

					objL = {
						cover_finalized: cover_finalized,
						amount_finalized: amount_finalized,
						document_finalized: document_finalized,
						cover_not_finalized: cover_not_finalized, //Math.round(Math.random() * (10 - 100) + 100)
						amount_not_finalized: amount_not_finalized,
						document_not_finalized: document_not_finalized,
						cover_tot: cover_tot,
						amount_tot: amount_tot_string,
						document_tot: document_tot,
						amount_waste: amount_waste,
						lastUpdate: lastUpdate
					};
					const time = 1;

					zreportStoreLocal("zreport_live_", objL, site.id, time);
				}

				const objLive = objL;

				return {
					...site,
					last_z_report: response?.records && response?.records.length > 0 ? response?.records[0] : {},
					covertot: 0,
					live: objLive
				};
			});

			Promise.all(updatedSites)
				.then((sites) => {
					dispatch(sitesFetchedSuccessfully(sites));
				})
				.catch((err) => {
					console.log(err);
					dispatch(sitesFetchFailed(null));
				});
		} catch (e) {
			if (currentRetry < MAXIMUM_RETRY_TENTATIVES) dispatch(fetchSites(currentRetry + 1));
			else dispatch(sitesFetchFailed(null));
		}
	};
