import { ISite } from "../models/ISite";
import { AppDispatch } from "../../core/store/store";
import request from "../../core/request/request";
import { IApiServerResponse } from "../../core/request/IApiServerResponse";

import { zreportStoreLocal, zreportGetStore, zreportCheckStore } from "../../shared/storage.js";
import { AxiosError } from "axios";

const host = process.env.REACT_APP_API_HOST || "";

const getSites = (dispatch: AppDispatch): Promise<IApiServerResponse<ISite[]>> => {
	return new Promise(async (resolve, reject) => {
		try {
			//const response = await request< IApiServerResponse< ISite[] > >(host, { url: '/sites', method:'GET'}, dispatch);
			/****LOCAL STORAGE ****/
			const checkStore = zreportCheckStore("zreport_site_global_", 10);
			var obj, temp;
			if (checkStore) {
				temp = zreportGetStore("zreport_site_global_", 10);
				obj = temp.data;
			} else {
				obj = await request<IApiServerResponse<ISite[]>>(host, { url: "/sites", method: "GET" }, dispatch);

				zreportStoreLocal("zreport_site_global_", obj, 10, 720);
				temp = zreportGetStore("zreport_site_global_", 10);
			}
			/**** FINE ***/
			resolve(obj.data);
		} catch (e: unknown) {
			const err = e as AxiosError;
			const response = {} as IApiServerResponse<ISite[]>;
			resolve(response);
		}
	});
};

const getLiveData = (site_id: number, dispatch: AppDispatch): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await request<any>(host, { url: "/sites/" + site_id + "/live-data", method: "GET" }, dispatch);
			const result = response?.data ?? null;
			resolve(result);
		} catch (e: unknown) {
			const err = e as AxiosError;
			console.log("getLiveDataError", err);
			resolve(null);
		}
	});
};

export const siteService = { getSites, getLiveData };
