/* eslint-disable no-unused-vars */
const remainingtime = (obj) => {
	var dt1 = new Date(obj.cache_date);
	var dt2 = new Date();
	var diff = (dt2.getTime() - dt1.getTime()) / 1000;
	diff /= 60;
	var mins = Math.abs(Math.round(diff));
	return obj.timelive - mins;
};

const zreportStoreLocal = (storename, data, siteId, timelive = 720) => {
	var today = new Date();
	var obj = {
		cache_date: today,
		timelive: timelive,
		data: data
	};
	localStorage.setItem(storename + siteId, JSON.stringify(obj));
};

const zreportGetStore = (storename, siteId) => {
	let obj = JSON.parse(localStorage.getItem(storename + siteId));
	obj.remainingtime = remainingtime(obj);
	return obj;
};

const zreportCheckStore = (storename, siteId) => {
	if (localStorage.getItem(storename + siteId) === null) {
		return 0;
	} else {
		let obj = JSON.parse(localStorage.getItem(storename + siteId));
		var timelive = obj.timelive;
		var dt1 = new Date(obj.cache_date);
		var dt2 = new Date();
		var diff = (dt2.getTime() - dt1.getTime()) / 1000;
		diff /= 60;
		var mins = Math.abs(Math.round(diff));
		if (mins >= timelive) {
			return 0;
		} else {
			return 1;
		}
	}
};

export { zreportStoreLocal, zreportGetStore, zreportCheckStore };
