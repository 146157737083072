import React from 'react';
import { customIcons } from '../../../layouts/icons/icons';

interface ILastDateTooltipComponentProps {
    x : number;
    data : string;
}

const LastDateTooltipComponent : React.FC<ILastDateTooltipComponentProps> = props => {

    const { x, data} = props;

    const borderStyle : string = "0.5px solid black";
    const lineStyle : React.CSSProperties = {
        borderRight: borderStyle, 
        borderBottom: borderStyle,
        position:'absolute', 
        left : x,
        width: '15px',
        height:'25px', 
        top: '172px'
    };

    const dataStyle : React.CSSProperties = {
        textAlign:'right',
        position: 'absolute',
        width: x - 25,
        fontWeight: 'bold'
    };

    const IconCalendar = customIcons.IconCalendar;

    const iconStyle : React.CSSProperties = {
        marginTop:'1px',
        fontSize: '30px'
    }

    return (

        <React.Fragment>
            <div style={lineStyle}></div>
            <div style={dataStyle}>
                <div style={{height:'100%'}}>
                    <IconCalendar style={iconStyle} /> <span>{data}</span>
                </div>
            </div>
        </React.Fragment>

    )

}

export default LastDateTooltipComponent;