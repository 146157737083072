import React from 'react';
import { IElectronicInvoiceStatistics } from '../../../features/ElectronicInvoice/models/IElectronicInvoiceStatistics';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import ElectronicInvoiceResumeSubCategory, {SubCategoryItem} from './electronicInvoiceResumeSubCategory.component';

interface IElectronicInvoiceComponentProps {
     statistics : IElectronicInvoiceStatistics | null
}

const ElectronicInvoiceResumeComponent : React.FC<IElectronicInvoiceComponentProps> = props => {

     const { t } = useTranslation();

     const activeInvoicesItems : SubCategoryItem[] = [
          {
               title: t("electronicInvoice.resume.year.totalActiveInvoices"), 
               count: props.statistics?.year_total_active_invoices, 
               color: "green"
          },
          {
               title: t("electronicInvoice.resume.year.totalRejectedActiveInvoices"), 
               count: props.statistics?.year_total_rejected_active_invoices
          },
          {
               title: t("electronicInvoice.resume.year.totalElaborationErrorActiveInvoices"), 
               count: props.statistics?.year_total_elab_error_active_invoices
          },
          {
               title: t("electronicInvoice.resume.year.totalActiveInvoicesToResend"), 
               count: props.statistics?.year_total_to_resend_active_invoices
          },
          {
               title: t("electronicInvoice.resume.year.totalRefusedActiveInvoices"), 
               count: props.statistics?.year_total_refused_active_invoices
          },
          {
               title: t("electronicInvoice.resume.year.totalProcessingActiveInvoices"), 
               count: props.statistics?.year_total_processing_active_invoices,
               color: "yellow"
          }
     ];

     const incomingInvoicesItems : SubCategoryItem[] = [
          {
               title: t("electronicInvoice.resume.year.totalIncomingInvoices"), 
               count: props.statistics?.year_total_incoming_invoices
          },
          {
               title: t("electronicInvoice.resume.year.totalUnreadIncomingInvoices"),
               count: props.statistics?.year_total_unread_incoming_invoices
          }
     ]

     const dailyDataItems : SubCategoryItem[] = [
          {
               title: t("electronicInvoice.resume.daily.totalUploadedInvoices"), 
               count: props.statistics?.day_total_uploaded_invoices, 
               color: "green"
          },
          {
               title: t("electronicInvoice.resume.daily.totalReceivedInvoices"), 
               count: props.statistics?.day_total_received_invoices, 
               color: "green"
          }
     ];

     return (
          <>
               <Card title={t("electronicInvoice.resume.title")} style={{margin: "10px"}}>
                    
                    <ElectronicInvoiceResumeSubCategory title={t("electronicInvoice.resume.activeInvoices")} items={activeInvoicesItems} />      

                    <ElectronicInvoiceResumeSubCategory title={t("electronicInvoice.resume.incomingInvoices")} items={incomingInvoicesItems} />                    

                    <ElectronicInvoiceResumeSubCategory title={t("electronicInvoice.resume.daily.title")} items={dailyDataItems} />                    
                    
               </Card>
          </>
     );
}

export default ElectronicInvoiceResumeComponent;