/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import useDateUtilities from '../../hooks/useDateUtilities';
import { ITemporalSelectorProps } from './TemporalSelector';
import { useTranslation } from 'react-i18next';
import TemporalSlidingButton from './TemporalSlidingButton';
import TemporalDateRangeLabel from './TemporalDateRangeLabel';

const WeekTemporalSelector : React.FC<ITemporalSelectorProps> = props => {

    const { getWeekNumber, getDateOfISOWeek, formatDateToLocale, getLastDayWeek} = useDateUtilities();

    const currentDate = () : Date => { if( props.currentDate && !isNaN(props.currentDate?.getTime() || NaN )) return props.currentDate; else return new Date(); }

    const [ selectedWeek, setSelectedWeek ] = useState<number>(getWeekNumber(currentDate()));

    const [ startDate, setStartDate ] = useState<Date>();

    const [ stopDate, setStopDate] = useState<Date>();

    const [ display, setDisplay ] = useState<string>('');

    const { t } = useTranslation();

    useEffect(() => {

        setStartDate(getDateOfISOWeek(selectedWeek));

    }, [selectedWeek]);

    const changeWeek = ( shift : number ) => {

        setSelectedWeek(selectedWeek + shift);

    }

    useEffect(() => {

        
        startDate && setStopDate(getLastDayWeek(startDate));

    }, [startDate])


    useEffect(() => {

        startDate && stopDate && props.onDateChange( startDate, stopDate);

        startDate && setDisplay(
            t('shared.from') + " " + formatDateToLocale(startDate || new Date()) + " " + t('shared.to') + " " + formatDateToLocale(stopDate || new Date())
        )

    }, [stopDate])

    useEffect(() => {

        props.onDisplayChange && props.onDisplayChange(display);

    }, [display])

    return (

        <React.Fragment>

            <Row gutter={0}>

                <Col xs={3}>

                    <TemporalSlidingButton direction='previous' onClick={() => changeWeek(-1)} />

                </Col>

                <Col xs={18}>

                    <TemporalDateRangeLabel display={display} />

                </Col>

               {/*  <Col xs={3}></Col>
 */}
                <Col xs={3}>

                    <TemporalSlidingButton direction='next' onClick={() => changeWeek(+1)} />
                
                </Col>

            </Row>

        </React.Fragment>

    )

}

export default WeekTemporalSelector;