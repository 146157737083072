import React, { useState } from 'react';
import { IUserState, userLogout } from '../shared/slices/userSlices';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../core/store/rootReducer';
import BottomToolbar from '../layouts/BottomToolbar/BottomToolbar';
import { unselectSite } from '../shared/slices/siteSlice';
import { useHistory } from 'react-router-dom';
import LoginBottomToolbar from '../layouts/BottomToolbar/LoginBottomToolbar';
import { IAppStatusState } from '../shared/slices/appStatusSlice';
import OfflineBottomToolbar from '../layouts/BottomToolbar/OfflineBottomToolbar';
import RedirectModal from '../layouts/shared/RedirectModal';

const BottomToolbarContainer : React.FC<{}> = props => {

    const [ isOpenModal, setIsOpenModal ] = useState<boolean>(false);

    const user : IUserState = useSelector(
        ( state : RootState) => state.UserReducers
    );

    const appStatus : IAppStatusState = useSelector(
        ( state : RootState) => state.AppStatusReducers
    );

    const dispatch = useDispatch();

    const history = useHistory();

    const handleLogout = () => {

        const notCleanKey1 = 'deviceVersion';
        const notCleanKey2 = 'notificationsEnabled';
        const notCleanKey3 = 'deviceOS';
        const notCleanKey4 = 'appID';
        const list = Object.keys(localStorage);
        list.forEach((key) =>
          !notCleanKey1.includes(key) && !notCleanKey2.includes(key) && !notCleanKey3.includes(key) && !notCleanKey4.includes(key) ? localStorage.removeItem(key) : key
        );
        dispatch(userLogout(null));
        dispatch(unselectSite(null));

        history.push("/");

    }

    const handleHome = () => {

        history.push("/sites");

    }

    const handleNotification = () => {

        history.push("/notifications");

    }

    const toggleRedirectModal = () => {

        setIsOpenModal(!isOpenModal);

    }

    const onCloud = ( ) => {

        const redirectUrl : string = process.env.REACT_APP_4CO_HOST || '';

        window.location.href = redirectUrl;

    }


    return (
        <React.Fragment>
            { !appStatus.online && <OfflineBottomToolbar lastFetchData={appStatus.lastFetchDateTime || ''} /> }
            {user.user 
                ? <BottomToolbar 
                    notifications={0}
                    onHome={handleHome}
                    onCloud={onCloud}
                    onNotification={handleNotification}
                    onLogout={handleLogout} 
                    user={user.user} />
                : <LoginBottomToolbar />
            }

            <RedirectModal 
                isOpen={isOpenModal} 
                url={process.env.REACT_APP_4CO_HOST || ''} 
                toggle={toggleRedirectModal} />

        </React.Fragment>
    )

}

export default BottomToolbarContainer;