import Axios from "axios";

const usePolyfillMinMax = () => {

    let doc = window.document;

    const getElementStyle = ( element : HTMLElement ) : CSSStyleDeclaration | undefined => {

        if('getComputedStyle' in window) return getComputedStyle(element, null);

    }

    const appendStyle = ( style : HTMLStyleElement, cssString : string ) : void => {

        if(style.type) style.type = 'text/css';

        const cssNode = doc.createTextNode(cssString);
        style.appendChild(cssNode);

    }

    const browserSupportsMinMax = () : boolean => {

        const body = doc.body;
        const head = doc.head;

        const dummyElement      = doc.createElement('div');
        dummyElement.id = "dummyElemVW";
        body.appendChild(dummyElement);

        const dummyElementStyle = doc.createElement('style');
        head.appendChild(dummyElementStyle);

        const cssString         = '#dummyElemVW { width:min(50vw,120vw) }';
        const width             = parseInt((window.innerWidth / 2 ).toString(), 10);

        appendStyle( dummyElementStyle, cssString );

        const testW = parseInt( getElementStyle(dummyElement)?.width|| '0' , 10);

        body.removeChild(
            dummyElement
        );

        head.removeChild(
            dummyElementStyle
        );

        return testW === width;

    }

    const getCSS = async ( url : string ) : Promise<string> => {

        return new Promise( async ( resolve,reject) => {

            try{

                const response = await Axios.get<string>( url);
        
                resolve(response.data);
        
            }
            catch( e ) { reject(e) }

        })

    }

    const replaceMinMax = ( cssString : string ) => {

        const minMaxRe = /min\((.*?),(.*?)\)/g

        const minMaxReOnce = /min\((.*?),(.*?)\)/;

        const newStyle = doc.createElement('style');

        const minMaxList = cssString.matchAll(minMaxRe);

        const minMaxArray = Array.from(minMaxList);

        if(minMaxArray.length > 0) {

            minMaxArray.forEach( item => cssString = cssString.replace(minMaxReOnce, item[1]) )

            appendStyle( newStyle, cssString);

            const head = doc.head || doc.getElementsByTagName('head')[0]

            head.appendChild(newStyle);

        }

    }

    const replaceMinMaxWithFirstElement = async () => {

        const styles = doc.getElementsByTagName('style')
        const links = doc.getElementsByTagName('link');

        for (const style of styles) {
            style.textContent && replaceMinMax(style.textContent)
        }

        for (const link of links) {
            try{

                const css = await getCSS(link.href);
                replaceMinMax(css);

            }
            catch( e ) { console.log(e) }

        }

    }

    const polyfill = () => {

        if(!browserSupportsMinMax()) {

            console.log("Broswer does not support min max CSS rules");
            replaceMinMaxWithFirstElement();

        }
        else { console.log("Broswer supports min max CSS rules");}
    }

    return { polyfill }

}

export default usePolyfillMinMax;
