/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../core/store/store";
import { ISite } from "../../../shared/models/ISite";
import { IReseller } from "../../Reseller/models/IReseller";
import { IFeedback } from "../models/feedback";
import { feedbackService } from "../services/feedback.services";

export interface IFeedbackState {
	request: {
		isError: boolean;
		isProcessing: boolean;
	};
}

const initialState: IFeedbackState = {
	request: {
		isError: false,
		isProcessing: false
	}
};

const feedbackSlice = createSlice({
	name: "Feedback",
	initialState,
	reducers: {
		uplodingFeedback(state: IFeedbackState, action: PayloadAction<boolean>) {
			state.request.isError = false;
			state.request.isProcessing = action.payload;
		},

		uploadSuccess(state: IFeedbackState, action: PayloadAction<boolean>) {
			state.request.isError = !action.payload;
			state.request.isProcessing = false;
		}
	}
});

export const { uplodingFeedback, uploadSuccess } = feedbackSlice.actions;

export default feedbackSlice.reducer;

export const uploadFeedback =
	(feedback: IFeedback, reseller: IReseller, site: ISite): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(uplodingFeedback(true));

			const response = await feedbackService.postFeedback(feedback, reseller, site, dispatch<any>);

			dispatch(uploadSuccess(true));
		} catch (e) {
			dispatch(uploadSuccess(false));
		}
	};
