/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { CashInOut } from '../../../features/ZReports/models/IZReport';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';

import style from './Zreport.module.css';
import CashInOutTable from './cashInOutTable';

interface IZReportCashInOutComponentProps {
    cashInOuts: CashInOut[]
}

const ZReportCashInOutComponent : React.FC<IZReportCashInOutComponentProps> = props => {

    const { t } = useTranslation();

    return(
        <React.Fragment>

            <Card title={t('zreports.zreport.details.cashInOut.title')} style={{margin:"8px"}}>

                {props.cashInOuts.length 
                    ? <CashInOutTable cashInOuts={props.cashInOuts} />
                    : <p>{t('zreports.zreport.details.cashInOut.noMovements')}</p>
                }

            </Card>

        </React.Fragment>
    )

}

export default ZReportCashInOutComponent;