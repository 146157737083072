/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ISite } from '../../shared/models/ISite';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import SiteEntryTitle from './siteEntryTitle.component';
import SiteEntryLastZReport from './siteEntryLastZReport.component';
import SiteEntryLive from './siteEntryLive.component';
import { Row, Col } from 'antd'
import style from './sites.module.css';

interface ISiteEntryComponent {
    site : ISite,
    selectSite: (site : ISite) => void
}

const SiteEntryComponent: React.FC<ISiteEntryComponent> = props => {

    const handleClick = () => {

       props.selectSite(props.site);
    }

    const { t } = useTranslation();

    const classes = style.bgyellow + ' '+ style.centerValue

    const lastZReport = props.site.last_z_report;

    const sizeFont = '3vw';

    return (
        
            <React.Fragment>
                <Card 
                    title={<SiteEntryTitle site={props.site} />} 
                    onClick={handleClick} 
                    style={{margin: "10px", boxShadow:'0px 1px 1px #dbe1ec', borderRadius: '10px'}} 
                    bodyStyle={{backgroundColor:'#f1f3f8', padding: 0, marginBottom: 3 }}>

                    <Row style={{backgroundColor:'rgb(160 160 160)',padding:0,display: 'flex',alignItems: 'center', flexGrow: 1}}>
                        <Col xs={props.site.activity_type !== 'retail' ? 12 : 15} style={{ textAlign: 'center'}}>
                            <b>{t("shared.proceeds").toUpperCase()}</b>
                        </Col>
                        {props.site.activity_type !== 'retail' ? <Col xs={6} style={{ textAlign: 'center'}}>
                            <b>{t("shared.cover").toUpperCase()}</b>
                        </Col> : null}
                        <Col xs={props.site.activity_type !== 'retail' ? 6 : 9} style={{ textAlign: 'center'}}>
                            <b>{t("shared.documents").toUpperCase()}</b>
                        </Col>
                    </Row>
                    {props.site.last_z_report && <SiteEntryLastZReport lastZReport={props.site.last_z_report} activity_type={props.site.activity_type} />}
                    
                    <SiteEntryLive site={props.site} />
                </Card>


         
         </React.Fragment>

    )

}

export default SiteEntryComponent;