import { DateUtils } from "../shared/utils/dateUtils";

const useDateUtilities = () => {

    const getWeekNumber = (dt : Date)  => {

        var tdt = new Date(dt.valueOf());
        var dayn = (dt.getDay() + 6) % 7;
        tdt.setDate(tdt.getDate() - dayn + 3);
        var firstThursday = tdt.valueOf();
        tdt.setMonth(0, 1);
        if (tdt.getDay() !== 4) tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);

        return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);

    }

    const getDateOfISOWeek = (w : number, y : number = new Date().getFullYear()) => {

        var dt = new Date(y, 0, 1 + (w - 1) * 7);
        var dow = dt.getDay();
        var ISOweekStart = dt;

        if (dow <= 4)
            ISOweekStart.setDate(dt.getDate() - dt.getDay() + 1);
        else
            ISOweekStart.setDate(dt.getDate() + 8 - dt.getDay());
        return ISOweekStart;

    }

    const getLastDayWeek = ( dt : Date ) : Date => {

        return new Date(dt.getTime() + (6 * 86400000));

    }

    const formatDateToLocale = ( dt : Date ) : string => {

        return DateUtils.formatDateToLocale( dt );

    }

    const formatDateToServerFormat = ( dt : Date) : string => {

        return DateUtils.formatDateToServerFormat( dt );

    }

    const getDateRangeFromMonth = (m : number, y : number) => {

        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y,m + 1,0);

        return { firstDay, lastDay };

    }

    const getDatesInRange = ( startDate : Date, stopDate : Date ) => {

        return DateUtils.getDatesInRage( startDate, stopDate );

    }

    const shiftWeekFromDate = ( date : Date, w : number ) => {

        return shiftDaysFromDate(date, (w * 7));

    }

    const shiftDaysFromDate = ( date : Date, d: number) => {

        const day = (24*60*60*1000);

        return new Date( date.getTime() + (d * day));

    }

    const isStringValidDate = (date: string | undefined) : boolean => 
    {
        if(typeof(date) === "undefined") return false;

        if(isNaN(new Date(date).getTime())) return false;

        return true;
    }

    return { 
        getWeekNumber, 
        getDateOfISOWeek, 
        formatDateToLocale,
        formatDateToServerFormat, 
        getDateRangeFromMonth, 
        getLastDayWeek, 
        getDatesInRange, 
        shiftWeekFromDate, 
        shiftDaysFromDate,
        isStringValidDate
    }

}

export default useDateUtilities;