const formatDateToLocale = ( dt : Date ) => {

    const dd = dt.getDate();
    const mm = dt.getMonth() + 1;
    const yyyy = dt.getFullYear();

    return [dd, mm, yyyy].join('-');

}

const getDatesInRage = ( startDate : Date, stopDate : Date ) => {

    let arr = [];

    let curStartDate = new Date(startDate.setHours(0,0,0,0));
    let curStopDate = new Date(stopDate.setHours(0,0,0,0));

    while (curStartDate.getTime() <=  curStopDate.getTime()) {
        arr.push(new Date(curStartDate));
        curStartDate.setDate(curStartDate.getDate()+1)
    }

    return arr;

}

const formatDateToServerFormat = ( dt : Date) => {

    const dd = dt.getDate();
    const mm = dt.getMonth() + 1;
    const yyyy = dt.getFullYear();

    return [yyyy, mm.toString().padStart(2,'0'), dd.toString().padStart(2,'0')].join('-');

}

export const DateUtils = { formatDateToLocale, getDatesInRage, formatDateToServerFormat };