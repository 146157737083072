import React from 'react';
import { Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

interface IProfileMenuEntryWrapperComponentProps{
    onBack: (...args:any) => void
}

const ProfileMenuEntryWrapperComponent : React.FC<IProfileMenuEntryWrapperComponentProps> = props => {


    return (

        <Card style={{width:'100%'}} >

            <p onClick={props.onBack}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Back</p>

            {props.children}

        </Card>

    )

}

export default ProfileMenuEntryWrapperComponent;