import React from 'react';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip } from 'recharts';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { IDashboardDailyProps } from './DashboardDailySells';
import { DashboardDailyData } from './DashboardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import ChartTotalsComponent, { ChartTotal } from './chartTotals.component';
import DashboardChartWrapper from './dashboardChartWrapper.component';
import { TemporalSelectorModes } from '../../layouts/TemporalSelector/TemporalSelector';
import DashboarcComponentTitle from './DashboardComponentTitle';

export interface IDashboardDailyCoversProps{
    data : DashboardDailyData,
    reportCaption : string
}

const DashboardDailyCovers : React.FC<IDashboardDailyCoversProps & IDashboardDailyProps> = props => {

    const { t } = useTranslation();

    const covers_num_translated = t("zreports.zreport.totalCoversNumber");

    const totals : ChartTotal[] = [
        {
            label: t('dashboard.current'),
            value: props.data.gran_totals.cover_gran_num,
            color: '#8884d8',
            fontSize: '3.5vw'
        },
        {
            label: t('dashboard.previous'),
            value: props.data.gran_totals_compare.cover_gran_num_compare,
            color: '#888412'
        }
    ];

    const title = props.mode === TemporalSelectorModes.week ? t('dashboard.titles.weekly.total_covers_number') : t('dashboard.titles.monthly.total_covers_number');

    return(

        <DashboardChartWrapper>

                <DashboarcComponentTitle title={title} />

                <ChartTotalsComponent
                    totals={totals}
                    mainTotal={0}
                    compareTotal={1}
                    deltaSuffix={<FontAwesomeIcon icon={faUtensils} /> } />

                <ResponsiveContainer 
                    height={160} 
                    className={props.responsiveContainerClassName}>

                    <LineChart 
                        data={props.data.aggregates}
                        margin={{left:-20, top:10}}
                    >

                        <CartesianGrid />

                        <XAxis dataKey="dateDay" />
                        <Tooltip labelFormatter={() => covers_num_translated} />
                        <YAxis tick={{fontSize: 18}}/>
                        <Line name={t('shared.period').toString()} type="monotone" dataKey="cover_num" strokeWidth={2} stroke="#8884d8" activeDot={{ r: 8 }} />
                        <Line name={t(props.compareLegendLabel).toString()} type="monotone" dataKey="cover_num_compare" strokeWidth={1} stroke="#888412" />

                    </LineChart>

                </ResponsiveContainer>

                <div style={{textAlign:'center', width:'100%'}}>{props.reportCaption}</div>

            </DashboardChartWrapper>

    )

}

export default DashboardDailyCovers;