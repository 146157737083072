/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, IUserState } from '../shared/slices/userSlices';
import { RootState } from '../core/store/rootReducer';
import { Redirect } from 'react-router-dom';
import LoginComponent from '../components/login/login.component';
import WarningComponent from '../components/shared/warning.component';
import { useTranslation } from 'react-i18next';
import { ILanguageState } from '../shared/slices/languageSlice';
import DefaultLayout from '../layouts/PageLayout/DefaultLayout';

const LoginContainer : React.FC<{}> = props => {

    const dispatch = useDispatch();

    const user : IUserState = useSelector(
        ( state : RootState) => state.UserReducers
    ) 

    const lang : ILanguageState = useSelector(
        (state : RootState) => state.LanguageReducers
    )

    const handleClick = ( username: string, password: string) => {

        dispatch(login(username,password));

    }

    const { t } = useTranslation();

    return (
        <DefaultLayout>

            { user.user ? <Redirect to='/sites' /> : null  }

            {user.error ?<WarningComponent title={t('shared.warning')} message={t('login.badCredentials')} /> : null }

            <LoginComponent isLoading={user.loading} onLogin={handleClick} />
            
        </DefaultLayout>
    )

}

export default LoginContainer;