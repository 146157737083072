import React from 'react';
import { Badge } from 'antd';

export enum BadgeColors {
     "lightGreen"   = "#52c41a",
     "green"        = "#52c41a",
     "red"          = "#ff4d4f",
     "white"        = "#fff",
     "darkGrey"     = "#777",
     "yellow"         = "#f3c623"
}

interface ICustomBadge {
     text ?: string,
     count?: number,
     color?: keyof typeof BadgeColors,
     isToShowZero? : boolean
}

const CustomBadge : React.FC<ICustomBadge> = props => 
{
     const color = props.color || "red";
     
     return (
          <>
               <Badge style={{ backgroundColor: BadgeColors[color] }} count={props.count || 0} showZero={props.isToShowZero || false} />
               {' '}<span>{props.text}</span>
          </>
     )
}

export default CustomBadge;