import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAppStatusState {
	online: boolean;
	lastFetchDateTime: string | null;
}

const initialState: IAppStatusState = {
	online: navigator.onLine,
	lastFetchDateTime: localStorage.getItem("lastFetchDateTime") || null
};

const appStatusSlice = createSlice({
	name: "appStatus",
	initialState,
	reducers: {
		setOnline: (state: IAppStatusState, action: PayloadAction<boolean>) => {
			state.online = action.payload;
		},

		setLastFetchDateTime: (state: IAppStatusState, action: PayloadAction<string>) => {
			const dateString = action.payload;

			state.lastFetchDateTime = dateString;
			localStorage.setItem("lastFetchDateTime", dateString);
		}
	}
});

export const { setOnline, setLastFetchDateTime } = appStatusSlice.actions;

export default appStatusSlice.reducer;
