import React from 'react';
import ProfileComponent from '../components/profile/profile.component';
import { IUserState, userLogout } from '../shared/slices/userSlices';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../core/store/rootReducer';
import { useHistory, useParams } from 'react-router-dom';
import { unselectSite } from '../shared/slices/siteSlice';
import { ILanguageState } from '../shared/slices/languageSlice';
import DefaultLayout from '../layouts/PageLayout/DefaultLayout';
import { sitesFetchedSuccessfully } from '../shared/slices/sitesSlices';

const ProfileContainer: React.FC<{}> = props => {

	const history = useHistory();

	const dispatch = useDispatch();

	const user: IUserState = useSelector(
		(state: RootState) => state.UserReducers
	)

	const language: ILanguageState = useSelector(
		(state: RootState) => state.LanguageReducers
	)

	const handleLogout = () => {

        const notCleanKey1 = 'deviceVersion';
        const notCleanKey2 = 'notificationsEnabled';
        const notCleanKey3 = 'deviceOS';
        const notCleanKey4 = 'appID';
        const list = Object.keys(localStorage);
        list.forEach((key) =>
          !notCleanKey1.includes(key) && !notCleanKey2.includes(key) && !notCleanKey3.includes(key) && !notCleanKey4.includes(key) ? localStorage.removeItem(key) : key
        );
		dispatch(userLogout(null));
		dispatch(unselectSite(null));
		dispatch(sitesFetchedSuccessfully([]));

		history.push('/');

	}

	const { id } = useParams<{ id: string }>()


	return (

		<DefaultLayout>

			{user.user && <ProfileComponent
				id={+id}
				language={language.value}
				onLogout={handleLogout}
				user={user.user} />}

		</DefaultLayout>

	)

}

export default ProfileContainer;