/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import WeekTemporalSelector from './WeekTemporalSelector';
import MonthTemporalSelector from './MonthTemporalSelector';
import { Row, Col, Card } from 'antd';
import TemporalModeLabel from './TemporalModeLabel';
import { useTranslation } from 'react-i18next';

export enum TemporalSelectorModes {
    "week"  = "week",
    "month" = "month"
}

export interface ITemporalSelectorProps {
    onModeChange ?: (mode : TemporalSelectorModes) => void;
    onDateChange : (startDate : Date, endDate : Date) => void;
    currentDate? : Date;
    onDisplayChange? : ( display : string ) => void
}

const TemporalSelector : React.FC<ITemporalSelectorProps> = props => {

    const [ selectedMode, setSelectedMode ] = useState<TemporalSelectorModes>(TemporalSelectorModes.week);

    const getSelectorFromMode = () => {

        switch(selectedMode) {

            case(TemporalSelectorModes.week):

                return <WeekTemporalSelector 
                        onDateChange={props.onDateChange} 
                        currentDate={props.currentDate} 
                        onDisplayChange={props.onDisplayChange}
                    />;

            case( TemporalSelectorModes.month):

                return <MonthTemporalSelector 
                        onDateChange={props.onDateChange} 
                        currentDate={props.currentDate} 
                        onDisplayChange={props.onDisplayChange}
                    />

        }

    }

    const changeMode = ( mode : TemporalSelectorModes ) => {

        setSelectedMode( mode );

    }

    useEffect(() => {

        props.onModeChange && props.onModeChange(selectedMode);

    }, [selectedMode])

    const { t } = useTranslation();

    return(

        <React.Fragment>

            <Row style={{marginLeft: '10px', marginTop: '10px', marginRight: '10px', position:'sticky'}} gutter={0}>

                <Card style={{width:'100%'}} >

                    <Row gutter={0}>

                        <Col 
                            onClick={() => changeMode(TemporalSelectorModes.week)} 
                            xs={12}
                        >

                            <TemporalModeLabel 
                                label={t('layouts.temporalSelector.modes.week')} 
                                isSelected={selectedMode === TemporalSelectorModes.week} 
                            />

                        </Col>
                        <Col 
                            onClick={() => changeMode(TemporalSelectorModes.month)} 
                            xs={12}
                        >

                            <TemporalModeLabel 
                                label={t('layouts.temporalSelector.modes.month')} 
                                isSelected={selectedMode === TemporalSelectorModes.month} 
                            />

                        </Col>
                    </Row>

                    {getSelectorFromMode()}

                </Card>

            </Row>

        </React.Fragment>

    )


}

export default TemporalSelector;