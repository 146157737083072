import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IFeedback } from '../../../features/Feedbacks/models/feedback';
import { IFeedbackState } from '../../../features/Feedbacks/slices/feedbackSlices';
import style from './feedback.module.css';
import FeedbackFormComponent from './feedbackForm.component';

interface IFeedbackProps {
    feedbackState: IFeedbackState;
    onSubmit: ( feedback : IFeedback) => void;
}

const FeedbackComponent : React.FC<IFeedbackProps> = props => {

    const { t } = useTranslation();

    const title = () => <> <FontAwesomeIcon icon={faEnvelopeOpenText} /> {t('support.feedback.title')} </>

    return (

        <Card 
            className={style.feedbackCard}
            title={title()}>

            <FeedbackFormComponent 
                onSubmit={props.onSubmit}
                {...props.feedbackState.request}
                />

        </Card>

    )

}

export default FeedbackComponent;