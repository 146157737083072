import React, { ReactElement } from 'react';
import { Row, Col } from 'antd';

interface IHomeRowProps {
     firstComponent: ReactElement,
     secondComponent: ReactElement
}
const HomeRow : React.FC<IHomeRowProps> = props => 
{
     return (
          <Row style={{marginLeft: '5px', marginRight: '5px', marginTop: '10px', marginBottom:'10px'}} gutter={0}>

               <Col span={12} style={{display: 'flex'}}>{props.firstComponent}</Col>
               
               <Col span={12} style={{display: 'flex'}}>{props.secondComponent}</Col>

          </Row>
     );
}

export default HomeRow;