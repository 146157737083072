/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { IElectronicInvoiceStatistics } from '../../features/ElectronicInvoice/models/IElectronicInvoiceStatistics';
import SectionButton from '../../layouts/SectionButton/SectionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt as farFileAlt } from '@fortawesome/free-regular-svg-icons';
import { Badge } from 'antd';

interface IElectronicInvoiceButtonComponent {
     title ?: string,
     isLoading : boolean,
     isError : boolean,
     statistics : IElectronicInvoiceStatistics | null,
     handleClick: () => void
}

const ElectronicInvoiceButtonComponent : React.FC<IElectronicInvoiceButtonComponent> = props => {

     const yearlyRefusedActiveInvoicesCount = props.statistics?.year_total_refused_active_invoices || 0;
     const yearlyRejectedActiveInvoicesCount = props.statistics?.year_total_rejected_active_invoices || 0;

     const handleClick = () => {
          if(!props.isError && !props.isLoading) props.handleClick();
     }
     
     return (
          <SectionButton title={props.title} onClick={handleClick}>
               {  props.isLoading ? 
                    <FontAwesomeIcon icon={faSpinner} size="3x" spin /> : 
                    <Badge showZero={false} count={yearlyRefusedActiveInvoicesCount + yearlyRejectedActiveInvoicesCount}>
                         <FontAwesomeIcon icon={farFileAlt} size="3x" color='black'/>
                    </Badge>
               }
          </SectionButton>
     );
}

export default ElectronicInvoiceButtonComponent;