import React from 'react';

import style from './bottomToolbar.module.css';
import { useTranslation } from 'react-i18next';

interface IOfflineBottomToolbarProps {
    lastFetchData : string
}

const OfflineBottomToolbar : React.FC<IOfflineBottomToolbarProps> = props => {

    const { t } = useTranslation();

    const lastDataUpdateString = ' - ' + t('layouts.bottomToolbar.lastUpdate') + `: ${props.lastFetchData}`;

    return (

        <div className={style.offline}>
                <span>Offline { props.lastFetchData && lastDataUpdateString}</span>
        </div>

    )

}

export default OfflineBottomToolbar;