import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IZReportList } from "../models/IZReportList";
import { AppThunk } from "../../../core/store/store";
import { zReportService } from "../services/zreport.service";
import { IZReportHeader } from "../models/IZReportHeader";
import { IZReportAggregate } from "../models/IZReportAggregate";
import { DateUtils } from "../../../shared/utils/dateUtils";

import { zreportStoreLocal, zreportGetStore, zreportCheckStore } from "../../../shared/storage.js";

export interface IZReportListState {
	zReportList: IZReportList | null;
	lastZReport: IZReportHeader | null;
	isLoading: boolean;
	isLazyLoading: boolean;
	isError: boolean;
	isLazyError: boolean;
	isLastRecordLoaded: boolean;
	pageNumber: number;
	pageSize: number;
	zReportAggregates: IZReportAggregate[];
	zReportCompare: IZReportList | null;
	zReportCompareAggregates: IZReportAggregate[];
}

const initialState: IZReportListState = {
	zReportList: null,
	lastZReport: null,
	isLoading: false,
	isLazyLoading: false,
	isError: false,
	isLazyError: false,
	isLastRecordLoaded: false,
	pageNumber: 1,
	pageSize: 10,
	zReportAggregates: [],
	zReportCompare: null,
	zReportCompareAggregates: []
};

const zReportListSlice = createSlice({
	name: "zReportList",
	initialState,
	reducers: {
		zReportListFetching: (state: IZReportListState, action: PayloadAction<boolean>) => {
			state.zReportList = null;
			state.isError = false;
			state.isLoading = action.payload;
		},

		zReportListLazyFetching: (state: IZReportListState, action: PayloadAction<boolean>) => {
			state.isLazyLoading = action.payload;
			state.isError = false;
		},

		zReportListSuccessfulFetch: (state: IZReportListState, action: PayloadAction<IZReportList>) => {
			if (state.zReportList === null) {
				state.zReportList = action.payload;
			} else {
				state.zReportList?.records.push(...action.payload.records);
			}

			state.lastZReport = state.zReportList.records[state.zReportList.records.length - 1];
			state.isLastRecordLoaded = state.zReportList?.records.length === action.payload.total_record_count;
			state.pageNumber++;

			state.isLoading = false;
			state.isLazyLoading = false;
			state.isError = false;
		},

		zReportListErrorFetch: (state: IZReportListState, action: PayloadAction<null>) => {
			state.isLoading = false;
			state.isLazyLoading = false;

			state.isError = true;
			state.lastZReport = null;
			state.zReportList = action.payload;
		},

		setZReportPagination: (state: IZReportListState, action: PayloadAction<number>) => {
			state.pageNumber = action.payload;
		},

		aggregateByDay: (
			state: IZReportListState,
			action: PayloadAction<{ src: "zReportList" | "zReportCompare"; dst: "zReportAggregates" | "zReportCompareAggregates"; dates: string[] }>
		) => {
			let zReportAggregates: IZReportAggregate[] = [];
			let zReports: IZReportHeader[] = [];

			if (state.zReportList) zReports = state[action.payload.src]!!.records.map((zreport) => Object.assign({}, zreport));
			else return;

			action.payload.dates.forEach((date) => {
				const formattedDate: string = DateUtils.formatDateToServerFormat(new Date(date));
				let document_total: number = 0;
				let cover_total_amount: number = 0;
				let document_num: number = 0;
				let cover_num: number = 0;

				const zReportWithSameDate = zReports?.filter((zrep) => zrep.start_date === formattedDate) ?? [];
				if (zReportWithSameDate.length > 0) {
					zReportWithSameDate.forEach((zReport) => {
						document_total += zReport.document_total || 0;
						document_num += zReport.document_num || 0;
						cover_num += zReport.cover_num || 0;
						cover_total_amount += zReport.cover_total_amount || 0;
					});
				}

				zReportAggregates.push({
					start_date: formattedDate,
					document_total,
					document_num,
					cover_total_amount,
					cover_num
				});
			});

			state[action.payload.dst] = zReportAggregates;
		},
		zReportListCompareSuccessfullFetch: (state: IZReportListState, action: PayloadAction<IZReportList>) => {
			state.zReportCompare = action.payload;
		},

		zReportListClearList: (state: IZReportListState, action: PayloadAction<null>) => {
			state.zReportList = action.payload;
			/*state.zReportAggregates = [];
               state.zReportCompare = action.payload;
               state.zReportCompareAggregates =  [];*/
		}
	}
});

export const {
	zReportListFetching,
	zReportListSuccessfulFetch,
	zReportListErrorFetch,
	zReportListLazyFetching,
	setZReportPagination,
	aggregateByDay,
	zReportListCompareSuccessfullFetch,
	zReportListClearList
} = zReportListSlice.actions;

export default zReportListSlice.reducer;

export const fetchZReportListByDateInterval =
	(siteId: number, startDate: string, stopDate: string, aggregateMode: string, compareStartDate?: string, compareStopDate?: string): AppThunk =>
	async (dispatch) => {
		try {
			//CustomFunction();

			dispatch(setZReportPagination(1));

			dispatch(zReportListFetching(true));

			dispatch(zReportListClearList(null));

			const checkStore = zreportCheckStore("zreport_", siteId);
			var obj;

			const dateHasBeenSet = !!startDate || !!stopDate;

			if (checkStore && !dateHasBeenSet) {
				var temp = zreportGetStore("zreport_", siteId);
				console.log("Uso il local sotrage");
				obj = temp.data;
			} else {
				obj = await zReportService.getByDateInterval(siteId, startDate, stopDate, dispatch<any>);
				//Salvo Z report nel local storage
				zreportStoreLocal("zreport_", obj, siteId);
			}

			const zReportList = obj;

			dispatch(zReportListSuccessfulFetch(zReportList));

			let datesRange;

			if (dateHasBeenSet) {
				datesRange = DateUtils.getDatesInRage(new Date(startDate), new Date(stopDate)).map((date) => date.toString());

				aggregateMode && aggregateMode === "day" && dispatch(aggregateByDay({ src: "zReportList", dst: "zReportAggregates", dates: datesRange }));
			}

			if (compareStartDate && compareStopDate) {
				const zReportListCompare = await zReportService.getByDateInterval(siteId, compareStartDate, compareStopDate, dispatch<any>);

				dispatch(zReportListCompareSuccessfullFetch(zReportListCompare));

				datesRange = DateUtils.getDatesInRage(new Date(compareStartDate), new Date(compareStopDate)).map((date) => date.toString());

				aggregateMode &&
					aggregateMode === "day" &&
					dispatch(aggregateByDay({ src: "zReportCompare", dst: "zReportCompareAggregates", dates: datesRange }));
			}
		} catch (e) {
			dispatch(zReportListErrorFetch(null));
		}
	};

export const fetchZReportList =
	(siteId: number): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(setZReportPagination(1));

			dispatch(zReportListFetching(true));

			dispatch(zReportListClearList(null));

			const zReportList = await zReportService.getAll(siteId, dispatch<any>);

			dispatch(zReportListSuccessfulFetch(zReportList));
		} catch (e) {
			dispatch(zReportListErrorFetch(null));
		}
	};

export const lazyFetchZReportList =
	(siteId: number, pageSize: number, pageNumber: number): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(zReportListLazyFetching(true));

			const zReportList = await zReportService.getAll(siteId, dispatch<any>, pageSize, pageNumber);

			dispatch(zReportListSuccessfulFetch(zReportList));
		} catch (e) {
			dispatch(zReportListErrorFetch(null));
		}
	};
