import React from 'react';

import style from './response.module.css';

interface IBaseResponseProps {
    backgroundColor : string;
}

const BaseResponse : React.FC<IBaseResponseProps> = props => {

    return (

        <div className={style.baseResponse} style={{backgroundColor:props.backgroundColor}}>

            {props.children}

        </div>

    )

}

export default BaseResponse;