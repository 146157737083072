/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Card, Col, Row } from "antd";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

import style from '../support/support.module.css';

const ProfileContactsComponent : React.FC<{}> = props => {

    const { t } = useTranslation();

    const onFacebookClick = () => {

        window.location.href = 'fb://profile/LabwareSpA';

    }

    const onYoutubeClick = () => {

        window.location.href = 'https://youtube.com/channel/UCjL7uq3H3jfpdgBQ-lNq-Ow';

    }

    const onLinkedinClick = () => {

        window.location.href = 'https://linkedin.com/company/labware-spa';

    }

    return (

        <Card
            style={{ width:'100%', marginTop:'10px' }}>

            <div>
                <p  className={style.businessName} >Labware S.p.A.</p>
                <p  className={style.other} >Via Enzo Ferrari, 3</p>
                <p  className={style.other} >Zona Ind. "A"</p>
                <p  className={style.other} >62012, Civitanova Marche (MC), Italy</p>
            </div>

            <div>

                <Row>
                    <Col style={{display:'flex', flexDirection:'row'}}>

                        <div className={style.brandIcon} >

                            <FontAwesomeIcon
                                id="fb-icon" 
                                icon={faFacebook} 
                                onClick={onFacebookClick}
                                color="#38529a" 
                                size="2x" />

                        </div>

                        <div className={style.brandIcon}>

                            <FontAwesomeIcon 
                                id="yt-icon" 
                                onClick={onYoutubeClick}
                                icon={faYoutube}  
                                color="red" 
                                size="2x" />

                        </div>

                        <div className={style.brandIcon} >

                            <FontAwesomeIcon
                                id="in-icon"  
                                onClick={onLinkedinClick}
                                icon={faLinkedin} 
                                color="#0073b1" 
                                size="2x" />

                        </div>

                    </Col>

                </Row>
            </div>

        </Card>

    )

}

export default ProfileContactsComponent;