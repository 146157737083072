import React from 'react';
import { Card } from 'antd';

interface ISectionButtonProps {
     title ?: string,
     onClick ?: () => void,
}

const SectionButton : React.FC<ISectionButtonProps> = props => {
     return (
          <Card 
               bordered={false} 
               onClick={props.onClick} 
               style={{textAlign:'center', flex: 1, marginLeft: '5px', marginRight: '5px', backgroundImage: 'linear-gradient(#cccccc, #ffffff)'}} 
               bodyStyle={{height:'100%', width:'100%'}} >
               <div style={{height:'65%',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <>{ props.children }</>
               </div>
               <hr/>
               <p>{ props.title }</p>
          </Card>    
     )
}

export default SectionButton;