export interface IMaterial {
    id : number;
    description : string;
    maxQuantity : number;
    minQuantity : number;
    price?: number
}

export const materials : IMaterial[] = [
        {
            id: 1,
            description   : "Carta termica per stampante fiscale",
            maxQuantity   : 9999,
            minQuantity   : 1
        },
        {
            id: 2,
            description   : "Carta termica per terminale pagamento",
            maxQuantity   : 9999,
            minQuantity   : 1
        },
        {
            id: 3,
            description   : "Carta termica per bilancia.",
            maxQuantity   : 9999,
            minQuantity   : 1
        },
        {
            id: 4,
            description   : "Rotoli elimina code",
            maxQuantity   : 9999,
            minQuantity   : 1
        },
        {
            id: 5,
            description   : "Toner",
            maxQuantity   : 9999,
            minQuantity   : 1
        },
        {
            id: 6,
            description   : "Rotoli pezzatrici",
            maxQuantity   : 9999,
            minQuantity   : 1
        },
        {
            id: 7,
            description   : "Tamponi prezzatrici",
            maxQuantity   : 9999,
            minQuantity   : 1
        },
        {
            id: 8,
            description   : "DGFE Stampante fiscale",
            maxQuantity   : 9999,
            minQuantity   : 1
        },
        {
            id: 9,
            description   : "Altro",
            maxQuantity   : 9999,
            minQuantity   : 1
        }
    ];