import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../core/store/store";
import { getStatistics } from "../services/electronicInvoiceStatistics.service";
import { IElectronicInvoiceStatistics } from "../models/IElectronicInvoiceStatistics";

export interface IElectronicInvoiceStatisticsState {
	isLoading: boolean;
	isError: boolean;
	statistics: IElectronicInvoiceStatistics | null;
}

const initialState: IElectronicInvoiceStatisticsState = {
	isLoading: false,
	isError: false,
	statistics: null
};

const electronicInvoiceStatisticsSlice = createSlice({
	name: "electronicInvoiceStatistics",
	initialState,
	reducers: {
		eiStatisticsFetching(state: IElectronicInvoiceStatisticsState, action: PayloadAction<boolean>) {
			state.isError = false;
			state.statistics = null;
			state.isLoading = action.payload;
		},

		eiStatisticsSuccessfulFetch(state: IElectronicInvoiceStatisticsState, action: PayloadAction<IElectronicInvoiceStatistics>) {
			state.isError = false;
			state.isLoading = false;
			state.statistics = action.payload;
		},

		eiStatisticsErrorFetch(state: IElectronicInvoiceStatisticsState, action: PayloadAction<null>) {
			state.isError = true;
			state.isLoading = false;
			state.statistics = null;
		}
	}
});

export const { eiStatisticsFetching, eiStatisticsSuccessfulFetch, eiStatisticsErrorFetch } = electronicInvoiceStatisticsSlice.actions;

export default electronicInvoiceStatisticsSlice.reducer;

export const fetchEiStatistics =
	(siteId: number): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(eiStatisticsFetching(true));

			const statistics = await getStatistics(siteId, dispatch<any>);

			dispatch(eiStatisticsSuccessfulFetch(statistics));
		} catch (e) {
			dispatch(eiStatisticsErrorFetch(null));
		}
	};
