/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'antd';
import useDateUtilities from '../../hooks/useDateUtilities';
import { ITemporalSelectorProps } from './TemporalSelector';
import { useTranslation } from 'react-i18next';
import TemporalSlidingButton from './TemporalSlidingButton';
import TemporalDateRangeLabel from './TemporalDateRangeLabel';

const MonthTemporalSelector : React.FC<ITemporalSelectorProps> = props => {

    const { getDateRangeFromMonth } = useDateUtilities();

    const currentDate = () : Date => { if( props.currentDate && !isNaN(props.currentDate?.getTime() || NaN )) return props.currentDate; else return new Date(); }

    const [ selectedMonth, setSelectedMonth ] = useState<number>(currentDate().getMonth());

    const [ selectedYear, setSelectedYear ] = useState<number>(currentDate().getFullYear());

    const [ startDate, setStartDate ] = useState<Date>();

    const [ stopDate, setStopDate] = useState<Date>();

    const [ display, setDisplay ] = useState<string>('');
    
    const changeMonth = ( shift : number ) => {

        setSelectedMonth(selectedMonth + shift);

    }

    useEffect(() => {

        const { firstDay, lastDay } = getDateRangeFromMonth(selectedMonth, selectedYear);

        setStartDate(firstDay);
        setStopDate(lastDay);

    }, [selectedMonth])

    useEffect(() => {

        startDate && stopDate && props.onDateChange( startDate, stopDate);

        const monthTranslationString = 'shared.calendar.months.' + startDate?.toLocaleDateString('en-En',{month:'long'});
        const monthTranslation = t(monthTranslationString);

        startDate && setDisplay(monthTranslation + " " + startDate?.getFullYear());

    },[ startDate, stopDate])

    useEffect(() => {

        props.onDisplayChange && props.onDisplayChange(display);

    }, [display])

    const { t } = useTranslation(); 

    return (

         <React.Fragment>

            <Row gutter={0}>

                <Col xs={6}>

                    <TemporalSlidingButton direction='previous' onClick={() => changeMonth(-1)} />

                </Col>

                <Col xs={12}>

                    <TemporalDateRangeLabel display={display} />

                </Col>

                <Col xs={3}></Col>

                <Col xs={3}>

                    <TemporalSlidingButton direction='next' onClick={() => changeMonth(+1)} />
                
                </Col>

            </Row>

            

        </React.Fragment>

    )

}

export default MonthTemporalSelector;