import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';
import DeltaComponent from './delta.component';

export interface ChartTotal {
    label : string;
    value : number;
    color : string;
    suffix?: string;
    fontSize?: string;
}

interface IChartTotalsComponentProps {
    totals : ChartTotal[];
    mainTotal? : number;
    compareTotal? : number;
    deltaSuffix: string | ReactNode
}

const ChartTotalsComponent : React.FC<IChartTotalsComponentProps> = props => {

    const fontSize = '3vw';

    const getValueColumnWidth = () => {

        const largeTotal = props.totals.find( total => total.value.toString().length > 6);

        return largeTotal ? 10 : 6;

    }

    const valueColumnWidth = getValueColumnWidth();

    const renderChartTotal = (chartTotal : ChartTotal, index: number) => {

        return <div key={index} style={{color:chartTotal.color, fontSize: chartTotal.fontSize || fontSize }}>
            <Row>
                <Col offset={3} xs={10} style={{textAlign:'left'}}>
                    {chartTotal.label}:
                </Col>
                <Col xs={valueColumnWidth} style={{textAlign:'right'}}>
                    {chartTotal.value } { chartTotal.suffix && chartTotal.suffix }        
                </Col>
            </Row>
            
        </div> 
    }

    return(

        <React.Fragment>

            <Row gutter={0}>

                <Col xs={16}>

                    {props.totals.map(renderChartTotal)}

                </Col>
                
                <Col xs={8} style={{textAlign: 'center'}}>

                    {typeof props.mainTotal !== 'undefined' && typeof props.compareTotal !== 'undefined' && 
                        <DeltaComponent 
                            deltaSuffix={props.deltaSuffix}
                            value={props.totals[props.mainTotal].value}
                            compareValue={props.totals[props.compareTotal].value} /> 
                    }

                </Col>

            </Row>

        </React.Fragment>

    )

}

export default ChartTotalsComponent;