import request from "../../../core/request/request";
import { AppDispatch } from "../../../core/store/store";
import { IReseller } from "../models/IReseller";

const host = process.env.REACT_APP_4CO_HOST || "";

const getReseller = (siteId: number, dispatch: AppDispatch): Promise<IReseller> => {
	const url = `/v2/_services/sites/${siteId}/reseller`;

	return new Promise(async (resolve, reject) => {
		try {
			const response = await request<IReseller>(
				host,
				{
					url: url,
					method: "GET"
				},
				dispatch
			);

			resolve(response.data);
		} catch (e: any) {
			reject(e.response);
		}
	});
};

export const resellerService = { getReseller };
