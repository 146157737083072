/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SyntheticEvent, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../core/store/rootReducer';
import { IZReportListState, fetchZReportList, lazyFetchZReportList, setZReportPagination } from '../features/ZReports/slices/zReportListSlices';
import ZReportsComponent from '../components/zreports/zreports.component';
import { ISiteState } from '../shared/slices/siteSlice';
import { IZReportHeader } from '../features/ZReports/models/IZReportHeader';
import { fetchZReport,  } from '../features/ZReports/slices/zReportSlice';
import { useHistory } from 'react-router-dom';
import Loading from '../layouts/Loading/Loading';
import DefaultLayout from '../layouts/PageLayout/DefaultLayout';
import usePagePosition from '../hooks/usePagePosition';

const ZReportContainer : React.FC<{}> = props => {

     const site : ISiteState = useSelector(
          ( state : RootState ) => state.SiteReducers
     );
     
     const zReportList : IZReportListState = useSelector(
          ( state : RootState) => state.ZReportListReducers
     );

     const siteId = site.site?.id || 0;

     const dispatch = useDispatch();
     const {onPositionChange} = usePagePosition();

     const fetchZReports = () => dispatch(fetchZReportList(siteId));

     useEffect(() => {

          fetchZReports();

     }, [])
     
     
     const history = useHistory();

     const handleSelectZReport = (zReport : IZReportHeader) => {

          dispatch(fetchZReport(siteId, zReport.z_id));

          history.push(`/zreports/${zReport.z_id}`);

     }     


     const onScroll = (event : SyntheticEvent) => {
          const pagePosition = onPositionChange(event);

          if(pagePosition.isBottom && !zReportList.isLazyLoading && !zReportList.isLastRecordLoaded){
               dispatch(lazyFetchZReportList(siteId, zReportList.pageSize, zReportList.pageNumber));
          }
     }
     
     return (
          <DefaultLayout onScroll={onScroll} onRefresh={fetchZReports}>
               { zReportList.isLoading ? 
                    <Loading /> :
                    ( typeof zReportList != "undefined" ? 
                              <ZReportsComponent 
                                   zreportList={zReportList.zReportList} 
                                   isLastRecordLoaded={zReportList.isLastRecordLoaded}
                                   isLazyLoading={zReportList.isLazyLoading}
                                   selectZReport={handleSelectZReport} /> 
                         : null
                    )
               }
          </DefaultLayout>
     )
}

export default ZReportContainer;
