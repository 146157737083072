/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Row, Card } from 'antd';

interface IDashboardChartWrapperProps {
    title?: string
}

const DashboardChartWrapper : React.FC<IDashboardChartWrapperProps> = props => {

    const [title, setTitle] = useState(props.title);
    const [children, setChildren] = useState(props.children); 

    return(

        <Row style={{marginLeft: '10px', marginTop: '10px', marginRight: '10px'}} gutter={0}>

            <Card title={title} style={{width:'100%'}}>

                {children}

            </Card>

        </Row>

    )

}

export default DashboardChartWrapper;