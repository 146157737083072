/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import TemporalSelector, { ITemporalSelectorProps, TemporalSelectorModes } from '../../layouts/TemporalSelector/TemporalSelector';
import { IZReportAggregate } from '../../features/ZReports/models/IZReportAggregate';
import useDateUtilities from '../../hooks/useDateUtilities';
import DashboardDailySells, { DailyAggregateData } from './DashboardDailySells';
import DashboardDailyDocuments from './DashboardDailyDocuments';
import DashboardDailyCovers from './DashboardDailyCovers';
import { useTranslation } from 'react-i18next';
import Loading from '../../layouts/Loading/Loading';

import style from './dashboard.module.css';
import WarningModal from '../../layouts/Modal/WarningModal';

interface IDashboardComponentProps {
    zReportAggregates: IZReportAggregate[];
    zReportAggregatesCompare?: IZReportAggregate[];
    dates: Date[];
    lastZReportDate: Date;
    isError: boolean;
    onRefresh: (...args: any) => void;
}

export interface DashboardDailyData {
    aggregates : DailyAggregateData[],
    gran_totals : {
        document_gran_total : number;
        document_gran_num : number;
        cover_gran_num : number;
        cover_gran_total : number;
    }
    gran_totals_compare : {
        document_gran_total_compare : number;
        document_gran_num_compare : number;
        cover_gran_num_compare : number;
    }
}

const DashboardComponent : React.FC<ITemporalSelectorProps & IDashboardComponentProps> = props => {

    const dataInitialState : DashboardDailyData = {
        aggregates: [],
        gran_totals: {
            document_gran_total : 0,
            document_gran_num : 0,
            cover_gran_num : 0,
            cover_gran_total : 0
        },
        gran_totals_compare: {
            document_gran_total_compare: 0,
            document_gran_num_compare: 0,
            cover_gran_num_compare: 0
        }
        
    }

    const { t } = useTranslation();

    const { formatDateToServerFormat, formatDateToLocale, shiftWeekFromDate } = useDateUtilities();

    const [ display, setDisplay ] = useState<string>('');

    const [ mode, setMode ] = useState<TemporalSelectorModes>(TemporalSelectorModes.week);

    const formatData = () : DashboardDailyData => {

        let data : DailyAggregateData[] = [];

        let document_gran_total = 0,
            document_gran_num = 0,
            cover_gran_num = 0,
            cover_gran_total = 0,
            document_gran_total_compare = 0,
            document_gran_num_compare = 0,
            cover_gran_num_compare = 0;
        
        props.dates.forEach( date => {

            let document_total = 0, 
                document_total_compare = 0,
                document_num = 0,
                document_num_compare = 0,
                cover_num = 0,
                cover_num_compare = 0,
                cover_total_amount = 0,
                cover_avg = 0,
                bar_avg = 0
            ;

            const formattedDate = formatDateToServerFormat(date);

            const zReportAggregatesFound =  props.zReportAggregates?.filter(zRepAggr => zRepAggr.start_date === formattedDate) ?? [];
            if(zReportAggregatesFound.length > 0){
                zReportAggregatesFound.forEach(zReport => {
                    document_total += zReport.document_total || 0;
                    document_num += zReport.document_num || 0;
                    cover_num += zReport.cover_num || 0;
                    cover_total_amount += zReport.cover_total_amount || 0;
                    cover_avg += zReport.cover_avg || 0;
                    bar_avg += zReport.bar_avg || 0;
                })

            }

            const weeksToShift = - Math.round( props.dates.length / 7 );

            const zReportAggregatesCompareFound = props.zReportAggregatesCompare?.filter(zRepAggrComp => zRepAggrComp.start_date === formatDateToServerFormat(shiftWeekFromDate(date, weeksToShift))) ?? [];

            if(zReportAggregatesCompareFound.length > 0){
                zReportAggregatesCompareFound.forEach(zReport => {
                    document_total_compare += zReport.document_total || 0;
                    document_num_compare += zReport.document_num || 0;
                    cover_num_compare += zReport.cover_num || 0;
                })
            }
            

            const document_avg = document_total && document_num ? document_total / document_num : 0;
            const document_avg_compare = document_total_compare && document_num_compare ? document_total_compare / document_num_compare : 0;

            const translatedWeekDay = t('shared.calendar.days.' + date.toLocaleDateString('en-En',{weekday:'long'}) + '.letter');

            document_gran_total += document_total;
            document_gran_num += document_num;
            cover_gran_num += cover_num;
            cover_gran_total += cover_gran_total;

            document_gran_total_compare += document_total_compare;
            document_gran_num_compare += document_num_compare;
            cover_gran_num_compare += cover_num_compare; 

            data.push({
                date : formatDateToLocale(date) ,
                dateDay: translatedWeekDay,
                document_total,
                document_total_compare,
                document_num,
                document_num_compare,
                cover_num,
                cover_num_compare,
                cover_total_amount,
                cover_avg,
                bar_avg,
                document_avg : Math.round(document_avg * 100) / 100,
                document_avg_compare: Math.round(document_avg_compare * 100) / 100
            });

        })

        console.log('document_gran_total', document_gran_total);

        return {
            aggregates : data,
            gran_totals : {
                document_gran_total: (Math.ceil(document_gran_total * 100)) / 100,
                document_gran_num,
                cover_gran_num,
                cover_gran_total: (Math.ceil(cover_gran_total * 100 )) / 100
            },
            gran_totals_compare : {
                document_gran_total_compare: (Math.ceil(document_gran_total_compare * 100)) / 100,
                document_gran_num_compare : document_gran_num_compare,
                cover_gran_num_compare: cover_gran_num_compare
            }
        }

    }

    const [ data, setData ] = useState<DashboardDailyData>(dataInitialState);
    
    useEffect(() => {
        setData(formatData())
    }, [props.zReportAggregatesCompare])

    const handleDisplayChange = ( display : string ) => {

        setDisplay( display );

    }

    const handleModeChange = ( mode : TemporalSelectorModes ) : void => {

        setMode(mode);

        setData(dataInitialState);

    }

    const handleDataChange = ( startDate : Date, stopDate : Date) : void => {

        setData(dataInitialState);

        props.onDateChange(startDate, stopDate);

    }

    const compareLegendLabel = mode === TemporalSelectorModes.week ? 'shared.lastWeek' : 'shared.lastMonth';

    return (

        <React.Fragment>
            <TemporalSelector 
                onModeChange={handleModeChange}
                onDateChange={handleDataChange} 
                currentDate={props.lastZReportDate} 
                onDisplayChange={handleDisplayChange}
            />
            { data && data.aggregates.length > 0 ? 
                    <React.Fragment>

                        <DashboardDailySells
                            responsiveContainerClassName={style.responsiveContainer}
                            mode={mode} 
                            compareLegendLabel={compareLegendLabel}
                            data={data} 
                            reportCaption={display}/>
                        <DashboardDailyDocuments 
                            responsiveContainerClassName={style.responsiveContainer}
                            mode={mode} 
                            compareLegendLabel={compareLegendLabel}
                            data={data} 
                            reportCaption={display} />
                        <DashboardDailyCovers
                            responsiveContainerClassName={style.responsiveContainer} 
                            mode={mode} 
                            compareLegendLabel={compareLegendLabel}
                            data={data} 
                            reportCaption={display}/>

                    </React.Fragment> : <Loading /> 
            }


            <WarningModal
                title={t('zreports.zreport.fetchingErrorTitle')}
                message={t('zreports.zreport.fetchingErrorMessage')}
                onOk={props.onRefresh}
                isOpen={props.isError} />

        </React.Fragment>
    )

}

export default DashboardComponent;

