import React from 'react';
import { Row, Col, Checkbox } from 'antd';
import { faBell as farBell, faFlag as farFlag} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import LanguageSelectComponent from '../shared/languageSelect.component';
import ProfileMenuEntryWrapperComponent from './profileMenuEntryWrapper.component';

interface IPreferenceMenuComponentProps {
    onBack: (...args:any) => void;
    language: string;
}

const PreferenceMenuComponent: React.FC<IPreferenceMenuComponentProps> = props => {

    const { t } = useTranslation();

    return (

            <ProfileMenuEntryWrapperComponent onBack={props.onBack}>

                <Row gutter={0} style={{ padding:'5px',borderBottom: '1px solid grey'}}>
                    
                    <Col xs={16}>

                        <p>
                            <FontAwesomeIcon icon={farFlag} /> 
                            {"     " + t('profile.preferences.language')}
                        </p>

                    </Col>
                    
                    <Col xs={8}>

                        <LanguageSelectComponent value={props.language} />

                    </Col>

                </Row>

                <Row gutter={0} style={{ padding:'5px',borderBottom: '1px solid grey'}}>

                    <Col xs={16}>

                        <p>
                            <FontAwesomeIcon icon={farBell} /> 
                            {"     " + t('profile.preferences.pushNotification')}
                        </p>

                    </Col>

                    <Col xs={8}>

                        <Checkbox checked={false} disabled />

                    </Col>

                </Row>
                
            </ProfileMenuEntryWrapperComponent>
    )

}

export default PreferenceMenuComponent;