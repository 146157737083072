/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Card } from "antd";

import style from './support.module.css';
import { useTranslation } from 'react-i18next';
import { IReseller } from '../../features/Reseller/models/IReseller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';

interface IContactsComponentProps {
    reseller : IReseller
}

const ContactsComponent : React.FC<IContactsComponentProps> = props => {

    const { t } = useTranslation();

    const onEmailClick = ( email : string ) => {

        window.location.href = `mailto:${email}`;

    }

    const title = () => <> <FontAwesomeIcon icon={faHeadset} size="lg"/> <span style={{marginLeft:'10px'}}>{t('support.title')}</span> </>

    return (

        <Card
            title={title()}
            style={{ width:'100%' }}>

            <div>
                <p className={style.businessName}>{props.reseller.description}</p>
                <p className={style.other}>{t('support.contacts.vatCode')} : {props.reseller.vatCode}</p>
                <p className={style.other}> <FontAwesomeIcon icon={faEnvelope} /> <a onClick={ () => onEmailClick(props.reseller.email)}>{props.reseller.email}</a></p>
                <hr/>
                <p className={style.businessName}>{t('support.contacts.resellerCode')} : {props.reseller.resellerCode} </p>
            </div>

        </Card>

    )

}

export default ContactsComponent;