import { Card, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMaterial } from '../../features/Materials/data/materials';
import { IReseller } from '../../features/Reseller/models/IReseller';
import MaterialForm from './materialForm.component';

interface IMaterialComponentProps {
    materials : IMaterial[],
    reseller: IReseller | null,
    siteId : number;
    onSubmit: ( material : IMaterial, quantity : number, notes : string ) => void;
    isProcessing: boolean;
    isError:boolean;
}

const MaterialComponent : React.FC<IMaterialComponentProps> = props => {

    const { t } = useTranslation();

    return(

        <Row style={{marginLeft: '10px', marginTop: '10px', marginRight: '10px', position:'sticky'}} gutter={0}>
            
            <Card 
                title={t('consumables.title')}
                style={{ width:'100%' }}>

                <MaterialForm 
                    onSubmit={props.onSubmit}
                    materialOptions={props.materials}
                    isProcessing={props.isProcessing}
                    isError={props.isError} />

            </Card>

        </Row>

    )

}

export default MaterialComponent;