/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect, useState } from 'react';
import { ISite } from '../../shared/models/ISite';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHourglassStart, faUser } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'antd'
import style from './sites.module.css';
import iconbill from '../../assets/icons/icon-bill.svg'
import icondocument from '../../assets/icons/icon-documents.svg'
import iconcovers from '../../assets/icons/icon-covers.svg'
import iconcalendar from '../../assets/icons/icon-calendar.svg'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

interface ISiteEntryComponent {
    site : ISite ,
    isDetail?: boolean
}


const SiteEntryLive : React.FC<ISiteEntryComponent> = props => {

    const { t } = useTranslation();
    const [averageCovered, setAverageCovered] = useState('');
    const [hasZreportDayBefore, setHasZreportDayBefore] = useState(false);
    const [betterAverage, setBetterAverage] = useState(false);

    useEffect(() => {
        if(props.isDetail && props.site?.live?.cover_finalized && props.site?.live?.cover_finalized > 0 && props.site?.live?.amount_finalized){
            const average_covered_fixed = (props.site?.live?.amount_finalized / props.site?.live?.cover_finalized).toFixed(2) ?? '';
            setAverageCovered(average_covered_fixed);

            if(props.site?.last_z_report?.start_date){
                const currentDate = new Date();
                const zReportDate = new Date(props.site?.last_z_report?.start_date);
                if(isDayBefore(currentDate, zReportDate)){
                    
                    const zreportProceeds = props.site?.last_z_report?.document_total ?? 0;
                    const zreportCovers = props.site?.last_z_report?.cover_num ?? 0;
                    const zReportAverageCovered = zreportProceeds / zreportCovers ?? 0
                    const average_covered = props.site?.live?.amount_finalized / props.site?.live?.cover_finalized ?? 0;
                    if(zReportAverageCovered === average_covered) {
                        setHasZreportDayBefore(false);
                    }else{
                        setHasZreportDayBefore(true);
                        setBetterAverage(average_covered > zReportAverageCovered ? true : false);
                    }
                }
            }
        }
    },[])

    const getPreviousDay = (date = new Date()) => {
        const previous = new Date(date.getTime());
        previous.setDate(date.getDate() - 1);
      
        return previous;
      }

    const isDayBefore = (d1: Date, d2: Date) => {
        const previousDate = getPreviousDay(d1);
        return d2.getFullYear() === previousDate.getFullYear() &&
                d2.getMonth() === previousDate.getMonth() &&
                d2.getDate() === previousDate.getDate();
    }
  
    return (

        <React.Fragment>
                <div style={{backgroundColor:'rgb(240 240 240)', fontSize: '18px'}}>
                    <Row style={{alignItems:'center'}}>
                        { !props.isDetail ? <img src={iconcalendar} className={style.icon} style={{marginLeft: '20px'}}/> : null}
                        <b style={{marginLeft: '5px', paddingTop: '3px'}}> {t("shared.current_day")} </b>
                    </Row> 
                    { props.isDetail ? 
                        <Row style={{ padding: '10px 0', fontSize: '16px'}}>
                            <Col xs={props.site.activity_type !== 'retail' ? 12 : 15} style={{display: 'flex', flexDirection: 'column',gap: '.3rem', alignItems: 'center'}}>
                                <img src={iconbill} className={style.icon}/>
                                {t("shared.proceeds").toUpperCase()}
                            </Col>
                            {props.site.activity_type !== 'retail' ? <Col xs={6} style={{display: 'flex', flexDirection: 'column',gap: '.3rem', alignItems: 'center', borderLeft: '1px solid rgb(160 160 160)'}}>
                                <img src={iconcovers} className={style.icon}/>
                                {t("shared.cover").toUpperCase()}
                            </Col> : null}
                            <Col xs={props.site.activity_type !== 'retail' ? 6 : 9} style={{display: 'flex', flexDirection: 'column',gap: '.3rem', alignItems: 'center', borderLeft: '1px solid rgb(160 160 160)'}}>
                                <img src={icondocument} className={style.icon}/>
                                {t("shared.documents").toUpperCase()}
                            </Col>
                        </Row> 
                    : null}

                    <Row>
                        <Col xs={props.site.activity_type !== 'retail' ? 12 : 15} style={{display: 'flex', alignItems: 'center', paddingRight: '10px'}}>
                            <div style={{display: 'flex', justifyContent: 'center', width: '30%'}}>
                                <FontAwesomeIcon icon={faCheckCircle} color='green'/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'end', width: '70%', paddingLeft: '5px'}}>
                                {props.site.live!.amount_finalized} €
                            </div>
                        </Col>
                        {props.site.activity_type !== 'retail' ? <Col xs={6} style={{display: 'flex',justifyContent: 'end', alignItems: 'center' , paddingRight: '10px', borderLeft: '1px solid rgb(160 160 160)'}}>
                            <div style={{display: 'flex', justifyContent: 'end', width: '50%'}}>
                                <FontAwesomeIcon icon={faCheckCircle} color='green'/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'end', width: '50%', paddingLeft: '5px'}}>
                                {props.site.live!.cover_finalized}
                            </div>
                        </Col>: null }
                        <Col xs={props.site.activity_type !== 'retail' ? 6 : 9} style={{display: 'flex',justifyContent: 'end', alignItems: 'center', paddingRight: '10px', borderLeft: '1px solid rgb(160 160 160)'}}>
                            <div style={{display: 'flex', justifyContent: 'end', width: '50%'}}>
                                <FontAwesomeIcon icon={faCheckCircle} color='green'/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'end', width: '50%', paddingLeft: '5px' }}>
                                {props.site.live!.document_finalized}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={props.site.activity_type !== 'retail' ? 12 : 15} style={{display: 'flex', alignItems: 'center', paddingRight: '10px'}}>
                            <div style={{display: 'flex', justifyContent: 'center', width: '30%'}}>
                                <FontAwesomeIcon icon={faHourglassStart} color='orange' style={{width: '16px'}}/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'end', width: '70%', paddingLeft: '5px'}}>
                                <span>{props.site.live!.amount_not_finalized} €</span>
                            </div>
                        </Col>
                        {props.site.activity_type !== 'retail' ? <Col xs={6} style={{display: 'flex',justifyContent: 'end', alignItems: 'center' , paddingRight: '10px', borderLeft: '1px solid rgb(160 160 160)'}}>
                            <div style={{display: 'flex', justifyContent: 'end', width: '50%'}}>
                                <FontAwesomeIcon icon={faHourglassStart} color='orange' style={{width: '16px'}}/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'end', width: '50%', paddingLeft: '5px'}}>
                                {props.site.live!.cover_not_finalized}
                            </div>
                        </Col> : null}
                        <Col xs={props.site.activity_type !== 'retail' ? 6 : 9} style={{display: 'flex',justifyContent: 'end', alignItems: 'center' , paddingRight: '10px', borderLeft: '1px solid rgb(160 160 160)'}}>
                            <div style={{display: 'flex', justifyContent: 'end', width: '50%', paddingLeft: '5px'}}>
                                <FontAwesomeIcon icon={faHourglassStart} color='orange' style={{width: '16px'}}/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'end', width: '50%', paddingLeft: '5px'}}>
                                {props.site.live!.document_not_finalized}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={props.site.activity_type !== 'retail' ? 12 : 15} style={{display: 'flex', justifyContent: 'end', alignItems: 'center', paddingRight: '10px'}}>
                            <b style={{borderTop: '1px solid rgb(160 160 160)'}}>{props.site.live!.amount_tot} €</b>
                        </Col>
                        {props.site.activity_type !== 'retail' ? <Col xs={6} style={{display: 'flex', justifyContent: 'end', alignItems: 'center', paddingRight: '10px', borderLeft: '1px solid rgb(160 160 160)'}}>
                            <b style={{borderTop: '1px solid rgb(160 160 160)'}}>{props.site.live!.cover_tot}</b>
                        </Col>: null}
                        <Col xs={props.site.activity_type !== 'retail' ? 6 : 9} style={{display: 'flex', justifyContent: 'end', alignItems: 'center', paddingRight: '10px', borderLeft: '1px solid rgb(160 160 160)'}}>
                            <b style={{borderTop: '1px solid rgb(160 160 160)'}}>{props.site.live!.document_tot}</b>
                        </Col>
                    </Row>
                    {props.isDetail ?
                        <>
                            <Row style={{ alignItems: 'end'}}>
                                <Col xs={16} style={{paddingLeft: '9px', fontSize: '16px'}}>
                                {props.site.activity_type !== 'retail' ? t("shared.average_covered").toUpperCase() : t("dashboard.titles.weekly.average_per_document").toUpperCase()}
                                </Col>
                                <Col xs={8} style={{ display: 'flex', justifyContent: 'end', paddingRight: '10px', alignItems: 'center', gap: '.3rem'}}>
                                    {hasZreportDayBefore ?? <FontAwesomeIcon icon={betterAverage ? faArrowUp :faArrowDown} color={betterAverage ? 'green':'red'} /> }
                                    {`${averageCovered ? averageCovered + ' €' : '0.00 €'}`} 
                                </Col>
                            </Row> 
                            <Row style={{ marginTop: '10px', borderTop: '1px solid rgb(160 160 160)', alignItems: 'end'}}>
                                <Col xs={16} style={{paddingLeft: '9px', fontSize: '16px'}}>
                                    {t("shared.canceled_accounts").toUpperCase()}
                                </Col>
                                <Col xs={8} style={{ display: 'flex', justifyContent: 'end', paddingRight: '10px', alignItems: 'center', gap: '.3rem'}}>
                                    {props.site.live!.amount_waste} €
                                </Col>
                            </Row>
                        </> 

                    : null}
                </div>

        </React.Fragment>

    )
 

}

export default SiteEntryLive;