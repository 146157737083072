import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import React from 'react';
import BaseResponse from './BaseResponse';

import style from './response.module.css';

interface IErrorResponseProps {
    text : string;
}

const ErrorResponse : React.FC<IErrorResponseProps> = props => {

    return (

        <BaseResponse backgroundColor="#ff7171" >

            <Row style={{height:'100%'}}>

                <Col xs={4}>

                    <div className={style.textItem}>

                        <FontAwesomeIcon icon={faTimesCircle} size="lg" />

                    </div>

                </Col>
                <Col xs={20} style={{height:'100%'}}>

                    <div className={style.textItem}>

                        {props.text}

                    </div>

                </Col>

            </Row>

    </BaseResponse>

    )

}

export default ErrorResponse;