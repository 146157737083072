/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip, AreaChart, Area, Legend, ComposedChart, Bar } from 'recharts';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { IDashboardDailyProps } from './DashboardDailySells';
import { DashboardDailyData } from './DashboardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChartTotalsComponent, { ChartTotal } from './chartTotals.component';
import DashboardChartWrapper from './dashboardChartWrapper.component';
import DashboarcComponentTitle from './DashboardComponentTitle';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import TemporalSelector, { TemporalSelectorModes } from '../../layouts/TemporalSelector/TemporalSelector';

export interface IDashboardDailyDocumentsProps{
    data : DashboardDailyData,
    reportCaption? : string;
}

const DashboardDailyDocuments : React.FC<IDashboardDailyDocumentsProps & IDashboardDailyProps> = props => {

    const { t } = useTranslation();

    const document_numbers_translation  = t('zreports.zreport.totalDocumentsNumber');
    const document_avg_translation      = t('zreports.zreport.averagePerDocument');

    const totals : ChartTotal[] = [
        {
            value: props.data.gran_totals.document_gran_num,
            label : t('dashboard.current'),
            color : '#8884d8',
            fontSize: '3.5vw'
        },
        {
            value: props.data.gran_totals_compare.document_gran_num_compare,
            label : t('dashboard.previous'),
            color : '#888412'
        }
    ];

    const documents_number_title = props.mode === TemporalSelectorModes.week ? t('dashboard.titles.weekly.total_documents_number') : t('dashboard.titles.monthly.total_documents_number');
    const documents_average_title = props.mode === TemporalSelectorModes.week ? t('dashboard.titles.weekly.average_per_document') : t('dashboard.titles.monthly.average_per_document');

    return(

        <DashboardChartWrapper>

                <DashboarcComponentTitle title={documents_number_title} />

                <ChartTotalsComponent
                    totals={totals}
                    mainTotal={0}
                    compareTotal={1}
                    deltaSuffix={<FontAwesomeIcon icon={faFileAlt} />} />

                <ResponsiveContainer 
                    className={props.responsiveContainerClassName}
                    height={160}> 

                    <LineChart 
                        data={props.data.aggregates}
                        margin={{left:-20, top:10}}
                    >

                        <CartesianGrid/>

                        <XAxis dataKey="dateDay" />
                        <Tooltip labelFormatter={() => document_numbers_translation} />
                        <YAxis tick={{fontSize: 18}} />
                        <Line name={t('shared.period').toString()} type="monotone" dataKey="document_num" stroke="#8884d8" strokeWidth={2} />
                        <Line name={t(props.compareLegendLabel).toString()} type="monotone" dataKey="document_num_compare" stroke="#888412" strokeWidth={1} />
                    
                    </LineChart>

                </ResponsiveContainer>

                <hr/>

                <DashboarcComponentTitle title={documents_average_title} />

                <ResponsiveContainer 
                    className={props.responsiveContainerClassName}
                    width="100%" 
                    height={160}>

                    <LineChart 
                        data={props.data.aggregates}
                        margin={{left:-20, top: 20}}
                    >

                        <CartesianGrid/>

                        <XAxis dataKey="dateDay" />
                        <Tooltip labelFormatter={() => document_avg_translation} />
                        <YAxis tick={{fontSize: 18}} />
                        <Line name={t('shared.period').toString()} dataKey="document_avg" stroke="#8884d8" strokeWidth={2}/>
                        <Line name={t(props.compareLegendLabel).toString()} dataKey="document_avg_compare" stroke="#888123" strokeWidth={1}/>

                    </LineChart>

                </ResponsiveContainer>

                <div style={{textAlign:'center', width:'100%'}}>{props.reportCaption}</div>



            </DashboardChartWrapper>

    )

}

export default DashboardDailyDocuments;