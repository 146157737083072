import { AxiosResponse } from "axios";
import { AppDispatch } from "../../../core/store/store";
import request from "../../../core/request/request";
import { IElectronicInvoiceStatistics } from "../models/IElectronicInvoiceStatistics";

const host = process.env.REACT_APP_4CO_HOST || "";

const getStatistics = (siteId: number, dispatch: AppDispatch): Promise<IElectronicInvoiceStatistics> => {
	const endpoint = `/v2/_services/m/sites/${siteId}/electronic-invoice-statistics`;

	return new Promise(async (resolve, reject) => {
		try {
			const response: AxiosResponse<IElectronicInvoiceStatistics> = await request<IElectronicInvoiceStatistics>(
				host,
				{
					url: endpoint,
					method: "GET"
				},
				dispatch
			);
			resolve(response.data);
		} catch (e: any) {
			reject(e.response);
		}
	});
};

export { getStatistics };
