/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { IZReportHeader } from '../../features/ZReports/models/IZReportHeader';
import { Card, Row, Col } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { customIcons, CustomIconType } from '../../layouts/icons/icons';

import style from './zReports.module.css';
import { ISiteState } from '../../shared/slices/siteSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../core/store/rootReducer';

interface IZReportComponent {
     zreport : IZReportHeader
     selectZReport: (zReport : IZReportHeader) => void
}

const ZReportItemComponent : React.FC<IZReportComponent> = props => {

     const site : ISiteState = useSelector(
          ( state : RootState ) => state.SiteReducers
     );

     const selectZReport = () => {
          props.selectZReport(props.zreport);
     }

     const rowStyle : React.CSSProperties = {textAlign:'center', height:'100%', fontWeight: 'bold', fontSize:'18px', justifyContent: 'space-evenly'}

     const paragraphStyle : React.CSSProperties = {fontWeight: 'bold', fontSize:'4.5vw',marginTop:'auto', marginBottom: 'auto'}

     const { IconCalendar } = customIcons;

     const renderInfoColumn = ( SvgComponent : CustomIconType, value : string, color? : string ) => 
          <Col xs={8}>

               <Row>

                    <Col xs={24} >

                         <SvgComponent className={style.icon} />

                    </Col>

                    <Col xs={24}>

                         <span>{ value }</span>
                         
                    </Col>

               </Row>

          </Col>
     
     return (
          <Card onClick={selectZReport} style={{margin:"8px"}}>

               <Row>
                    <Col xs={6} style={{margin:'auto'}}>

                         <Row style={rowStyle}>

                              <Col xs={24} style={{textAlign:'center'}}>
                                   <div style={{height:'100%'}}> <span style={paragraphStyle}> # {props.zreport.z_count}</span> </div>
                              </Col>

                         </Row>

                    </Col>

                    <Col xs={14}>

                         <Row style={rowStyle}>
                              <Col xs={4} style={{margin:'auto'}}>
                                   {/* <IconCalendar style={{fontSize:'min(8vw,40px)'}} />  */}
                              </Col>
                              <Col xs={20} style={{margin:'auto'}}>
                                   <div style={{height:'100%'}}> <span style={paragraphStyle}>{props.zreport.start_date}</span> </div>
                              </Col>
                         </Row>

                    </Col>

                    <Col xs={4}>

                         <Row style={{height:'100%', float:'right'}}>

                              <div style={{margin:'auto', textAlign:'center'}}>
                                   <FontAwesomeIcon icon={faChevronRight} size="lg" color="#af0404"/>
                              </div>

                         </Row>                         

                    </Col>
               </Row>
               
               <hr/>

               <Row style={rowStyle}>


                    {renderInfoColumn( customIcons.IconBill, props.zreport.document_total + " €", 'green')}
                    {site.site?.activity_type !== 'retail' ? renderInfoColumn( customIcons.IconCover, props.zreport.cover_num?.toString() || '') : null}
                    {renderInfoColumn( customIcons.IconDocuments, props.zreport.document_num?.toString() || '')}

               </Row>
               
          </Card>
     );
}

export default ZReportItemComponent ;
