/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SiteHomeComponent from "../components/siteHome/siteHome.component";
import { RootState } from "../core/store/rootReducer";
import { IElectronicInvoiceStatisticsState, fetchEiStatistics } from "../features/ElectronicInvoice/slices/electronicInvoiceStatisticsSlices";
import { fetchReseller } from "../features/Reseller/slices/resellerSlices";
import { IZReportListState, fetchZReportListByDateInterval } from "../features/ZReports/slices/zReportListSlices";
import useDateUtilities from "../hooks/useDateUtilities";
import DefaultLayout from "../layouts/PageLayout/DefaultLayout";
import { ISiteState } from "../shared/slices/siteSlice";

const SiteHomeContainer: React.FC<{}> = (props) => {
	// reducer linked to home site list to catch when refreshing datas
	const site: ISiteState = useSelector((state: RootState) => state.SiteReducers);

	const electronicInvoiceStatistics: IElectronicInvoiceStatisticsState = useSelector((state: RootState) => state.ElectronicInvoiceReducers);

	const zReportList: IZReportListState = useSelector((state: RootState) => state.ZReportListReducers);

	const [curSiteData, setCurSiteData] = useState<ISiteState>(site);
	const [curInvoiceStatisticsData, setCurInvoiceStatisticsData] = useState<IElectronicInvoiceStatisticsState>(electronicInvoiceStatistics);
	const [curZReportData, setZReportData] = useState<IZReportListState>(zReportList);

	const [siteId, setSiteId] = useState(0);

	const history = useHistory();

	const dispatch = useDispatch();

	const { shiftDaysFromDate, formatDateToServerFormat } = useDateUtilities();

	const handleGoToDashboard = () => {
		history.push("/dashboard");
	};

	const handleGoToEiRecap = () => {
		history.push("/electronic-invoice-home");
	};

	const handleGoToZReportList = () => {
		history.push("/zreport-list");
	};

	const handleGoToMaterialsRequest = () => {
		history.push(`/sites/${siteId}/materials`);
	};

	const handleGoToSupport = () => {
		history.push(`/support`);
	};

	useEffect(() => {
		let cursite = site.site?.id ?? 0;
		setSiteId(cursite);
	}, []);

	useEffect(() => {
		if (siteId) {
			fetchData();
		}
	}, [siteId]);

	useEffect(() => {
		setCurSiteData(site);
		setSiteId(site?.site?.id ?? 0);
	}, [site]);

	useEffect(() => {
		setCurInvoiceStatisticsData(electronicInvoiceStatistics);
	}, [electronicInvoiceStatistics]);

	useEffect(() => {
		setZReportData(zReportList);
	}, [zReportList.zReportList, zReportList.lastZReport]);

	const getStopDate = (): Date => {
		const date = new Date(site.site?.last_z_report?.start_date || "");
		if (!isNaN(date.getTime())) return date;
		else return new Date();
	};

	const fetchData = () => {
		/*** Chiamo le API per la pagina site-home **/

		const stopDate = getStopDate();
		const startDate = shiftDaysFromDate(stopDate, -7);

		dispatch(fetchReseller(siteId));

		dispatch(fetchEiStatistics(siteId));

		dispatch(fetchZReportListByDateInterval(siteId, formatDateToServerFormat(startDate), formatDateToServerFormat(stopDate), "day"));
	};

	const page = (
		<DefaultLayout onRefresh={fetchData}>
			<SiteHomeComponent
				onRefresh={fetchData}
				handleGoToDashboard={handleGoToDashboard}
				handleGoToEIRecap={handleGoToEiRecap}
				handleGoToZReportList={handleGoToZReportList}
				handleGoToMaterialsRequest={handleGoToMaterialsRequest}
				handleGoToSupport={handleGoToSupport}
				eiStatisticsState={curInvoiceStatisticsData}
				zReportListState={curZReportData}
				ISiteState={curSiteData}
			/>
		</DefaultLayout>
	);

	return page;
};

export default SiteHomeContainer;
