/* eslint-disable react-hooks/exhaustive-deps */
import { faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFeedback } from '../../../features/Feedbacks/models/feedback';
import usePrevious from '../../../hooks/usePrevious';
import ErrorResponse from '../../../layouts/Response/ErrorResponse';
import SuccessResponse from '../../../layouts/Response/SuccessResponse';

import style from './feedback.module.css';

interface IFeedbackFormProps {
    isProcessing : boolean;
    isError: boolean;
    onSubmit: ( feedback : IFeedback ) => void;
}

const FeedbackFormComponent : React.FC<IFeedbackFormProps> = props => {

    const { t } = useTranslation();
    
    const prevProps = usePrevious<{isProcessing : boolean, isError : boolean}>({isProcessing: props.isProcessing, isError: props.isError});

    const [ title, setTitle ]   = useState<string | undefined>(undefined);
    const [ body, setBody ]     = useState<string | undefined>(undefined);
    const [ files, setFiles]    = useState<RcFile[]>([]);

    const [ showSuccess, setShowSuccess ]   = useState<boolean>(false);
    const [ showError, setShowError ]       = useState<boolean>(false);

    const onSubmit = () => {

        const data = new FormData();

        files.forEach( file => {

            data.append("files[]",file)

        });

        title && body && 
            props.onSubmit({
                title: title,
                body: body,
                files: data
            });


    }

    const addFile = ( file : RcFile, list : RcFile[]) : boolean => {

        setFiles([
            ...files,file
        ]);

        return false;

    }

    const onRemove = ( fileToRemove : UploadFile) => {

        const index = files.findIndex( file => file.uid === fileToRemove.uid)

        if(index > -1 ) {

            let tmpFiles = [...files];
            tmpFiles.splice( index, 1);

            setFiles( tmpFiles );

        }
        
    } 

    useEffect(() => {

        if(prevProps?.isProcessing && !props.isError && !props.isProcessing) {
            setShowSuccess(true);
            resetForm();
        }
        
        if(prevProps?.isProcessing && props.isError && !props.isProcessing) {
            setShowError(true);
        }

    }, [props.isError, props.isProcessing])

    const resetForm = () => {

        setTitle(undefined);
        setBody(undefined);
        setFiles([]);

    }

    return (

        <div>

            { showError && <ErrorResponse text={t('support.feedback.form.errorResponse')} /> }
            { showSuccess && <SuccessResponse text={t('support.feedback.form.successResponse')} /> }

                <Input
                    placeholder={t('support.feedback.form.subject')}
                    value={title}
                    onChange={ e => setTitle(e.target.value)} />



                <TextArea
                    autoSize={{minRows:3, maxRows: 6}}
                    className={style.feedbackSpacing}
                    placeholder={t('support.feedback.form.body')}
                    value={body}
                    onChange={ e => setBody( e.target.value)} />

                <div className={`${style.feedbackSpacing}`}>

                    <Upload
                        fileList={files}
                        beforeUpload={addFile}   
                        onRemove={onRemove}           
                        >
                            <Button icon={<FontAwesomeIcon icon={faUpload}/>}><span className={style.buttonLabelSpacing}>Upload</span></Button>
                    </Upload>

                </div>

                <Button
                    id="feedbackFormSubmitButton" 
                    disabled={props.isProcessing || !title || !body }
                    onClick={onSubmit}
                    className={`${style.feedbackSpacing} ${style.feedbackButton}`}
                    type="primary" >{props.isProcessing ? <FontAwesomeIcon icon={faSpinner} spin /> : t('shared.send')}</Button>

        </div>

    )

}

export default FeedbackFormComponent;