import React from 'react';
import { Row, Col } from 'antd';

import ProfileMenuEntryWrapperComponent from './profileMenuEntryWrapper.component';
import ProfileContactsComponent from './profileContacts.component';
import ProfileContactUsComponent from './profileContactUs.component';

interface IAboutMenuComponentProps {
    onBack: (...args:any) => void;
}

const AboutMenuComponent : React.FC<IAboutMenuComponentProps> = props => {

    return (

        <ProfileMenuEntryWrapperComponent onBack={props.onBack}>


            <Row gutter={0} style={{ padding:'5px',borderBottom: '1px solid grey'}}>

                <Col xs={24}>

                    <h3> MyBasiq App </h3>

                </Col>

                <Col xs={24}>
                    
                    <h5> Version : {process.env.REACT_APP_VERSION}</h5>

                </Col>

            </Row>

            <ProfileContactsComponent />

            <ProfileContactUsComponent />

        </ProfileMenuEntryWrapperComponent>

    )

}

export default AboutMenuComponent;