import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProfileMenuEntryComponentProps {
    label: string;
    color?: string;
    icon: IconProp;
    onClick? : (...args:any) => void
}

const ProfileMenuEntryComponent : React.FC<IProfileMenuEntryComponentProps> = props => {

    return(

        <Col xs={24} onClick={props.onClick}>

            <p style={{padding:'5px',borderBottom:'1px solid grey', color: props.color || 'black'}}>
                <FontAwesomeIcon icon={props.icon} /> 
                {"     " + props.label}
            </p>

        </Col>

    )

}

export default ProfileMenuEntryComponent;