/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IUserState, userLogging, userLoginSuccess } from '../shared/slices/userSlices';
import { RootState } from '../core/store/rootReducer';
import LoginContainer from '../containers/LoginContainer';
import { useEffect } from 'react';
import { IUser } from '../shared/models/IUser';
import SitesContainer from '../containers/SitesContainer';
import ZReportContainer from '../containers/ZReportContainer';
import { Layout } from 'antd';
import ZReportDetailContainer from '../containers/ZReportDetailContainer';
import SiteHomeContainer from '../containers/SiteHomeContainer';
import ElectronicInvoiceContainer from '../containers/ElectronicInvoiceContainer';
import DashboardContainer from '../containers/DashboardContainer';
import ProfileContainer from '../containers/ProfileContainer';
import useAppStatus from '../hooks/useAppStatus';
import { setOnline } from '../shared/slices/appStatusSlice';
import NotificationContainer from '../containers/NotificationContainer';
import MaterialContainer from '../containers/MaterialContainer';
import SupportContainer from '../containers/SupportContainer';

const Routes : React.FC<{}> = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(userLogging(true));

        const storedUser = localStorage.getItem("user") || '';

        const user : IUser = storedUser ? JSON.parse(storedUser) : '';

        user ? dispatch(userLoginSuccess(user)) : dispatch(userLogging(false));

    }, []);

    const user : IUserState = useSelector(
        ( state : RootState) => state.UserReducers
    )

    const { online } = useAppStatus();

    useEffect(() => {

        dispatch(setOnline(online));

    }, [ online ])

    return(

        <React.Fragment>


            <Layout style={{backgroundColor:"#FFF"}}>

                <BrowserRouter>

                        <Switch>
                        
                            { user ? <Route exact path="/login" component={LoginContainer} /> : <Redirect to='/sites' /> }

                            <PrivateRoute isLogged={!!user.user} />

                        </Switch>

                </BrowserRouter>

            </Layout>
                

        </React.Fragment>

    )

}

export default Routes;

const PrivateRoute : React.FC<{ isLogged : boolean }> = props => {

    return (
        props.isLogged 
            ?   <React.Fragment> 
                    <Route exact path="/site-home" component={SiteHomeContainer} />
                    <Route exact path="/zreport-list" component={ZReportContainer} />
                    <Route exact path="/zreports/:id" component={ZReportDetailContainer} />
                    <Route exact path="/electronic-invoice-home" component={ElectronicInvoiceContainer} />
                    <Route exact path="/dashboard" component={DashboardContainer} />
                    <Route exact path="/notifications" component={NotificationContainer} />
                    <Route exact path="/profile/:id" component={ProfileContainer} />
                    <Route exact path="/support" component={SupportContainer} />
                    <Route exact path="/sites/:id/materials" component={MaterialContainer} />
                    <Route exact path="/sites" component={SitesContainer} />
                    <Redirect to="/sites" />
                </React.Fragment> 
            
            : <Redirect to="/login" />
    )

}