import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../../core/store/store';
import { IReseller } from '../models/IReseller';
import { resellerService } from '../services/reseller.service';

export interface IResellerState {
	reseller: IReseller | null;
	loading: boolean;
	error: boolean
};

const initialState: IResellerState = {
	reseller: null,
	loading: false,
	error: false
};

const resellerSlice = createSlice({
	name: 'Reseller',
	initialState,
	reducers: {

		resellerFetching(state: IResellerState, action: PayloadAction<boolean>) {

			state.error = false;

			state.loading = action.payload;

		},

		resellerFetchSuccess(state: IResellerState, action: PayloadAction<IReseller>) {

			state.reseller = action.payload;

			state.error = false;
			state.loading = false;

		},

		resellerFetchError(state: IResellerState, action: PayloadAction<null>) {

			state.reseller = null;
			state.error = true;
			state.loading = false;

		}

	}
})

export const { resellerFetching, resellerFetchSuccess, resellerFetchError } = resellerSlice.actions;

export default resellerSlice.reducer;

export const fetchReseller = (siteId: number): AppThunk => async dispatch => {

	try {

		dispatch(resellerFetching(true));

		const reseller = await resellerService.getReseller(siteId, dispatch<any>);

		dispatch(resellerFetchSuccess(reseller));

	}
	catch (e) {

		dispatch(resellerFetchError(null));

	}

}

