import { combineReducers, Reducer } from '@reduxjs/toolkit';

import UserReducers from '../../shared/slices/userSlices';
import ZReportListReducers from '../../features/ZReports/slices/zReportListSlices';
import SiteReducers from '../../shared/slices/siteSlice';
import SitesReducers from '../../shared/slices/sitesSlices';
import ElectronicInvoiceReducers from '../../features/ElectronicInvoice/slices/electronicInvoiceStatisticsSlices';
import ZReportReducers from '../../features/ZReports/slices/zReportSlice';
import LanguageReducers from '../../shared/slices/languageSlice';
import AppStatusReducers from '../../shared/slices/appStatusSlice';
import MaterialReducers from '../../features/Materials/slices/materialSlice';
import ResellerReducers from '../../features/Reseller/slices/resellerSlices';
import FeedbackReducers from '../../features/Feedbacks/slices/feedbackSlices';

const rootReducer : Reducer = combineReducers( { 
    UserReducers: UserReducers,
    SitesReducers : SitesReducers,
    ZReportListReducers: ZReportListReducers,
    SiteReducers : SiteReducers,
    ZReportReducers: ZReportReducers,
    LanguageReducers: LanguageReducers,
    ElectronicInvoiceReducers: ElectronicInvoiceReducers,
    AppStatusReducers,
    MaterialReducers,
    ResellerReducers,
    FeedbackReducers
} );

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;