import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser, faWifi } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'antd';
import { ISite } from '../../shared/models/ISite';
import style from './topToolbar.module.css';
import { useHistory } from 'react-router-dom';
import { customIcons } from '../icons/icons';

interface ITopToolbarProps {
    onBack: (...args: any) => void;
    site : ISite | null;
    user : string;
    onTitleClick: (...args:any) => void;
    onUser: (...args: any) => void;
    online: boolean;
}

const TopToolbar : React.FC<ITopToolbarProps> = props => {

    const history = useHistory();

    const getBackgroundColor = () : string => {

        return history.location.pathname === '/login' ? '#FFF' : '#414141'

   }

   const { MyBasiqHeaderLogo } = customIcons;


    return(
        <div className={style.fill} style={{padding:"10px", height:"50px", position:'absolute', width:"100%", backgroundColor:getBackgroundColor(), zIndex:2, top:"0px",left:"0px", color:'white'}}>
            
            {props.user && <Row className={style.fill} gutter={0}>
                <Col className={style.fill} xs={3}>

                    {props.site?.name ? <FontAwesomeIcon icon={faArrowLeft} onClick={props.onBack} color="white" /> : null }

                </Col>
                <Col className={style.fill} xs={18}>

                  
                    <Row className={style.fill} justify={"center"} onClick={props.onTitleClick} gutter={0}>
                        { props.site && props.site.name ? <h3 style={{color:'white'}}> {props.site?.name} </h3> : <MyBasiqHeaderLogo /> }
                    </Row>

                </Col>
                <Col xs={1}>
                    {props.online ? null : <FontAwesomeIcon icon={faWifi} size="lg" color='#ff7171' /> }
                </Col>
                <Col xs={2} onClick={props.onUser}>

                    <Row justify={"center"} gutter={0}>
                        <FontAwesomeIcon icon={faUser} size="2x"/>
                    </Row>

                </Col>

            </Row> }

        </div>
    )

}

export default TopToolbar;