/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { IZReportAggregate } from '../../features/ZReports/models/IZReportAggregate';

import style from './siteHome.module.css';
import LastDateTooltipComponent from './sellsChartComponents/lastDateTooltip.component';
import useDateUtilities from '../../hooks/useDateUtilities';
import { ISite } from '../../shared/models/ISite';
import { ILanguageState } from '../../shared/slices/languageSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../core/store/rootReducer';

interface IZReportSellsChartComponent {
     isLoading: boolean,
     isError: boolean,
     zReportList: IZReportAggregate[] | null,
     site : ISite,

}

const ZReportSellsChartComponent : React.FC<IZReportSellsChartComponent> = props => {

     const { t } = useTranslation();

     const amountLbl = t("site.lastZReportChart.sellsAmount") || "";
     
     const [ x, setX ] = useState<number>(0);

     const [dateKeyFinalized, setDateKeyFinalized] = useState('finalized');
     const [dateKeyNotFinalized, setDateKeyNotFinalized] = useState('not_finalized');

     const { isStringValidDate, formatDateToLocale } = useDateUtilities();

     const language: ILanguageState = useSelector(
		(state: RootState) => state.LanguageReducers
	)

     useEffect(() => {
          switch (language.value) {
               case 'FR':
                    setDateKeyFinalized('cloturé');
                    setDateKeyNotFinalized('en cours');
                    break;
               case 'IT':
                    setDateKeyFinalized('finalized');
                    setDateKeyNotFinalized('not_finalized');
                    break;
               default:
                    setDateKeyFinalized('finalized');
                    setDateKeyNotFinalized('not_finalized');
                    break;
          }
     },[language])

     const data = props.zReportList?.map( aggregate => {
          return {
               ...aggregate,
               dateDay : t('shared.calendar.days.' + new Date(aggregate.start_date).toLocaleDateString('en-En',{weekday:'long'}) + '.letter')
          }
     }) ?? [];

     const lastDate = () => {
          if(props.site.live && props.site.live.lastUpdate){
               return formatDateToLocale(
                    new Date(props.site.live.lastUpdate)
               );
          }else{
               if(data && data.length > 0 && isStringValidDate(data[data.length - 1].start_date)) {

                    return formatDateToLocale(
                         new Date(data[data.length - 1].start_date)
                    );
     
               }
          }

          return ''
     }

     const myData = () => {
          let dataGraph = [];
          if(props.site.live && props.site?.live?.lastUpdate){
               const liveDate: Date = new Date(props.site?.live?.lastUpdate);
               const convertedliveDate = liveDate.getFullYear() + '-' + ("0" +liveDate.getMonth()).slice(-2) + '-' +  liveDate.getDate();

               for (let index = 7; index > 0; index--) {
                    const startingDateBeforeLiveDate = new Date(liveDate.getTime() - (index * 24 * 60 * 60 * 1000)); // date minus 7days
                    const convertedStartingDateBeforeLiveDate = startingDateBeforeLiveDate.getFullYear() + '-' + ("0" +startingDateBeforeLiveDate.getMonth()).slice(-2) + '-' +  startingDateBeforeLiveDate.getDate();
                    const zreportFound = data?.find(zreport => zreport.start_date === convertedStartingDateBeforeLiveDate) ?? null;
                    let obj;
                    if(zreportFound){
                         switch (language.value) {
                              case 'FR':
                                   obj = {
                                        name: t('shared.calendar.days.' + new Date(zreportFound.start_date).toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                                        total: zreportFound.document_total,
                                        cloturé:0,
                                        "en cours":0
                                   }
                                   break;
                              case 'IT':
                                   obj = {
                                        name: t('shared.calendar.days.' + new Date(zreportFound.start_date).toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                                        total: zreportFound.document_total,
                                        finalized:0,
                                        not_finalized:0
                                   }
                                   break;
                              default:
                                   obj = {
                                        name: t('shared.calendar.days.' + new Date(zreportFound.start_date).toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                                        total: zreportFound.document_total,
                                        finalized:0,
                                        not_finalized:0
                                   }
                                   break;
                         }
                         
                         dataGraph.push(obj);
                    }else{
                         switch (language.value) {
                              case 'FR':
                                   obj = {
                                        name: t('shared.calendar.days.' + new Date(startingDateBeforeLiveDate).toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                                        total: 0,
                                        cloturé:0,
                                        "en cours":0
                                   }
                                   break;
                              case 'IT':
                                   obj = {
                                        name: t('shared.calendar.days.' + new Date(startingDateBeforeLiveDate).toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                                        total: 0,
                                        finalized:0,
                                        not_finalized:0
                                   }
                                   break;
                              default:
                                   obj = {
                                        name: t('shared.calendar.days.' + new Date(startingDateBeforeLiveDate).toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                                        total: 0,
                                        finalized:0,
                                        not_finalized:0
                                   }
                                   break;
                         }

                         dataGraph.push(obj);
                    }
                    
               }

               const zReportWithSameDateAsLive = data?.filter(zreport => zreport.start_date === convertedliveDate) ?? [];
               let zReportTotalDocument = 0;
               zReportWithSameDateAsLive.forEach(zReport => {
                    zReportTotalDocument += zReport.document_total ?? 0
               });
               let obj;
               switch (language.value) {
                    case 'FR':
                         obj = {
                              name: t('shared.calendar.days.' + liveDate.toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                              total: zReportWithSameDateAsLive.length > 0 ? zReportTotalDocument + props.site.live.document_tot ?? 0 : props.site.live.document_tot ?? 0,
                              cloturé:props.site.live.amount_finalized ?? 0,
                              "en cours":props.site.live.amount_not_finalized ?? 0
                         }
                         break;
                    case 'IT':
                         obj = {
                              name: t('shared.calendar.days.' + liveDate.toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                              total: zReportWithSameDateAsLive.length > 0 ? zReportTotalDocument + props.site.live.document_tot ?? 0 : props.site.live.document_tot ?? 0,
                              finalized:props.site.live.amount_finalized ?? 0,
                              not_finalized:props.site.live.amount_not_finalized ?? 0
                         }
                         break;
                    default:
                         obj = {
                              name: t('shared.calendar.days.' + liveDate.toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                              total: zReportWithSameDateAsLive.length > 0 ? zReportTotalDocument + props.site.live.document_tot ?? 0 : props.site.live.document_tot ?? 0,
                              finalized:props.site.live.amount_finalized ?? 0,
                              not_finalized:props.site.live.amount_not_finalized ?? 0
                         }
                         break;
               }

               dataGraph.push(obj);
          }else{
               if(data && data.length > 0 ){
                    for (let i = 0; i < data.length  ; i++) {
                         let obj = {};
                         if(i >= 0 && i < data.length){
                              switch (language.value) {
                                   case 'FR':
                                        obj = {
                                             name: t('shared.calendar.days.' + new Date(data[i].start_date).toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                                             total: data[i].document_total,
                                             cloturé:0,
                                             "en cours":0
                                        }
                                        break;
                                   case 'IT':
                                        obj = {
                                             name: t('shared.calendar.days.' + new Date(data[i].start_date).toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                                             total: data[i].document_total,
                                             finalized:0,
                                             not_finalized:0
                                        }
                                        break;
                                   default:
                                        obj = {
                                             name: t('shared.calendar.days.' + new Date(data[i].start_date).toLocaleDateString('en-En',{weekday:'long'}) + '.letter'),
                                             total: data[i].document_total,
                                             finalized:0,
                                             not_finalized:0
                                        }
                                        break;
                              }
                         }

                         dataGraph.push(obj);
                    }
               }
          }

          return dataGraph;
     }

     /**
      * This function relies on the rechart.js library xAxis classes.
      * Its important to not update the rechart library without testing this function
      * 
      * At the moment i couldnt find another way to set the tooltip in that position without asking those recharts elements for the coordinates.
      * 
      */
     const getLastXAxisTick = () : number => {

          const xAxis = document.getElementsByClassName('recharts-layer recharts-cartesian-axis recharts-xAxis xAxis');
          if(!xAxis || xAxis.length <= 0) return 0;

          const ticks = xAxis[0].getElementsByClassName('recharts-layer recharts-cartesian-axis-tick');
          if(ticks.length <= 0) return 0;

          const lastTickText = ticks[ticks.length - 1].getElementsByClassName('recharts-text recharts-cartesian-axis-tick-value');
          if(lastTickText.length <= 0) return 0;

          return parseInt(
               lastTickText[0].getAttribute('x') || '0'
          );

     }

     useEffect(() => {

          setX(getLastXAxisTick());

     }, [data]);

     const onResize = () => {

          setTimeout( () => setX(getLastXAxisTick()), 200);

     }

     useEffect(() => {

          window.onresize = onResize;

          return () => {
               window.onresize = null;
          }

     }, [])

     const onAnimationStart = ( ) => {

          setTimeout( () => setX(getLastXAxisTick()), 500);

     }

     return (
          <Card style={{width: '100%'}} bodyStyle={{backgroundColor:'#fff'}}>
               <ResponsiveContainer        className={style.responsiveContainer}
                                             height={160} >
                    <BarChart
                         width={500}
                         height={300}
                         data={myData()}
                         margin={{
                              top: 20,
                              right: 30,
                              left: 20,
                              bottom: 5,
                         }}
                    >
                         <CartesianGrid strokeDasharray="3 3" />
                         <XAxis dataKey="name" />
                         <YAxis tick={{fontSize: 18}} />
                         <Tooltip />
               
                    
                         <Bar dataKey="total"        fill="#414141" />
                         <Bar dataKey={dateKeyFinalized}    stackId="a" fill="rgb(88 185 88)" />
                         <Bar dataKey={dateKeyNotFinalized}   stackId="a"           fill="#ffc658" />
                    </BarChart>
               </ResponsiveContainer>
               { x ? <LastDateTooltipComponent x={x} data={lastDate()} /> : null}
          </Card>
     );
}


export default ZReportSellsChartComponent;