import Axios from "axios";

const usePolyfillViewport = () => {

    let doc = window.document;

    const getElementStyle = ( element : HTMLElement ) : CSSStyleDeclaration | undefined => {

        if('getComputedStyle' in window) return getComputedStyle(element, null);

    }

    const appendStyle = ( style : HTMLStyleElement, cssString : string ) : void => {

        if(style.type) style.type = 'text/css';

        const cssNode = doc.createTextNode(cssString);
        style.appendChild(cssNode);

    }

    const browserSupportsViewport = () : boolean => {

        const body = doc.body;
        const head = doc.head;

        const dummyElement      = doc.createElement('div');
        dummyElement.id = "dummyElemVW";
        body.appendChild(dummyElement);

        const dummyElementStyle = doc.createElement('style');
        head.appendChild(dummyElementStyle);

        const cssString         = '#dummyElemVW { width:50vw }';
        const width             = parseInt((window.innerWidth /2 ).toString(), 10);

        appendStyle( dummyElementStyle, cssString );

        const testW = parseInt( getElementStyle(dummyElement)?.width || '0' , 10);

        body.removeChild(
            dummyElement
        );

        head.removeChild(
            dummyElementStyle
        );

        return testW === width;

    }

    const setFontSize = () : void => {

        const setRem = () => {

            const rem = doc.documentElement.clientWidth / 100;
            doc.documentElement.style.fontSize = `${rem}px`;

        }

        setRem();

        window.addEventListener('resize',setRem);
        window.addEventListener('pageshow', e => e.persisted && setRem() );

    }

    const getCSS = async ( url : string ) : Promise<string> => {

        return new Promise( async ( resolve,reject) => {

            try{

                const response = await Axios.get<string>( url);
        
                resolve(response.data);
        
            }
            catch( e ) { reject(e) }

        })

    }

    const replaceViewport = ( cssString : string ) => {

        const vwRe = /([+-]?[0-9.]+)vw/g;
        const vwReOnce = /([+-]?[0-9.]+)vw/;

        const newStyle = doc.createElement('style');

        const viewportList = cssString.match(vwRe);

        if(viewportList) {

            viewportList.forEach( item => cssString = cssString.replace(vwReOnce, item.replace('vw','rem')) )

            appendStyle( newStyle, cssString);

            const head = doc.head || doc.getElementsByTagName('head')[0]

            head.appendChild(newStyle);

        }

    }

    const replaceViewportsWithRem = async () => {

        const styles = doc.getElementsByTagName('style')
        const links = doc.getElementsByTagName('link');

        for (const style of styles) {
            style.textContent && replaceViewport(style.textContent)
        }

        for (const link of links) {
            try{

                const css = await getCSS(link.href);
                replaceViewport(css);

            }
            catch( e ) { console.log(e) }

        }

    }

    const polyfill = () => {
        if(!browserSupportsViewport()) {

            console.log("Broswer does not support viewport sizes");
            setFontSize();
            replaceViewportsWithRem();

        }
        else { console.log("Broswer supports viewport sizes");}
    }

    return { polyfill }

}

export default usePolyfillViewport;
