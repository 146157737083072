/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import DefaultLayout from '../layouts/PageLayout/DefaultLayout';
import { IElectronicInvoiceStatisticsState } from '../features/ElectronicInvoice/slices/electronicInvoiceStatisticsSlices';
import { useSelector } from 'react-redux';
import { RootState } from '../core/store/rootReducer';
import ElectronicInvoiceResumeComponent from '../components/electronicInvoice/resume/electronicInvoiceResume.component';

const ElectronicInvoiceContainer : React.FC<{}> = () => 
{
     const electronicInvoiceStatistics : IElectronicInvoiceStatisticsState = useSelector(
          ( state : RootState ) => state.ElectronicInvoiceReducers
     );

     return (
          <DefaultLayout>
               <ElectronicInvoiceResumeComponent statistics={electronicInvoiceStatistics.statistics} />
          </DefaultLayout>
     );
}

export default ElectronicInvoiceContainer;