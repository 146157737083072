import { AxiosResponse } from "axios";
import request from "../../../core/request/request";
import { AppDispatch } from "../../../core/store/store";
import { IConsumableRequest } from "../models/IConsumableRequest";

const host = process.env.REACT_APP_RT_HOST || "";

const postMaterials = (product: string, quantity: number, notes: string = "", siteId: number, idReseller: number, dispatch: AppDispatch) => {
	const endpoint = `/resellers/${idReseller}/consumables_requests`;

	return new Promise(async (resolve, reject) => {
		const body = {
			product,
			quantity,
			notes,
			siteId
		};

		try {
			const response: AxiosResponse<IConsumableRequest> = await request<IConsumableRequest>(
				host,
				{
					url: endpoint,
					method: "POST",
					data: body
				},
				dispatch
			);

			resolve(response);
		} catch (e: any) {
			reject(e.response);
		}
	});
};

export const materialsService = { postMaterials };
