/* eslint-disable @typescript-eslint/no-unused-vars */
import { faHeadset, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import SiteEntryLive from "../../components/sites/siteEntryLive.component";
import { IElectronicInvoiceStatisticsState } from "../../features/ElectronicInvoice/slices/electronicInvoiceStatisticsSlices";
import { IZReportListState } from "../../features/ZReports/slices/zReportListSlices";
import HomeRow from "../../layouts/HomeRow/HomeRow";
import WarningModal from "../../layouts/Modal/WarningModal";
import SectionButton from "../../layouts/SectionButton/SectionButton";
import { customIcons } from "../../layouts/icons/icons";
import { ISiteState } from "../../shared/slices/siteSlice";
import ElectronicInvoiceButtonComponent from "./electronicInvoiceButton.component";
import ZReportButtonComponent from "./zReportButton.component";
import ZReportSellsChartComponent from "./zReportSellsChart.component";

interface ISiteHomeComponent {
	onRefresh: (...args: any) => void;
	handleGoToDashboard: () => void;
	handleGoToZReportList: () => void;
	handleGoToEIRecap: () => void;
	handleGoToMaterialsRequest: (...args: any) => void;
	handleGoToSupport: (...args: any) => void;
	eiStatisticsState: IElectronicInvoiceStatisticsState;
	zReportListState: IZReportListState;
	ISiteState: ISiteState;
}

const SiteHomeComponent: React.FC<ISiteHomeComponent> = (props) => {
	const { t } = useTranslation();

	const siteLive = props.ISiteState.site;

	const { IconMatCons } = customIcons;

	const dashboardSectionComponent = () => (
		<SectionButton title={t("site.dashboardSection")} onClick={props.handleGoToDashboard}>
			<FontAwesomeIcon icon={faTachometerAlt} size="3x" />
		</SectionButton>
	);

	const zReportSection = () => (
		<ZReportButtonComponent
			title={t("site.zreportSection")}
			isError={props.zReportListState.isError}
			isLoading={props.zReportListState.isLoading}
			zReportList={props.zReportListState.zReportList}
			lastZReport={props.zReportListState.lastZReport}
			handleClick={props.handleGoToZReportList}
		/>
	);

	const electronicInvoiceButtonComponent = () => (
		<ElectronicInvoiceButtonComponent
			title={t("site.electronicInvoiceSection")}
			isError={props.eiStatisticsState.isError}
			isLoading={props.eiStatisticsState.isLoading}
			statistics={props.eiStatisticsState.statistics}
			handleClick={props.handleGoToEIRecap}
		/>
	);

	return (
		<React.Fragment>
			<WarningModal
				title={t("site.siteHome.errorTitle")}
				onOk={props.onRefresh}
				isOpen={props.zReportListState.isError}
				message={t("site.siteHome.errorMessage")}
			/>

			<Row style={{ marginTop: "10px", paddingRight: "10px", paddingLeft: "10px" }}>
				<Col xs={24}>{siteLive ? <SiteEntryLive site={siteLive} isDetail={true} /> : null}</Col>
			</Row>

			<Row style={{ marginLeft: "10px", marginTop: "10px", marginRight: "10px" }}>
				{siteLive ? (
					<ZReportSellsChartComponent
						isError={props.eiStatisticsState.isError}
						isLoading={props.eiStatisticsState.isLoading}
						site={siteLive}
						zReportList={props.zReportListState.zReportAggregates}
					/>
				) : null}
			</Row>

			<HomeRow firstComponent={dashboardSectionComponent()} secondComponent={zReportSection()} />

			<HomeRow firstComponent={electronicInvoiceButtonComponent()} secondComponent={<SectionButton />} />

			<HomeRow
				firstComponent={
					<SectionButton onClick={props.handleGoToMaterialsRequest} title={t("consumables.title")}>
						<IconMatCons style={{ fontSize: "20vw" }} />
					</SectionButton>
				}
				secondComponent={
					<SectionButton onClick={props.handleGoToSupport} title="Support">
						<FontAwesomeIcon icon={faHeadset} size="3x" style={{ marginTop: "15px" }} />
					</SectionButton>
				}
			/>
		</React.Fragment>
	);
};

export default SiteHomeComponent;
