import React, { ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';

interface IDeltaComponentProps {
    value : number;
    compareValue : number;
    deltaSuffix? : string | ReactNode;
}

interface DeltaIcon {
    icon    : IconProp;
    color   : string;
}

const DeltaComponent : React.FC<IDeltaComponentProps> = props => {


    const deltaIcon : DeltaIcon = props.value > props.compareValue ? { icon : faArrowUp, color : 'green' } : { icon : faArrowDown, color : 'red'}

    const delta : number = Math.round((props.value - props.compareValue) * 100) / 100;

    return (

        <React.Fragment>
            <Row>
                <Col xs={24} style={{fontSize:'3.5vw'}}>
                    <FontAwesomeIcon icon={deltaIcon.icon} color={deltaIcon.color} size="lg" />
                </Col>
            </Row>
            <Row>
                <Col xs={24} style={{fontSize:'3vw'}}>
                    <p>{delta} {props.deltaSuffix ? props.deltaSuffix : null}</p>
                </Col>
            </Row>
            
        </React.Fragment>

    )


}

export default DeltaComponent;