import React from 'react';

import { Provider } from 'react-redux';
import Store from './core/store/store';
import Routes from './routes/Routes';

import 'antd/dist/antd.css';
import './App.css';
import './webkitBounce';
import usePolyfillViewport from './shared/utils/viewportPolyfill';
import usePolyfillMinMax from './shared/utils/minMaxPolyfill';

function App() {

  const { polyfill } = usePolyfillViewport();
  const minMaxPolyfill = usePolyfillMinMax();

  minMaxPolyfill.polyfill();
  polyfill();

  return (
    <div >
      
      <Provider store={Store} >

          <Routes />

      </Provider>
    </div>
  );
}

export default App;
