/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { IZReportHeader } from '../../features/ZReports/models/IZReportHeader';
import { Row, Col } from 'antd';

import style from './sites.module.css';
import { customIcons, CustomIconType } from '../../layouts/icons/icons';
import { ILanguageState } from '../../shared/slices/languageSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../core/store/rootReducer';

interface ISiteEntryLastZReportProps {
    lastZReport: IZReportHeader,
    activity_type: string;
}

const SiteEntryLastZReport : React.FC<ISiteEntryLastZReportProps> = props => {

    const language: ILanguageState = useSelector(
		(state: RootState) => state.LanguageReducers
	)

    const renderIncasso = ( SVGElement : CustomIconType, value : string, color?: string) => {
        const curDate = value ? new Date(value) : '';
        let day, month, year = '';
        if(curDate){
            day = curDate.getDate().toString();
            const curMonth = curDate.getMonth() + 1;
            month = curMonth.toString();
            year = curDate.getFullYear().toString();
        }
        let formattedDate = '';
        switch (language.value) {
            case 'IT':
            case 'FR':
                if(curDate){
                    formattedDate = `${day + '/' + month + '/' + year}`
                }
                break;
        
            default:
                if(curDate){
                    formattedDate = `${month + '/' + day + '/' + year}`
                }
                break;
        }
        
        
        return <>
            <div style={{display: 'flex', alignItems: 'center', height: '21px', paddingLeft: '15px' }}>
                <SVGElement className={style.lastZIcons}/><span className={style.lastZValue}>{formattedDate}</span>
            </div>
            <div style={{display: 'flex', justifyContent: 'end', height: '21px' }}>
                <b>{props.lastZReport.document_total ? props.lastZReport.document_total.toFixed(2) + " €" : '0 €'}</b>
            </div>
        </>
    }


    const renderCover = () => 
        <>
            <div style={{display: 'flex', height: '21px'}}></div>
            <div>
                <b>{props.lastZReport.cover_num?.toString() ?? '0'}</b>
            </div>
        </>

    const renderDoc = () => 
    <>
        <div style={{display: 'flex', height: '21px'}}></div>
        <div>
            <b>{props.lastZReport.document_num?.toString() ?? '0'}</b>
        </div>
    </>
 


    return (

        <React.Fragment>
            <Row style={{backgroundColor:'rgb(222 222 222)',padding:'0px',position:'relative', color: '#707070', fontSize: '18px'}}>
                <Col xs={props.activity_type !== 'retail' ? 12 : 15} style={{display: 'flex', flexDirection: 'column', paddingRight: '10px'}}>
                    {renderIncasso(customIcons.IconCalendar, props.lastZReport.start_date)}
                </Col>
                {props.activity_type !== 'retail' ? <Col xs={6} style={{display: 'flex', flexDirection: 'column', alignItems: 'end', paddingRight: '10px'}}>
                    {renderCover()}
                </Col> : null}
                <Col xs={props.activity_type !== 'retail' ? 6 : 9} style={{display: 'flex', flexDirection: 'column', alignItems: 'end', paddingRight: '10px'}}>
                    {renderDoc()}
                </Col>
            </Row> 
        </React.Fragment>

    )

}

export default SiteEntryLastZReport;