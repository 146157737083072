import React from 'react';
import { useParams } from 'react-router-dom';
import DefaultLayout from '../layouts/PageLayout/DefaultLayout';
import MaterialComponent from '../components/material/material.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../core/store/rootReducer';
import { IMaterialState, uploadMaterialRequest } from '../features/Materials/slices/materialSlice';
import { IMaterial } from '../features/Materials/data/materials';
import { IResellerState } from '../features/Reseller/slices/resellerSlices';
import NoResellerWarning from '../components/shared/reseller/NoReseller';
import { ISiteState } from '../shared/slices/siteSlice';

const MaterialContainer : React.FC<{}> = props => {

    const { siteId } = useParams<{siteId:string}>();

    const dispatch = useDispatch();

    const materialState = useSelector<RootState,IMaterialState>(
        ( state : RootState) => state.MaterialReducers
    )

    const resellerState = useSelector<RootState, IResellerState>(
        ( state : RootState ) => state.ResellerReducers
    )

    const siteState = useSelector<RootState, ISiteState>(
        ( state : RootState ) => state.SiteReducers 
    )

    const handleSubmit = ( material : IMaterial, quantity : number, notes : string ) => {

        resellerState.reseller && siteState.site && dispatch( 
            uploadMaterialRequest( 
                material, 
                quantity, 
                notes, 
                siteState.site.id,
                resellerState.reseller
            ) 
        );
    }

    return (

        <DefaultLayout>

            { resellerState.reseller 
                ? <MaterialComponent
                    reseller={resellerState.reseller}  
                    materials={materialState.materials}
                    onSubmit={handleSubmit}
                    siteId={parseInt(siteId)}
                    isProcessing={materialState.request.isProcessing}
                    isError={materialState.request.isError} /> 
                : <NoResellerWarning feature='features.consumables' /> }

        </DefaultLayout>

    )

}

export default MaterialContainer;