/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SyntheticEvent, useState } from 'react';
import { Layout } from 'antd';
import ToolbarContainer from '../../containers/ToolbarContainer';
import BottomToolbarContainer from '../../containers/BottomToolbarContainer';
import { ICoordinates } from './ICoordinates';
import { useHistory } from 'react-router-dom';

interface IDefaultLayout {
     onScroll ?: (e: SyntheticEvent) => void;
     isRefreshing? : Boolean;
     onRefresh ?:(...args: any) => void
}

const DefaultLayout : React.FC<IDefaultLayout> = props => {

     const getTransformCSSProperties = ( deg : number ) : React.CSSProperties => {
          
          const transformValue = `rotateX(${deg}deg)`;

          /* return {
               transform: transformValue,
               WebkitTransform: transformValue,
               msTransform: transformValue,
               OTransform: transformValue,
          } */

          return {
               marginTop: `${deg}px`
          }

     }

     const initialLayoutStyle : React.CSSProperties = { 
          bottom:'40px',top:'50px',position:'absolute',overflow:'scroll',
          zIndex:1,  
          transition: "all 0.3s ease-in-out",
          WebkitTransition: "all 0.3s ease-in-out",
          MozTransition: "all 0.3s ease-in-out",
          OTransition: "all 0.3s ease-in-out",
          transformStyle: "preserve-3d",
          WebkitTransformStyle: "preserve-3d",
          MozTransformStyle: "preserve-3d",
          ...getTransformCSSProperties(0),
          backfaceVisibility: 'hidden',
          WebkitBackfaceVisibility:'hidden',
     }

     const [ layoutStyle, setLayoutStyle ]        = useState<React.CSSProperties>(initialLayoutStyle);
     const [ refreshReached, setRefreshReached]   = useState<Boolean>(false);
     const [ tStart, setTStart]                   = useState<ICoordinates>({ x: 0 , y: 0 });

     let tCurrent : ICoordinates = { x: 0, y: 0};

     const onTouchStart = ( e : React.TouchEvent ) => {

          let touch = e.targetTouches[0];

          setTStart({ x: touch.screenX, y: touch.screenY });

     }

     const onTouchMove = ( e : React.TouchEvent) => {

          props.onScroll && props.onScroll( e );
          if(!props.onRefresh) return;

          const touch = e.changedTouches[0];
          tCurrent = { x: touch.screenX, y: touch.screenY };

          if((tStart.y - tCurrent.y) > 0) return;
          const changeY = Math.abs(tStart.y - tCurrent.y);
          const rotation = changeY < 100 ? changeY * 30/100 : 30;

          if(e.currentTarget.scrollTop === 0) {

             

               if(changeY > 200 ){
                     Object.keys(localStorage).forEach(
                         element => {
                              if(element.indexOf('zrepo') === 0){
                                   localStorage.removeItem(element);
                              }else{
                                   console.log(element.indexOf('zrepo'));
                              }

                         }
                    );
                    setRefreshReached(true);
               }

               setLayoutStyle({
                    ...layoutStyle,
                    ...getTransformCSSProperties(rotation * 2)
               });

          }

     }

     const onTouchEnd = () => {
          //localStorage.clear();




          setLayoutStyle( initialLayoutStyle );

          if(refreshReached) {

               setRefreshReached(false);
               props.onRefresh && props.onRefresh();     

          }

     }

     const history = useHistory();

     const getBackgroundColor = () : string => {

          return history.location.pathname === '/login' ? '#FFF' : '#313131'

     }

     return (
          <div style={{backgroundColor:getBackgroundColor(), height:'100%', width:'100%'}}>
               <ToolbarContainer/>
                         
                         
                              <Layout.Content 
                                   style={layoutStyle}
                                   >
                                   <div 
                                        onTouchStart={onTouchStart}   
                                        onTouchEnd={onTouchEnd}                                                                      
                                        onTouchMove={onTouchMove}     
                                        onScroll={props.onScroll}
                                        style={{width:'100vw',backgroundColor:getBackgroundColor(),overflow:'scroll', height:'100%',bottom:'50px',top:'60px'}} 
                                        >{props.children}
                                   </div>
                              </Layout.Content>
                         
          
               <BottomToolbarContainer />
          </div>
     )

}

export default DefaultLayout;