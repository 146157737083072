/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Layout, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import LabwareLogo from '../../shared/assets/images/labware_logo.png';

const LoginBottomToolbar : React.FC<{}> = props => {

    const { t } = useTranslation(); 

    return (
        <Layout.Footer style={{position:"fixed",bottom:"0px", backgroundColor:"#FFF", width:"100%", padding:"5px 0", height:"40px",zIndex:5, borderTop:"1px solid #73879c"}}>
            
            <Row justify="center" >
                <img src={LabwareLogo} width="70px" height="20px" />
            </Row>

        </Layout.Footer>
    )

}

export default LoginBottomToolbar;