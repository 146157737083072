import React, { ChangeEvent } from 'react';
import { useState } from 'react';
import { Input, Row, Col, Space, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import BackgroundSign from '../../shared/assets/images/labware_sign.png';
import MyBasiqLogo from '../shared/myBasiqLogo.component';

interface ILoginComponentProps {
    onLogin : ( username : string, password : string ) => void;
    isLoading: boolean;
}

const LoginComponent : React.FC<ILoginComponentProps> = props => {

    const [ username, setUsername ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');

    const handleUsernameChange = ( e : ChangeEvent<HTMLInputElement> ) => {

        setUsername(e.target.value);

    }

    const handlePasswordChange = ( e : ChangeEvent<HTMLInputElement> ) => {

        setPassword(e.target.value);

    }

    const handleLoginClick = () => {

        props.onLogin(username, password );

    }

    const loginDisabled = () : boolean => {

        return props.isLoading || !username || !password

    }

    const backgroundImageSrc = 'url(' + BackgroundSign + ')';

    return(

        <React.Fragment>

            <div style={{backgroundImage:backgroundImageSrc, textAlign:'center',  backgroundPosition: 'center', backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>

                <Space size="large" style={{margin:'3vh'}}>

                    <MyBasiqLogo />

                </Space>


                <Space style={{width:'100%'}} direction="vertical">
                    

                    <Row gutter={[12,12]} justify="center" align="middle" style={{paddingLeft:"60px", paddingRight:"60px", marginTop:"10px"}}>

                        {/* <Typography.Title style={{color:'white'}} level={2} >Login </Typography.Title>  */}

                    </Row>

                    <Row gutter={[12,12]} justify="center" align="middle" style={{paddingLeft:"60px", paddingRight:"60px"}}>

                        <Col md={12} lg={6}>

                            <Input value={username} onChange={handleUsernameChange} />

                        </Col>

                    </Row>
                    <Row gutter={[12,12]} justify="center" align="middle" style={{paddingLeft:"60px", paddingRight:"60px"}}>

                        <Col md={12} lg={6}>

                            <Input value={password} type="password" onChange={handlePasswordChange} />

                        </Col>

                    </Row>

                    <Row gutter={[12,12]} justify="center" align="middle">

                        <Button disabled={loginDisabled()} onClick={handleLoginClick} > 
                            {props.isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Login" } 
                        </Button>

                    </Row>


                </Space>

            </div>
            
        </React.Fragment>

    )

}

export default LoginComponent;