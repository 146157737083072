/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../../core/store/store";
import { IReseller } from "../../Reseller/models/IReseller";
import { IMaterial, materials } from "../data/materials";
import { materialsService } from "../services/materials.service";

export interface IMaterialState {
	materials: IMaterial[];
	isError: boolean;
	isLoading: boolean;
	request: {
		isProcessing: boolean;
		isError: boolean;
	};
}

const initialState: IMaterialState = {
	materials: materials,
	isError: false,
	isLoading: false,
	request: {
		isProcessing: false,
		isError: false
	}
};

const materialSlice = createSlice({
	name: "materials",
	initialState,
	reducers: {
		uploadingRequest(state: IMaterialState, payload: PayloadAction) {
			state.request.isError = false;
			state.request.isProcessing = true;
		},

		succesfullUploadRequest(state: IMaterialState, payload: PayloadAction) {
			state.request.isProcessing = false;
			state.request.isError = false;
		},

		errorUploadRequest(state: IMaterialState, payload: PayloadAction) {
			state.request.isProcessing = false;
			state.request.isError = true;
		}
	}
});

export default materialSlice.reducer;

export const { uploadingRequest, succesfullUploadRequest, errorUploadRequest } = materialSlice.actions;

export const uploadMaterialRequest =
	(material: IMaterial, quantity: number, notes: string, siteId: number, reseller: IReseller): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(uploadingRequest());

			const response = await materialsService.postMaterials(material.description, quantity, notes, siteId, reseller.id, dispatch<any>);

			dispatch(succesfullUploadRequest());
		} catch (e) {
			dispatch(errorUploadRequest());
		}
	};
