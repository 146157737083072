import Axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { auth } from "../../shared/services/auth.service";
import store, { AppDispatch } from "../store/store";
import { userLoginSuccess, IUserState, userLogout, refreshing } from "../../shared/slices/userSlices";
import { setLastFetchDateTime } from "../../shared/slices/appStatusSlice";

const ALREADY_REFRESHING_EXCEPTION = 'User already refreshing';

const request = <T>(host : string, options : AxiosRequestConfig, dispatch: AppDispatch) : Promise<AxiosResponse<T>> => {

    const user : IUserState = store.getState().UserReducers;

    let headers = options.headers || {};
    let data = options.data || {};

    headers.Authorization = `Bearer ${user.user?.token.access_token}`;

    const client = Axios.create({
        baseURL: host
    });

    client.interceptors.response.use(
        success => onSuccess(success),
        async err => {

            if(err.response.status === 401) {

                try{

                    const user : IUserState = store.getState().UserReducers;

                    if(user.isRefreshing) throw ALREADY_REFRESHING_EXCEPTION;

                    dispatch( refreshing(true) );

                    const refresh_token = user.user?.token.refresh_token;

                    try{

                        const token = await auth.refreshToken(refresh_token || '');

                        dispatch(userLoginSuccess({username: user.user?.username || '', token: token}));

                        return request<T>(host, options, dispatch);

                    } catch(e) {

                        dispatch(userLogout(null));

                    }

                }
                catch(e) {

                    return await new Promise<AxiosResponse<T>>((resolve,reject) => {

                        const intervalId = setInterval(() => {

                            const user : IUserState = store.getState().UserReducers;

                            if(!user.user?.token.access_token && !user.user?.token.refresh_token) dispatch(userLogout(null));

                            if(!user.isRefreshing) {

                                clearInterval(intervalId);
                                resolve(request(host, options, dispatch));

                            }

                        }, 500)


                    })

                }

            }

        }
    );

    const onSuccess = (response : AxiosResponse) => {

        navigator.onLine && dispatch(setLastFetchDateTime(new Date().toLocaleString()));

        return Promise.resolve(response);
    }

    const onError = ( error : AxiosError) => Promise.reject(error);

    const url = options.url || '';

    switch(options.method) {

        case('POST'):
            return client.post(url, data, { headers: headers })
                .then(onSuccess)
                .catch(onError)
        case('DELETE'):
            return client.post(url,{ headers: headers})
                .then(onSuccess)
                .catch(onError)
        case('PATCH'):
            return client.post(url, { headers: headers})
                .then(onSuccess)
                .catch(onError)
        case('PUT'):
            return client.post(url, { headers: headers})
                .then(onSuccess)
                .catch(onError)
        default:
            return client.get(url, { headers: headers })
                .then(onSuccess)
                .catch(onError);
            
    }

}

export default request;