/* eslint-disable jsx-a11y/anchor-is-valid */
import { Card, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProfileContactUsComponent : React.FC<{}> = props => {

    const { t } = useTranslation();

    const onEmailClick = ( email : string ) => {

        window.location.href = `mailto:${email}?subject=Client Request From MyBasiq App`;

    }

    const renderEmailRow = ( role : string, email : string ) =>                 
        <Row>

            <Col xs={12}>

                <p>{role}</p>

            </Col>
            <Col xs={12}>

                <a onClick={(_) => onEmailClick(email)} >{email}</a>

            </Col>
            
        </Row>  

    return (

        <Card 
            style={{width:'100'}}>

            <p>{t('support.contactUs.paragraph')}</p>

            <hr/>

            <div>

                { renderEmailRow(t('support.contactUs.roles.customerGeneralInformation'),'info@labware.it') }
                { renderEmailRow(t('support.contactUs.roles.support'),'support@labware.it') }

            </div>

        </Card>

    )

}

export default ProfileContactUsComponent;