import { IUser, IUserDetail } from "../models/IUser";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../core/store/store";
import { auth } from "../services/auth.service";
import { userService } from "../services/user.service";
import { setLanguage, Languages } from "./languageSlice";

export interface IUserState {
	user: IUser | null;
	loading: boolean;
	error: boolean;
	isRefreshing: boolean;
}

const localStorageUser = localStorage.getItem("user");

const initialState: IUserState = {
	user: localStorageUser ? JSON.parse(localStorageUser) : null,
	loading: false,
	error: false,
	isRefreshing: false
};

const userSlice = createSlice({
	name: "User",
	initialState,
	reducers: {
		userLogging: (state: IUserState, action: PayloadAction<boolean>) => {
			state.error = false;

			state.loading = action.payload;
		},

		userLoginSuccess: (state: IUserState, action: PayloadAction<IUser>) => {
			state.isRefreshing = false;

			state.user = action.payload;

			localStorage.setItem("user", JSON.stringify(action.payload));

			state.loading = false;
		},

		userLoginFailed: (state: IUserState, action: PayloadAction<null>) => {
			state.error = true;

			state.user = action.payload;

			state.loading = false;
		},

		userLogout: (state: IUserState, action: PayloadAction<null>) => {
			localStorage.removeItem("user");

			state.user = null;
		},

		setUserDetail: (state: IUserState, action: PayloadAction<IUserDetail>) => {
			if (state.user) state.user.userDetail = action.payload;
		},

		refreshing: (state: IUserState, action: PayloadAction<boolean>) => {
			state.isRefreshing = action.payload;
		}
	}
});

export const { userLogging, userLoginSuccess, userLoginFailed, userLogout, setUserDetail, refreshing } = userSlice.actions;

export default userSlice.reducer;

export const login =
	(username: string, password: string): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(userLogging(true));

			const token = await auth.login(username, password);

			dispatch(userLoginSuccess({ username: username, token: token }));

			try {
				const userDetails = await userService.getUser(username, dispatch<any>);

				dispatch(setUserDetail(userDetails));

				const userLanguage = userDetails.language.toUpperCase();

				if (Object.keys(Languages).includes(userLanguage)) dispatch(setLanguage(userLanguage));
			} catch (e) {
				return;
			}
		} catch (e) {
			dispatch(userLoginFailed(null));

			return;
		}
	};
