/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import DefaultLayout from '../../layouts/PageLayout/DefaultLayout';
import { IUser } from '../../shared/models/IUser';
import { Row } from 'antd';
import AvatarComponent from './avatar.component';
import ProfileMenuComponent from './profileMenu.component';
import { faEdit, faDotCircle, faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import PreferenceMenuComponent from './preferenceMenu.component';
import { Languages } from '../../shared/slices/languageSlice';
import { useHistory } from 'react-router-dom';
import AboutMenuComponent from './aboutMenu.component';

export interface IProfileMenuEntry {
    name : string;
    translation: string;
    icon : IconProp;
    onClick?: (...args:any) => void;
    color? : string;
}

interface IProfileComponentProps {
    id : number;
    user : IUser;
    onLogout(): any;
    language : string;
}

const ProfileComponent : React.FC<IProfileComponentProps> = props => {

    const history = useHistory();

    const profileMenuEntries : IProfileMenuEntry[] = [
        {
            name: 'Preferences',
            translation: 'profile.menu.preferences',
            icon: faEdit,
            onClick: () => selectEntry(1)
        },
        {
            name: 'About',
            translation: 'profile.menu.about',
            icon: faDotCircle,
            onClick: () => selectEntry(2)
        },
        {
            name: 'Logout',
            translation: 'profile.menu.logout',
            icon: faArrowAltCircleRight,
            onClick: props.onLogout
        }
    ]

    const selectEntry = ( entry : number ) => {

        history.push('/profile/' + entry);

    }

    const renderMenu = () => {

        switch(props.id * 1) {

            case(0):
                return <ProfileMenuComponent
                            menu={profileMenuEntries} />

            case(1): 

                return <PreferenceMenuComponent 
                            language={props.language} 
                            onBack={() => history.go(-1)} />

            case(2): 

                return <AboutMenuComponent 
                            onBack={() => history.go(-1)} />

        }

    }

    return (

            <Row style={{marginLeft: '10px', marginTop: '10px', marginRight: '10px', position:'sticky'}} gutter={0}>

                <AvatarComponent
                    user={props.user} />

                {renderMenu()}

            </Row>
            
    )

}

export default ProfileComponent;