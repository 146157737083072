import React from 'react';
import { Row } from 'antd';

interface ITemporalDataRangeLabelProps {
    display : string;
}

const TemporalDateRangeLabel : React.FC<ITemporalDataRangeLabelProps> = props => {

    return(

        <Row style={{height:'100%'}}>

                        
            <span style={{textAlign:'center', fontSize:'3.1vw', margin:'auto'}}>
                {props.display}
            </span> 
            

        </Row>

    )

}

export default TemporalDateRangeLabel;